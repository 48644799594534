.bh_hp-cta-button {
  &:hover {
    color: $black;
  }
}

.bh_hp-features__inner {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    @extend %bh_type-title-1;
    @include larger-than(tablet) {
      font-size: $text-large-title;
    }
  }

  p {
    @extend %bh_type-body;
    max-width: 600px;
  }
}

.bh_hp-features__columns {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 30px;

  @include larger-than(smallDesktop) {
    margin-top: 50px;
    flex-direction: row;
    margin-bottom: 50px;
  }
}

.bh_hp-features__column {
  &:last-child {
    margin-left: 20px;
    margin-right: 20px;
    @include larger-than(smallDesktop) {
      margin-left: 50px;
      margin-right: 0px;
    }
  }
  flex-grow: 0;
  flex-shrink: 1;

  @include larger-than(smallDesktop) {
    width: 50%;
    min-width: 550px;
    max-width: 550px;
  }

  img {
    width: 100%;
    max-width: 550px;
    @include larger-than(smallDesktop) {
    }
  }
  h2 {
    color: $inspirationBlue;
    @extend %bh_type-title-2;
    @include larger-than(smallDesktop) {
      font-size: $text-title-1;
    }
  }
}

.bh_hp-features__grid {
  &, li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  li {
    width: 182px;
    height: 182px;
    border-left: 1px solid $inspirationBlue;
    border-top: 1px solid $inspirationBlue;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &:nth-child(3n) {
      border-right: 1px solid $inspirationBlue;
    }

    &:nth-child(n+7) {
      border-bottom: 1px solid $inspirationBlue;
    }

    &:hover {
      svg path {
        stroke: $black;
      }
      > div {
        color: $black;
      }
    }

    &.bh_hp-features__grid-item--active {
      background: $inspirationBlue;
      svg path {
        stroke: $white;
      }
      svg path {
        stroke: $white;
      }
      > div {
        color: $white;
      }
    }

    img, svg {
      height: 48px;
      width: 100%;
      margin-top: 20px;
      path {
        stroke: $inspirationBlue;
      }
    }

    > div {
      height: 62px;
      max-width: 70%;
      @extend %bh_type-subhead;
      color: $inspirationBlue;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: $text-title-2;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.bh_hp-features__column--left {
  display: none;
  @include larger-than(smallDesktop) {
    display: block;
  }
  margin-right: 16px;
}

.bh_hp-features__column--right {
  width: 100%;
  padding-bottom: 20px;
  background-color: $white;
  position: relative;

  h3 {
    @extend %bh_type-title-2;
  }

  p {
    @extend %bh_type-body;
  }

  @include larger-than(smallDesktop) {
    height: 546px;
    max-width: 550px;
  }
}

.bh_hp-features__slideshow-slide {
  img {
    max-height: 132px;
    width: auto;
    margin-bottom: 30px;

    @include larger-than(tablet) {
      margin: 0 auto 24px;
      max-height: 200px;
    }
  }
  padding: 30px 20px 0;
  @include larger-than(tablet) {
    padding: 30px 80px 0;
  }
}

.bh_hp-features__slideshow-slide-controls {
  display: none;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  @include larger-than(smallDesktop) {
    display: flex;
  }

  button {
    width: 40px;
    height: 40px;
    display: block;
    // mask: url('../images/icons/carousel-arrow.svg');
    // mask-size: contain;
    -webkit-appearance: none;
    border: none;
    position: relative;
    svg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      * {
        stroke: $black;
      }
    }
    background-color: transparent;
    &:hover {
      svg * {
        stroke: $inspirationBlue;
      }
    }
  }

  .bh_hp-features__slideshow-slide-controls--left {
    transform: rotate(180deg);
  }
}

.bh_hp-features__slideshow-slide-bottom-controls {
  display: flex;
  justify-content: center;
  margin-top: 30px;

  @include larger-than(smallDesktop) {
    display: none;
  }

  button {
    width: 40px;
    height: 40px;
    display: block;
    background: url('../images/icons/carousel-arrow.svg');
    background-size: contain;
    -webkit-appearance: none;
    border: none;
  }

  .bh_hp-features__slideshow-slide-bottom-controls--left {
    transform: rotate(180deg);
    margin-right: 30px;
  }
}
