.bh_third-party {
  img {
    max-width: 338px;
  }

  p {
    &:last-of-type {
      margin-bottom: 30px;
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    margin-right: -#{$gutter};
    margin-top: 50px;

    & > * {
      width: calc(50% - #{$gutter});
      margin-right: $gutter;
      margin-bottom: 16px;

      @include larger-than(smallMobile) {
        width: auto;
      }
    }
  }
}