.bh_secure-hosting {
    .bh_title-two-columns-body-left{
        display:flex;
        align-items: center;
    }

    .bh_title-two-columns-list {
        margin-top:40px;
        li {
            display:flex;
            flex-direction: column;
            div{
                display:flex;
            }
            .bh_type-headline {
                margin-bottom:0;
            }
            .bh_type-callout {
                margin-left:41px
            }
        }

        @include larger-than(smallTablet){
            margin-top:0;
        }
    }

    .bh_hide-item {
        display:none;
    }
}