.bh_bottom-feature-nav-container {
    display:flex;
    justify-content: center;

    h4 {
        margin-bottom:0px;
        font-size:$text-headline;
    }

    & .--hide {
        display:none
    }

    & > div {
        display:flex;
        align-items: center;
        width:100%;
        max-width:250px;
        @include larger-than(mobile){
            width:auto;
            max-width:unset;
            & > div {
                max-width:unset;
                padding: 0 80px;
                margin: unset;
            }
        }
    }

}

.bh_bottom-feature-nav-button {
    color:$spaceBlack;
    height:45px;
    width:45px;
    svg {
        height:100%;
        width:100%;
    }

    @include larger-than(mobile){ 
        height:60px;
        width:60px;

        &:hover {
            color: $inspirationBlue;
        }
    }
}

.bh_bottom-feature-nav-left {
    padding-right: 12px;
    justify-content: flex-end;
    border-right: 1px solid black;
    
    & > div {
        text-align: right;
        margin-left: auto;
        max-width: 86px;
    }

    a {
        transform: rotate(180deg);
    }
}

.bh_bottom-feature-nav-right {
    padding-left: 12px;
    & > div {
        margin-right: auto;
        max-width: 86px;
    }

}