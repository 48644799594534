.bh_hp-websites__inner {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    @extend %bh_type-title-1;
    @include larger-than(tablet) {
      font-size: $text-large-title;
    }
  }
}

.bh_hp-websites__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 32px;
  max-width: 560px;
  width: 100%;
  flex-wrap: wrap;

  a {
    text-decoration: none;
    color:black;
    border:3px solid transparent;
    margin-bottom:22px;
    width: calc(50% - 8px);
    cursor:pointer;
  
    @include larger-than(mobile) {
      width: 268px;
    }
    &:hover {
      border:3px solid $inspirationBlue;
      border-radius:4px;
    }
  }

  @include larger-than(smallDesktop) {
    max-width: 1120px;
    width: 1120px;
    flex-wrap: nowrap;
  }
}

.bh_hp-websites__website-content {
  padding: 22px;
}

.bh_hp-websites__website {
  height:100%;
  img {
    width: 100%;
    height: 161px;
    object-fit: cover;
  }
  text-align: left;
  border: 1px solid $lightGray;

  h2 {
    @extend %bh_type-headline;
  }

  p {
    @extend %bh_type-callout;
    margin-top: 8px;
  }
}