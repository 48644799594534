#s4s-landing {
  nav.bh_nav {
    display: none;
  }
  main {
    padding-top: 0;
  }
}

.bh_landing__inspiration-logo {
  display: block;
  width: 150px;
  height: 150px;
  background: url(../images/homepage/inspiration.png);
  background-size: contain;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50px);

  @include larger-than(tablet) {
    transform: translate(-50%, -20%);
    width: 160px;
    height: 160px;
  }
}

.bh_landing-hero-top {
  height: 648px;
  background: url(../images/landing-page/bg-mobile.jpg) top center / cover no-repeat;

  @include larger-than(mobile) {
    background: url(../images/landing-page/bg.jpg) top center / cover no-repeat;
  }

  @include larger-than(tablet) {
    height: 530px;
  }

  > div {
    position: relative;
    padding-top: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  img {
    width: 182px;
    position: absolute;
    top: 35px;
    left: 40px;
    display: none;

    @include larger-than(tablet) {
      display: block;
    }
  }

  h1 {
    @extend %bh_type-feature;
    line-height: 40px;
    font-size: $text-title-1;
    font-weight: $font-weight-bold;
    max-width: 860px;
    margin-bottom: 22px;

    @include larger-than(tablet) {
      font-size: $text-feature;
      line-height: 60px;
      font-weight: $font-weight-bold;
    }
  }

  p {
    @extend %bh_type-highlight;
    margin-bottom: 40px;
  }
}

.bh_landing-enter-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    padding-top: 220px;
    @extend %bh_type-title-1;

    @include larger-than(tablet) {
      padding-top: 148px;
    }
  }

  ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul {
    max-width: 870px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 10px;
    padding: 0 16px;

    @include larger-than(tablet) {
      flex-direction: row;
    }

    li {
      display: flex;
      flex-direction: column;
      align-items: center;

      h2 {
        @extend %bh_type-title-2;
      }

      p {
        @extend %bh_type-body;
        margin-bottom: 44px;
      }

      @include larger-than(tablet) {
        width: calc(33% - 20px);
        margin-bottom: 0px;
      }
    }
  }
}

.bh_landing-enter-form__step {
  width: 26px;
  height: 26px;
  border-radius: 13px;
  color: $white;
  border: 2px solid $inspirationBlue;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 16px;

  span {
    @extend %bh_type-subhead;
    margin-top: -4px;
  }
}

.bh_landing-hero__signup {
  margin-bottom: 30px;
  width: 100%;
  max-width: 870px;
  margin-top: 60px;
  padding: 0 16px;

  @include larger-than(mobile) {
    padding: 0 16px;
    margin-bottom: 20px;
    display: flex;

    input {
      flex-grow: 1;
    }
  }

  @include larger-than(tablet) {
    padding: 0;
  }
}

.bh_landing-hero__input {
  width: 100%;
  background-color: $black;
  outline: none;
  border: 1px solid $white;
  padding: 0 16px;
  height: 49px;

  @include larger-than(mobile) {
    padding: 0 16px;
    border-right: none;
    flex-grow: 1;
    width: auto;
  }
}

.bh_landing-hero__submit {
  width: 100%;
  height: 50px;
  padding: 0 32px;
  border: none;

  @include larger-than(mobile) {
    width: auto;
  }
}