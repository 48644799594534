.bh_image-text-slider__inner {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: center;

  h1 {
    @extend %bh_type-title-1;
    max-width: 600px;
    margin-bottom: 0;
    @include larger-than(tablet) {
      font-size: $text-large-title;
      margin-bottom: 32px;
    }
  }

  p {
    max-width: 200px;
    margin: 0 auto;
  }

  button {
    width: 40px;
    height: 40px;
    background: url('../images/icons/carousel-arrow.svg');
    background-size: contain;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    border: none;
  }

  .bh_image-text-slider__slideshow-button--previous {
    transform: rotate(180deg);
    margin-right: 30px;
  }
}

.bh_image-text-slider__slideshow {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  margin-top: 40px;

  @include larger-than(mobile) {
    justify-content: center;
  }

  button {
    display: none;
    @include larger-than(tablet) {
      display: block;
    }
  }
}

.bh_image-text-slider__slides {
  width: 100%;
}

.bh_image-text-slider__slide {
  margin: 0 40px;
  img {
    margin: 0 auto;
    max-width: 100%;
  }
}

.bh_image-text-slider__slideshow-buttons {
  display: flex;
  margin-top: 16px;
  flex-direction: row;
  align-self: center;
  -webkit-tap-highlight-color: transparent;
  @include larger-than(tablet) {
    display: none;
  }
}


.bh_image-text-slider__slideshow {
  button {
    visibility: hidden;
    position: absolute;

    @include larger-than(tablet) {
      visibility: visible;
      position: static;
    }
  }
}

.bh_image-text-slider__image {
  height: 84px;
  max-width: 200px;
  margin: 0 auto 30px;
  display: flex;
  align-items: center;
}