.text-two-columns__columns {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  margin-right: -#{$gutter};

  img {
    max-width: 100%;
    vertical-align: bottom;
    margin-bottom: 16px;
  }

  @include larger-than(mobile) {
    margin-top: 40px;
  }

  & > * {
    margin-bottom: 30px;
    width: calc(50% - #{$gutter});
    margin-right: $gutter;

    @include larger-than(mobile) {
      margin-bottom: 0;;
    }
  }
}