.bh_hp-intro__inner {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    @extend %bh_type-large-title;
  }

  p {
    @extend %bh_type-body;
    max-width: 620px;
  }
}

.bh_hp-intro__columns {
  display: flex;
  flex-direction: column;

  @include larger-than(smallTablet) {
    margin-top: 50px;
    flex-direction: row;
  }
}

.bh_hp-intro__column {
  &:last-child {
    @include larger-than(smallTablet) {
      margin-left: 50px;
    }
  }
  flex-grow: 0;
  flex-shrink: 1;

  @include larger-than(smallTablet) {
    width: 50%;
    max-width: 410px;
  }

  img {
    width: 100%;
    max-width: 410px;
    @include larger-than(smallTablet) {
    }
  }
  h2 {
    color: $inspirationBlue;
    @extend %bh_type-title-2;
    @include larger-than(smallTablet) {
      font-size: $text-title-2;
      line-height: $text-title-3;
    }
  }

  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul li {
    @extend %bh_type-callout;
    margin-bottom: 20px;

    position: relative;
    padding-left: 45px;

    &::before {
      content: '';
      background-image: url('../images/icons/checkmark-circle.svg');
      width: 27px;
      height: 27px;
      position: absolute;
      background-size: 27px;
      left: 0;
    }
  }
}
