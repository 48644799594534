.bh-home-hero {
  width: 100vw;
  position: relative;
  background-size: cover;
  background-position: top center;
  background-image: linear-gradient(rgba(0,0,0,.6),rgba(0,0,0,.6)), url("/images/homepage/hero-desktop.jpg");

  @include smaller-than(tablet) {
    background-image: url('/images/homepage/hero-mobile.png');
  }
}

.bh-home-wrap {
  padding: 154px 0;
  color: $white;
  max-width: 670px;
  @include smaller-than(tablet) {
    margin-left: 0;
    width: 100%;
    padding: 154px 2.5%;
  }
  h1 {
    @extend .bh_type-feature;
    font-size: 56px;
    line-height: 60px;
    @include smaller-than(tablet) {
      font-size: 36px;
      line-height: 40px;
    }
  }
  p {
    @extend .bh_type-callout;
    font-size: 22px;
    line-height: 36px;
    margin: 20px 0 60px;
    @include smaller-than(tablet) {
      font-size: 16px;
      line-height: 28px;
      margin: 20px 0 100px;
    }
  }

  .hero-inp {
    background-color: orange;
    color: $black;
  }
}

.hero-email {
  width: 460px;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  @include smaller-than(tablet) {
    width: 100%;
    flex-direction: column;
    height: 100px;
  }
  input {
    background-color: $white;
    color: $black;
    height: 50px;
    border: none;
    padding: 0 16px;
    width: 60%;
    @include smaller-than(tablet) {
      width: 100%;
    }
    &:focus {
      outline: none;
    }
  }
}

.hero-link {
  font-size: 16px;
  br {
    display: none;
  }
  margin-top: 20px;
  @include smaller-than(tablet) {
    br {
      display: block;
    }
  }
}
