.image-wide-column {
  @include larger-than(smallTablet) {
    display: flex;
  }

  & > div img {
    max-width: 284px;
    width: 100%;
    @include larger-than(smallTablet) {
      max-width: 360px;
    }
  }

  & > div {
    &.image-wide-column_img-short {
      img {
        max-height: 360px;
        width: auto;
      }
    }
  }

  & > div:first-of-type {
    @include larger-than(smallTablet) {
      width: 410px;
      flex-shrink: 0;
    }
  }

  & > div:last-of-type {
    text-align: center;
    @include larger-than(smallTablet) {
      flex-grow: 1;
    }
  }

  &-title{
    display:flex;
    flex-direction: column;
    &-image{
      max-width:170px !important;
      max-height:56px;
    }

    @include larger-than(smallTablet) {
      flex-direction: row;
      margin-bottom:0;
      &-image{
        margin-bottom:0;
        margin-left:12px;
      }
    }
  }

}



.image-wide-column--left {
  @include larger-than(smallTablet) {
    flex-direction: row-reverse;

    & > div:last-of-type {
      padding-right: 32px;
    }
  }
}

.image-wide-column--right {
  @include larger-than(smallTablet) {
    & > div:last-of-type {
      padding-left: 32px;
    }
  }
}


.image-wide-column__iframe {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
  margin-top: 30px;

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    @include larger-than(smallTablet) {
      width: 95%;
      height: 95%;
    }
  }
}