.bh_carousel-card {
  max-width: 984px;
  margin: 0 auto;
  padding-bottom: 70px;

  @include larger-than(mobile) {
    padding-bottom: 0;
  }

  .slick-slide {
    padding: 0 8px;
  }

  .slick-track {
    display: flex;
  }

  .slick-initialized .slick-slide {
    display: flex;
    float: none;
    height: auto;
  }

  .slick-arrow {
    font-size: 0;
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    background: transparent;
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: -70px;
    transform: translateX(-50%);

    @include larger-than(tablet) {
      transform: none;
      bottom: inherit;
      top: 50%;
      transform: translateY(-50%);
    }

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-size: 40px;
      background-image: url("data:image/svg+xml,%3Csvg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.8135 11.8135L27.0059 21.0059L17.8135 30.1983' stroke='%23ffffff' stroke-miterlimit='10' stroke-linecap='square'/%3E%3Ccircle cx='21' cy='21' r='20' stroke='%23ffffff' stroke-miterlimit='10' stroke-linecap='square'/%3E%3C/svg%3E");
    }

    @include larger-than(tablet) {
      &:hover {
        &::before {
          background-image: url("data:image/svg+xml,%3Csvg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.8135 11.8135L27.0059 21.0059L17.8135 30.1983' stroke='%23111111' stroke-miterlimit='10' stroke-linecap='square'/%3E%3Ccircle cx='21' cy='21' r='20' stroke='%23111111' stroke-miterlimit='10' stroke-linecap='square'/%3E%3C/svg%3E");
        }
      }
    }

  }

  .slick-prev {
    left: calc(50% - 35px);

    @include larger-than(tablet) {
      left: -70px;
    }

    &::before {
      transform: rotate(180deg);
    }
  }

  .slick-next {
    left: calc(50% + 35px);

    @include larger-than(tablet) {
      left: inherit;
      right: -70px;
    }
  }
}

.bh_carousel-card__card {
  background: $white;
  border-radius: 30px;
  padding: 30px;

  @include larger-than(mobile) {
    padding: 50px;
  }

  &.bh_background-blue {
    background: $inspirationBlue;
    color: $white;
    .bh_review-carousel_reviewer-name .bh_strong,
    .bh_type-caption {
      color: $white !important;
    }
  }
}

.bh_carousel-card.bh_light {
  .slick-arrow {
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-size: 40px;
      background-image: url("data:image/svg+xml,%3Csvg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.8135 11.8135L27.0059 21.0059L17.8135 30.1983' stroke='%23111111' stroke-miterlimit='10' stroke-linecap='square'/%3E%3Ccircle cx='21' cy='21' r='20' stroke='%23111111' stroke-miterlimit='10' stroke-linecap='square'/%3E%3C/svg%3E");
    }

    @include larger-than(tablet) {
      &:hover {
        &::before {
          background-image: url("data:image/svg+xml,%3Csvg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.8135 11.8135L27.0059 21.0059L17.8135 30.1983' stroke='%23136EF6' stroke-miterlimit='10' stroke-linecap='square'/%3E%3Ccircle cx='21' cy='21' r='20' stroke='%23136EF6' stroke-miterlimit='10' stroke-linecap='square'/%3E%3C/svg%3E");
        }
      }
    }

  }
}
