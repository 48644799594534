.bh_copy-with-cb {
  h2 {
    max-width: 522px;
  }
}

.bh_copy-with-cb-grid {
  div:last-of-type {
    img {
      max-width: 329px;
      width: 100%;
    }
  }
}

.bh_copy-with-cb-columns {
  columns: 2;

  li {
    margin-bottom: 17px;
    position: relative;
    padding-left: 42px;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;

    &::before {
      content: '';
      background-image: url('../images/icons/checkmark-circle.svg');
      width: 26px;
      height: 26px;
      position: absolute;
      background-size: 26px;
      left: 0;
    }
  }
}