.bh_image-copy-tiles {
    .bh_type-title-1 {
        color: $inspirationBlue;
    }

    .bh_type-headline {
        color: $inspirationBlue;
        line-height:40px;
        font-size: $text-title-1;
    }

    img {
        max-width:280px;
    }

    .bh_right-align-copy-image {
        margin-top:0;
        padding-top:40px;
        .bh_image-left {
            margin-top:0;
          }
    }
    .bh_right-align-copy-image > :first-child{
        display:flex;
        flex-direction: column-reverse;
        img {
            padding-top:20px;
        }
    }

    @include larger-than(smallTablet){

        .bh_left-text-wide-image-content,
        .bh_right-align-copy-image-content {
            max-width:405px !important;
        }

        .bh_right-align-copy-image > :first-child{
            flex-direction: row;
        }

        section {
            .bh_right-align-copy-image{
                .bh_image-left {
                    margin-top:0;
                    margin-right:30px;
                  }
            }
            &.--bottom {
                padding-bottom:40px;
            }
    
            &.--top {
                padding-top:60px;
            }
        }

        img {
            max-width:480px;
            margin-top:20px;
            margin-left:0;
        }
    }
}