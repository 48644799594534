.bh_statistic-carousel {
  &.slick-initialized .slick-slide {
    display: block;
  }

  @include larger-than(smallMobile) {
    margin-top: 50px;
  }

  .bh_carousel-card &{
    .slick-arrow {
      @include larger-than(tablet) {
        &::before {
          content: '';
          background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='20' cy='20' r='20' fill='white' fill-opacity='0.8'/%3E%3Cpath d='M16.8135 10.8135L26.0059 20.0059L16.8135 29.1983' stroke='%23111111' stroke-miterlimit='10' stroke-linecap='square'/%3E%3C/svg%3E");
        }
      &:hover{
        &::before {
          background-image: url("data:image/svg+xml,%3Csvg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.8135 11.8135L27.0059 21.0059L17.8135 30.1983' stroke='%23ffffff' stroke-miterlimit='10' stroke-linecap='square'/%3E%3Ccircle cx='21' cy='21' r='20' stroke='%23ffffff' stroke-miterlimit='10' stroke-linecap='square'/%3E%3C/svg%3E");
        }
      }
    }}
  }
}

.bh_statistic-carousel__stat {
  img {
    max-height: 105px;
    width: auto;
  }

  @include larger-than(mobile) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.bh_statistic-carousel__card {
  @include larger-than(mobile) {
    display: flex;
    flex-direction: column;
    min-height: 340px;
  }

  & > p {
    @include larger-than(mobile) {
      margin-top: auto;
      margin-bottom: 0;;
    }
  }
}