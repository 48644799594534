.text-three-columns__columns {
  margin-top: 20px;

  img {
    max-width: 100%;
    vertical-align: bottom;
    margin-bottom: 16px;
  }

  @include larger-than(mobile) {
    display: flex;
    flex-wrap: wrap;
    margin-right: -#{$gutter};
    margin-top: 40px;
  }

  & > * {
    margin-bottom: 30px;

    @include larger-than(mobile) {
      width: calc(33.33% - #{$gutter});
      margin-right: $gutter;
      margin-bottom: 0;;
    }
  }
}