.bh_hp-integrations {
  background-color: $offWhite;

  .bh_pricing_pages & {
    background-color: $white;
  }
}

.bh_hp-integrations__inner {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    @extend %bh_type-title-2;
    max-width: 550px;
    margin-bottom: 32px;
    @include larger-than(tablet) {
      font-size: $text-title-1;
    }
  }

  p {
    @extend %bh_type-body;
    max-width: 550px;
  }

  button {
    width: 40px;
    height: 40px;
    background: url('../images/icons/carousel-arrow.svg');
    background-size: contain;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    border: none;
  }

  .bh_hp-integrations__slideshow-button--previous {
    transform: rotate(180deg);
    margin-right: 30px;
  }
}

.bh_hp-integrations__slideshow {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: flex-start;

  @include larger-than(mobile) {
    justify-content: center;
  }

  button {
    display: none;
    @include larger-than(tablet) {
      display: block;
    }
  }

  > div {
    width: 100%;
    @include larger-than(mobile) {
      width: 90%;
    }
  }
}

.bh_hp-integrations__slides {
  width: 100%;
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  &.slick-initialized .slick-slide {
    display: flex;
    height: 70px;
    align-items: center;
    justify-content: center;
  }
}

.bh_hp-integrations__slide {
  width:175px;
  img {
    margin:12px;
    max-width: 112px;
    @include larger-than(tablet) {
      margin:0 12px;
      max-width: 145px;
    }
  }
}

.bh_hp-integrations__slideshow-buttons {
  display: flex;
  margin-top: 16px;
  flex-direction: row;
  align-self: center;
  @include larger-than(tablet) {
    display: none;
  }
}


.bh_hp-integrations__slideshow {
  button {
    visibility: hidden;
    position: absolute;

    @include larger-than(tablet) {
      visibility: visible;
      position: static;
    }
  }
}

.bh_hp-integrations__image-container {
  display:flex;
  align-items:center;
  justify-content: center;
  width:400px;
  max-width:100%;
  flex-wrap: wrap;
  @include larger-than(tablet) {
    width:1120px;
    flex-wrap: nowrap;
  }
}