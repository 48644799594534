.bh_hp-hero {
  position: relative;
    &::before, &::after {
      @include larger-than(tablet) {
        content: '';
        position: absolute;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    &::before {
      @include larger-than(tablet) {
        background-image: url('../images/hero/hero-image.png');
        width: 232px;
        height: 330px;
        bottom: 50px;
        left: -100px;
      }

      @include larger-than(smallDesktop) {
        left: 0;
      }
    }

    &::after {
      @include larger-than(tablet) {
        background-image: url('../images/hero/hero-image-2.png');
        width: 174px;
        height: 297px;
        right: -100px;
        top: 0;
      }

      @include larger-than(smallDesktop) {
        right: 0;
      }
    }


}

.bh_hp-hero__inner {
  max-width: 836px;

  h1 {
    font-size: $text-title-1;
    line-height: 40px;
    font-family: $base-font;
    font-weight: $font-weight-bold;
    color: inherit;
    margin-bottom: 22px;

    @include larger-than(mobile) {
      font-size: $text-feature;
      line-height: 60px;
    }
  }

  p {
    line-height: 28px;
    font-family: $base-font;
    font-size: $text-body;
    font-weight: $font-weight-medium;
    color: inherit;
    margin-bottom: 36px;

    @include larger-than(mobile) {
      font-size: $text-title-2;
      line-height: 36px;
      margin-bottom: 50px;
    }
  }
}

.bh_hp-hero__content {
  max-width: 550px;
  margin: 0 auto;
}

.bh_hp-hero__signup {
  margin-bottom: 30px;
  @include larger-than(mobile) {
    margin-bottom: 20px;
    display: flex;

    input {
      flex-grow: 1;
    }
  }
}

.bh_hp-hero__input {
  width: 100%;

  @include larger-than(mobile) {
    flex-grow: 1;
    width: auto;
  }
}

.bh_hp-hero__submit {
  width: 100%;
  height: 50px;

  @include larger-than(mobile) {
    width: auto;
  }
}