.bh_shopping-cart {
    .bh_adjust-top-padding {
        padding-top:10px
    }

    &-card-container{
        display:flex;
        justify-content: space-evenly;
        flex-wrap:wrap;
        align-items: center;
        margin:auto;
        &-image {
            display:flex;
            justify-content: center;
            align-items: center;
            max-width:50%;
            width:100%;
            margin:22px auto;
            img {
                width:125px;
                margin:0px;
            }
        }

        @include larger-than(mobile){
            flex-wrap: unset;
            margin:28px 0 40px 0;
            flex-direction: row;
            &-image {
                margin:0;
                height:130px;
                max-width:150px;
            }
        }

        @include larger-than(smallTablet){
            &-image img{
                width:auto;
            }
        }
    }

    &-reverse{
        display:flex;
        flex-direction: column-reverse;
        @include larger-than(smallTablet){
            flex-direction: row;
        }
    }

    &-adjust-margin{
        margin-top:0px;
        padding-top:40px;
        .bh_image-left {
            margin-top:0px;
        }
        @include larger-than(tablet){
            .bh_image-left {
                margin-top:-80px;
            }
        }
    }

    &-video-container{
        height:300px;
        width:100%;
        max-height:400px;
        max-width:500px;
        @include larger-than(smallTablet){
            height:100%;
        }
    }

    &-tall-image {
        @include smaller-than(smallTablet){
            width:210px !important;
            height:200px;
        }
    }
}