.bh_nav {
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 2;
  font-family: $base-font;
  box-shadow: 0px 2px 0px $box-shadow;
  background: $white;

  &.bh-nav--scrolled {
    position: fixed;
  }


  &.--mobile-open {
    &::after {
      content: '';
      position: fixed;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);
      top: 0;
      left: 0;

      @include larger-than(tablet) {
        display: none;
      }
    }
  }
}

.bh_nav-inner {
  max-width: $large-width;
  margin: 0 auto;
  @include larger-than(tablet) {
    display: flex;
  }
}

.bh_nav__logo {
  max-width: 182px;
}

.bh_nav__logo--invert {
  display: none;
}

.bh_nav__left {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 18px;

  @include larger-than(tablet) {
    height: 92px;
    padding: 0 0 0 30px;
  }
}

.bh_nav__toggle {
  @include reset-button;
  display: flex;

  @include larger-than(tablet) {
    display: none;
  }
}

.bh_nav__right {
  position: fixed;
  z-index: 2;
  background: $white;
  width: 100%;
  height: 0%;
  top: 0;
  padding: 0 18px;
  transform: translateX(100%);
  opacity: 0;
  visibility: hidden;
  transition: transform 300ms, opacity 100ms 1s, visibility 100ms 1s,
    height 100ms 1s;

  .--mobile-open & {
    height: 100%;
    transform: translateX(0%);
    opacity: 1;
    visibility: visible;
    transition: transform 300ms, opacity 100ms, visibility 100ms;

    @include larger-than(tablet) {
      transform: none;
      opacity: 1;
      visibility: visible;
    }
  }

  @include larger-than(tablet) {
    position: static;
    height: auto;
    padding: 0 50px 0 18px;
    transform: none;
    opacity: 1;
    visibility: visible;
    transition: none;
  }
}

.bh_nav__right-header {
  display: flex;
  justify-content: space-between;
  height: 60px;
  align-items: center;

  @include larger-than(tablet) {
    display: none;
  }
}

.bh_nav__scroll {
  overflow: auto;
  height: calc(100% - 60px);
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 0 20px;

  @include larger-than(tablet) {
    overflow: visible;
    height: auto;
    position: static;
    padding: 0;
    display: flex;
    justify-content: space-between;
  }
}

.bh_nav__links[role='list'] {
  @include reset;
  list-style: none;

  a {
    color: $black;

    &:hover {
      text-decoration: none;
      @include larger-than(tablet) {
        color: $inspirationBlue;
      }
    }
  }

  @include larger-than(tablet) {
    display: flex;
  }
}

.bh_nav__links-item {
  position: relative;
  border-bottom: 1px solid $box-shadow;

  @include larger-than(tablet) {
    border-bottom: none;
    position: relative;
  }
}

.bh_nav__link {
  font-size: $text-headline;
  line-height: 28px;
  font-weight: $font-weight-bold;
  padding: 22px 0;
  display: block;
  position: relative;

  .bh_nav-links-expandable & {
    &::after {
      content: '';
      position: absolute;
      background-image: url('../images/icons/chevron-blue.svg');
      background-size: 14px;
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
      top: 50%;
      transform: translateY(-50%) rotate(90deg) scaleX(1);
      transition: transform 400ms;
      right: 0;

      @include larger-than(tablet) {
        display: none;
      }
    }
  }

  .bh_nav-links-expandable.bh-nav--dropdown-open & {
    &::after {
      transform: translateY(-50%) rotate(90deg) scaleX(-1);

      @include larger-than(tablet) {
        transform: none;
      }
    }
  }

  @include larger-than(tablet) {
    font-size: #{$text-body};
    font-weight: #{$font-weight-medium};
    padding: 0 20px;
    display: flex;
    height: 92px;
    align-items: center;

    &.--selected {
      font-weight: $font-weight-bold;
      &::before {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 20px;
        height: 2px;
        width: calc(100% - 40px);
        background: $inspirationBlue;
      }
    }

    .bh-nav--dropdown-open & {
      &::before {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 20px;
        height: 2px;
        width: calc(100% - 40px);
        background: $inspirationBlue;
      }

      &::after {
        display: block;
        content: '';
        width: 100%;
        height: 4px;
        bottom: -2px;
        left: 0;
        background: transparent;
        top: inherit;
        transform: none;
        right: inherit;
      }
    }
  }
}

.bh_nav__logins {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;

  @include larger-than(tablet) {
    margin-top: 0;
  }
}

.bh_nav__login-login {
  margin-right: 30px;

  &:hover {
    text-decoration: none;
    color: $inspirationBlue;
  }
}

.bh_nav__links-inner {
  @include reset;
  list-style: none;
  max-height: 0;
  overflow: hidden;
  transition: max-height 400ms;
  visibility: hidden;

  .bh-nav--dropdown-open & {
    max-height: 900px;
    visibility: visible;
  }

  @include larger-than(tablet) {
    position: absolute;
    background: $white;
    width: 200px;
    top: 102%;
    left: 0;
    padding: 0 0 20px;
    display: none;
    max-height: inherit;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);

    .bh-nav--dropdown-open & {
      display: block;
      max-height: inherit;
    }
  }
}

.bh_nav__link-inner {
  padding: 15px 0;
  font-weight: $font-weight-medium;
  display: block;

  @include larger-than(tablet) {
    padding: 8px 20px;
  }
}

.bh_nav__links-inner-item {
  &:nth-child(1) {
    .bh_nav__link-inner {
      padding: 0 0 15px;
      @include larger-than(tablet) {
        padding: 10px 20px 8px;
      }
    }
  }
  &:last-of-type {
    .bh_nav__link-inner {
      padding: 15px 0 30px;

      @include larger-than(tablet) {
        padding: 10px 20px 8px;
      }
    }
  }

  &.--desktop-hidden {
    @include larger-than(tablet) {
      display: none;

      & + li {
        .bh_nav__link-inner {
          padding: 10px 20px 8px;
        }
      }
    }
  }
}

.bh_nav__logo-link {
  display: flex;
  align-items: center;

  img {
    vertical-align: bottom;
  }
}

#s4s-home {
  .bh_nav {
    box-shadow: none;
    &.bh-nav--scrolled {
      background: $white;
      box-shadow: 0px 2px 0px $box-shadow;
    }
  }

  .bh_nav:not(.bh-nav--scrolled) {
    background-color: transparent;

    .bh_nav__logo--invert {
      display: block;
    }

    .bh_nav__logo--normal {
      display: none;
    }

    .bh_nav__right {
      @include larger-than(tablet) {
        background-color: transparent;
      }
    }

    .bh_nav__links-item > a,
    .bh_nav__logins a {
      @include larger-than(tablet) {
        color: $white;
      }

      &:hover {
        @include larger-than(tablet) {
          font-weight: $font-weight-bold;
        }
      }
    }

    .bh_nav__left .bh_nav__toggle {
      svg {
        > * {
          stroke: $white;
        }
      }
    }

    .--mobile-open .bh_nav__left .bh_nav__toggle {
      svg {
        > * {
          stroke: $black;
        }
      }
    }
  }
}
