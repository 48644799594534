.bh_manage-products-alignment {

    .bh_type-subhead{
        margin-bottom: 12px;
    }

    .bh_type-title-3{
        max-width:650px;
    }

    .bh_type-body{
        margin-bottom: 22px;
    }

    img {
        align-self: center;
    }

}

.bh_manage-products {
    .bh_remove-bottom-margin {
        margin-bottom:0px;
    }
    @include larger-than (tablet){
        .bh_manage-product-alignment {
            padding-top:0px;
        }
    }

    .bh_adjust-image {
        max-width:100%;

        @include larger-than (tablet){
            max-width:90%;
        }
    }

    .bh_title-two-columns-body-left {
        flex-direction: column;
    }

}

.bh_manage-products-margin {
    margin-top:0px;
    .bh_image-left {
        margin-top:0px;
    }
    @include larger-than(tablet){
        .bh_image-left {
            margin-top:-60px;
        }
    }
    
}