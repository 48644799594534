.bh_left-text-wide-image {
  img {
    max-width: 329px;
    width: 100%;
  }

  .bh_image-mobile-overflow & {
    margin-bottom: -40px;

    @include larger-than(mobile) {
      margin-bottom: -80px;
    }

    @include larger-than(smallTablet) {
      margin-bottom: 0;
    }

    .bh_image-right {
      position: relative;

      img {
        position: relative;
        z-index: 1;
      }

      &::after {
        content: '';
        background: $offWhite;
        position: absolute;
        width: 100vw;
        height: 80px;
        bottom: 0;
        left: -16px;

        @include larger-than(smallTablet) {
          display: none;
        }
      }
    }
  }
}