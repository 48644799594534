.bh_static-stats {
  margin-top: 30px;

  @include larger-than(mobile) {
    margin-top: 90px;
  }

  @include larger-than(tablet) {
    margin-top: 30px;
  }
}

.bh_static-stats__inner {
  margin-bottom: 20px;
  margin-top: 30px;

  @include larger-than(mobile) {
    display: flex;
    flex-wrap: wrap;
    margin-right: -#{$gutter};
    margin-top: 40px;
  }

  & > * {
    &:first-of-type {
      margin-bottom: 16px;

      @include larger-than(mobile) {
        margin-bottom: 0;
      }
    }

    @include larger-than(mobile) {
      width: calc(50% - #{$gutter});
      margin-right: $gutter;
    }
  }
}

.bh_static-stats__card {
  background: $inspirationBlue;
  border-radius: 30px;
  padding: 30px;
  display: flex;
  justify-content: space-between;

  p:first-of-type {
    font-size: $text-large-title;
  }

  @include larger-than(mobile) {
    padding: 30px 40px;
  }

  & > div:first-of-type {
    max-width: 240px;
  }

  img {
    max-width: 62px;
  }
}