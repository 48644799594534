.bh_mobile-ecommerce{

    .bh_title-two-columns-headline .bh_type-title-3{
        max-width:650px;
    }

    .bh_title-two-columns-body {
        p {
            margin-bottom:12px;
        }

        &-left {
           align-items: center; 
        }
        
    }

    .bh_title-subtitle .bh_type-title-3 {
        margin-bottom:0;
    }

    .bh_manage-orders-image {
        flex-grow:unset !important;
        img {
            padding:0 8px;
        }
    }

    .bh_manage-orders-align{
        padding-left:0px !important;
    }



    .bh_shift-right-manage-orders {
        @include larger-than(tablet) {
          padding: auto;
            
        }
      }
    }

    .bh_manage-orders-large-centered-image {
            flex-grow:unset !important;
            img {
                max-width: 600px;
                padding:0 8px;
            }


}