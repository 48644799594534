// Font family
$base-font: freight-sans-pro, sans-serif;

// Font weights
$font-weight-thin: 300;
$font-weight-reg: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

// Font sizes
$text-feature: 56px;
$text-large-title: 36px;
$text-title-1: 30px;
$text-title-2: 22px;
$text-title-3: 28px;
$text-headline: 18px;
$text-body: 16px;
$text-callout: 14px;
$text-subhead: 15px;
$text-footnote: 13px;
$text-caption: 11px;

// Widths
$large-width: 1280px;
$container-width: 1120px;
$medium-width: 836px;

// Spacing
$gutter: 16px;
$column-lg: 552px;
$column-m: 836px;
$column-sm: 410px;
$shift: 142px;
