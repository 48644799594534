.bh_text-video_inner {
  max-width: 637px;
  width: 100%;
  margin: 0 auto;

  + * {
    margin-top: 22px;
  }
}

.bh_text-video__iframe {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
  margin-top: 30px;

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}