@mixin absolute-center-vertical {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin absolute-center-horizontal {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin absolute-center-both {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin reset {
  margin: 0;
  padding: 0;
}

@mixin reset-button {
  margin: 0;
  padding: 0;
  border: 0;
  appearance: none;
  background: transparent;
}

@mixin bh_button($height, $maxWidth, $bgColor, $bgColorHover, $color, $colorHover, $fontWeight: $font-weight-medium, $border: none, $borderHover: none) {
  @include reset-button;
  height: $height;
  min-width: $maxWidth;
  background-color: $bgColor;
  border: $border;
  font-weight: $fontWeight;
  color: $color;
  &:hover {
    background-color: $bgColorHover;
    color: $colorHover;
    border: $borderHover;
  }
}

@mixin bh_button-arrow($color, $colorHover, $arrow, $arrowHover, ) {
  @include reset-button;
  font-weight: $font-weight-bold;
  text-decoration: underline;
  color: $color;
  position: relative;
  padding-right: 32px;

  &::after {
    content: '';
    position: absolute;
    height: 17px;
    width: 21px;
    background-image: url($arrow);
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &:hover {
    color: $colorHover;

    &::after {
      background-image: url($arrowHover);
    }
  }
}