//Primary
$black: #000000;
$white: #ffffff;

$box-shadow: #eaeaea;

$spaceBlack: #090304;
$inspirationBlue: #136ef6;
$rocketGray: #88969e;
$lightGray: #c1c1c1;
$offWhite: #f9f9f9;
$cloudWhite: #ffffff;
$errorRed: #b41b1b;
$goGreen: #357e29;

$blueOpacity: rgba(0, 107, 237, 0.2);