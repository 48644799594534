.bh_store-design {

    &-adjust-margin{
        margin-top:0px;
        padding-top:40px;
        .bh_image-left {
            margin-top:0px;
        }
        @include larger-than(smallTablet){
            .bh_image-left {
                margin-top:-80px;
            }
        }
    }

    &-multiple-columns {
        .image-column{
            align-items: flex-start;
            margin-top: 12px;
        }

    }

    &-desktop-only-image{
        display:none;
        @include larger-than(smallTablet){
            display:flex;
        }
    }

    &-title-two-columns {
        img {
            max-width:400px;
        }

        p {
            margin-bottom:22px;
        }

        .bh_columns {
            flex-direction: column-reverse;
            padding-bottom:40px;
            @include larger-than (smallTablet){
                flex-direction: row;
            }
        }
    }

    @include larger-than (smallTablet){
        .bh_alignment-margin {
            padding-left:16px;
        }
    }
}