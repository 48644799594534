.bh_type-feature,
%bh_type-feature {
  font-size: $text-feature;
  line-height: 60px;
  font-family: $base-font;
  font-weight: $font-weight-bold;
  color: inherit;
}

.bh_type-highlight,
%bh_type-highlight {
  line-height: 36px;
  font-family: $base-font;
  font-size: $text-title-2;
  font-weight: $font-weight-medium;
  color: inherit;
}

.bh_type-large-title,
%bh_type-large-title {
  line-height: 44px;
  font-family: $base-font;
  font-size: $text-large-title;
  font-weight: $font-weight-bold;
  color: inherit;
  margin-bottom: 22px;
  @include smaller-than(smallTablet) {
    font-size: 22px;
    line-height: 28px;
    br {
      display: none;
    }
  }
}

.bh_type-title-1,
%bh_type-title-1 {
  line-height: 40px;
  font-family: $base-font;
  font-size: $text-title-1;
  font-weight: $font-weight-bold;
  color: inherit;
  margin-bottom: 22px;
}

.bh_type-title-2,
%bh_type-title-2 {
  line-height: 28px;
  font-family: $base-font;
  font-size: $text-title-2;
  font-weight: $font-weight-bold;
  color: inherit;
  margin-bottom: 22px;
}

.bh_type-title-3,
%bh_type-title-3 {
  line-height: 28px;
  font-family: $base-font;
  font-size: $text-title-2;
  font-weight: $font-weight-medium;
  color: inherit;
  margin-bottom: 22px;
}

.bh_type-headline,
%bh_type-headline {
  line-height: 28px;
  font-family: $base-font;
  font-size: $text-headline;
  font-weight: $font-weight-bold;
  color: inherit;
}

.bh_type-body,
%bh_type-body {
  line-height: 28px;
  font-family: $base-font;
  font-size: $text-body;
  font-weight: $font-weight-medium;
  color: inherit;
}

.bh_type-callout,
%bh_type-callout {
  line-height: 26px;
  font-family: $base-font;
  font-size: $text-callout;
  font-weight: $font-weight-medium;
  color: inherit;
}

.bh_type-subhead,
%bh_type-subhead {
  line-height: 28px;
  font-family: $base-font;
  font-size: $text-subhead;
  font-weight: $font-weight-bold;
  color: inherit;
}

.bh_type-footnote,
%bh_type-footnote {
  line-height: 18px;
  font-family: $base-font;
  font-size: $text-footnote;
  font-weight: $font-weight-medium;
  color: inherit;
}

.bh_type-caption,
%bh_type-caption {
  line-height: 15px;
  font-family: $base-font;
  font-size: $text-caption;
  font-weight: $font-weight-medium;
  color: $black;
}

.bh_strong {
  font-weight: $font-weight-bold;
  color: $black;
}

.bh_a {
  color: inherit;
  text-decoration: underline;
  font-family: $base-font;

  &:hover {
    color: $inspirationBlue;
  }

}

.bh_a-blue {
  color: inherit;
  text-decoration: underline;
  font-family: $base-font;

  &:hover {
    color: $black;
  }

}

.bh_margin {
  margin-bottom: 22px;
}

.bh_margin-double {
  margin-bottom: 44px;
}

.bh_center {
  text-align: center;
}

.bh_left {
  text-align: left;
}

.bh_right {
  text-align: right;
}

.bh_mobile-text-center{
  text-align: center;
  
  @include larger-than(mobile) {
    text-align: inherit;
  }
}

.bh_type-subhead-mobile-body,
%bh_type-subhead-mobile-body {
  line-height: 28px;
  font-family: $base-font;
  font-size: $text-subhead;
  font-weight: $font-weight-bold;
  color: inherit;

  @include smaller-than(smallTablet) {
    line-height: 28px;
    font-family: $base-font;
    font-size: $text-body;
    font-weight: $font-weight-medium;
    color: inherit;
   }

}

.bh_mobile-text-left{
  text-align: inherit;
   @include smaller-than(smallTablet) {
     text-align: left;
   }
   
 }
 
