.bh_landing-signup-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  h1 {
    @extend %bh_type-large-title;

    @include larger-than(tablet) {
      line-height: 40px;
      font-size: $text-title-1;
      font-weight: $font-weight-bold;
      color: inherit;
      margin-bottom: 22px;
    }
  }
}

.bh_landing-signup-form__form-body {
  display: flex;
  max-width: 650px;
  width: 100%;
  flex-direction: column;

  @include larger-than(mobile) {
    flex-direction: row;
  }

  input {
    height: 45px;
    background-color: $white;
    color: $black;
    border: 1px solid $black;

    @include larger-than(tablet) {
      border-right: none;
    }
  }
  button {
    height: 45px;
    border: 1px solid $black;
    &:hover {
      color: $black;
    }
  }
}