.bh_title-icon-list__content {
  margin-bottom: 35px;

  @include larger-than(smallTablet) {
    margin-bottom: 40px;
  }
}

.bh_title-icon-list__list {
  li {
    display: flex;

    > div:last-of-type {
      margin-top: 10px;
    }

    > div:first-of-type {
      width: 48px;
      margin-right: 24px;
      flex-shrink: 0;

      img {
        width: 100%;
      }
    }
  }
}