.bh_plan-features {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    @extend %bh_type-title-1;

    @include larger-than(tablet) {
      line-height: 44px;
      font-size: $text-large-title;
    }
  }

  ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: $gutter;

    @include larger-than(tablet) {
      justify-content: flex-start;
      max-width: 847px;
      transform: translate(5%, 0);
      margin-top: 40px;
    }
  }

  li {
    width: 100%;
    text-align: left;
    @extend %bh_type-callout;
    width: calc(50% - 8px);

    @include larger-than(tablet) {
      width: 33%;
    }

    a {
      color: $inspirationBlue;
    }

    margin-bottom: 20px;

    position: relative;
    padding-left: 36px;

    &::before {
      content: '';
      background-image: url('../images/icons/checkmark-circle.svg');
      width: 27px;
      height: 27px;
      position: absolute;
      background-size: 27px;
      left: 0;
    }
  }
}
