.bh_grid {
  display: flex;
  margin-right: -#{$gutter};

  & > * {
    width: calc((100% / 8) - #{$gutter});
    margin-right: $gutter;
  }
}

.bh_spacing-large {
  &.--top {
    padding-top: 40px;
    @include larger-than(mobile) {
      padding-top: 80px;
    }
  }

  &.--bottom {
    padding-bottom: 40px;
    @include larger-than(mobile) {
      padding-bottom: 80px;
    }
  }
}

.bh_spacing-standard {
  &.--top {
    padding-top: 30px;
    @include larger-than(mobile) {
      padding-top: 50px;
    }
  }
  &.--bottom {
    padding-bottom: 30px;
    @include larger-than(mobile) {
      padding-bottom: 50px;
    }
  }
}

.bh_spacing-primary {
  &.--top {
    padding-top: 22px;
    @include larger-than(mobile) {
      padding-top: 30px;
    }
  }
  &.--bottom {
    padding-bottom: 22px;
    @include larger-than(mobile) {
      padding-bottom: 30px;
    }
  }
}

.bh_spacing-secondary {
  &.--top {
    padding-top: 16px;
    @include larger-than(mobile) {
      padding-top: 22px;
    }
  }
  &.--bottom {
    padding-bottom: 16px;
    @include larger-than(mobile) {
      padding-bottom: 22px;
    }
  }
}

.bh_spacing-small {
  &.--top {
    padding-top: 10px;
    @include larger-than(mobile) {
      padding-top: 16px;
    }
  }
  &.--bottom {
    padding-bottom: 10px;
    @include larger-than(mobile) {
      padding-bottom: 16px;
    }
  }
}

%bh_input {
  width: 197px;
  height: 50px;
  padding-left: 20px;
  border-radius: 2px;
  outline: none;
  background: transparent;

  .bh_input--error & {
    border: 1px solid $errorRed;
  }
}

.bh_input-message {
  .bh_input--success & {
    color: $goGreen;
  }

  .bh_input--error & {
    color: $errorRed;
  }
}

.bh_input-white {
  @extend %bh_input;
  color: $spaceBlack;
  border: 1px solid $lightGray;

  &::-webkit-input-placeholder {
    color: $lightGray;
  }
  &::-moz-placeholder {
    color: $lightGray;
  }
  &:-ms-input-placeholder {
    color: $lightGray;
  }
  &::-ms-input-placeholder {
    color: $lightGray;
  }
  &::placeholder {
    color: $lightGray;
  }
}

.bh_input-black {
  @extend %bh_input;
  color: $white;
  border: 1px solid $black;

  &::-webkit-input-placeholder {
    color: $white;
  }
  &::-moz-placeholder {
    color: $white;
  }
  &:-ms-input-placeholder {
    color: $white;
  }
  &::-ms-input-placeholder {
    color: $white;
  }
  &::placeholder {
    color: $white;
  }
}

.bh_ul {
  @include reset;
  list-style: none;
}

// Colors

.bh_color-blue {
  color: $inspirationBlue;
}

.bh_color-black {
  color: $black;
}

.bh_color-white {
  color: $white;
}

.bh_color-black {
  color: $black;
}

.bh_c-space-black {
  color: $spaceBlack;
}

.bh_color-rocketGray {
  color: $rocketGray;
}

.bh_background-off-white,
.bh_bg-off-white {
  background: $offWhite;
}

.bh_background-black {
  background: $black;
}

.bh_background-blue {
  background: $inspirationBlue;
}

// Containers

.bh_container1120 {
  max-width: calc(#{$container-width} + #{$gutter} * 2);
  width: 100%;
  margin: 0 auto;
  padding: 0 $gutter;
}

.bh_shift-left {
  @include larger-than(tablet) {
    padding-left: $shift;
  }
}

.bh_shift-right {
  @include larger-than(tablet) {
    padding-right: $shift;
  }
}

.bh_shift-left-small {
  @include larger-than(tablet) {
    padding-left: 72px;
  }
}

.bh_shift-right-small {
  @include larger-than(tablet) {
    padding-right: 72px;
  }
}

.bh_grid-wide-left {
  @include larger-than(smallTablet) {
    display: flex;
  }

  > div:first-of-type {
    margin-bottom: 22px;
    @include larger-than(smallTablet) {
      flex-grow: 1;
      max-width: $column-lg;
      margin-bottom: 0;
    }
  }

  > div:last-of-type {
    @include larger-than(smallTablet) {
      padding-left: $gutter;
      flex-grow: 1;
    }
  }
}

.bh_grid-narrow-left {
  @include larger-than(smallTablet) {
    display: flex;
  }

  > div:first-of-type {
    margin-bottom: 22px;
    @include larger-than(smallTablet) {
      flex-grow: 1;
      max-width: $column-sm;
      margin-bottom: 0;
    }
  }

  > div:last-of-type {
    @include larger-than(smallTablet) {
      padding-left: $gutter;
      flex-grow: 1;
    }
  }
}

.bh_columns {
  @include larger-than(smallTablet) {
    display: flex;
    flex-wrap: wrap;
    margin-right: -#{$gutter};
  }
}

.bh_columns--2 {
  & > * {
    @include larger-than(smallTablet) {
      width: calc(50% - #{$gutter});
      margin-right: $gutter;
    }
  }
}

.bh_columns--2-3 {
  @include larger-than(smallTablet) {
    flex-wrap: nowrap;
    margin-right: -#{$gutter} * 2;
  }

  & > * {
    &:first-of-type {
      @include larger-than(smallTablet) {
        width: 30%;
        flex-shrink: 0;
        margin-right: $gutter * 2;
      }
    }
    
  }
}

.bh_columns--3 {
  & > * {
    @include larger-than(smallTablet) {
      width: calc(33.33% - #{$gutter});
      margin-right: $gutter;
    }
  }
}

.bh_grid-two-column-split {
  @include larger-than(smallTablet) {
    display: flex;
    flex-wrap: wrap;
    margin-right: -#{$gutter};

    & > * {
      width: calc(50% - #{$gutter});
      margin-right: $gutter;
    }
  }
}

.bh_image-left {
  text-align: center;
  @include larger-than(smallTablet) {
    text-align: left;
  }
}

.bh_image-right {
  text-align: center;
  @include larger-than(smallTablet) {
    text-align: right;
  }
}

.bh_desktop-only {
  display: none;

  @include larger-than(smallTablet) {
    display: block;
  }
}

.bh__max-width-large {
  max-width: $column-lg;
  width: 100%;
}

.bh__max-width-large-center {
  max-width: $column-lg;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.bh__max-width-medium-center {
  max-width: $column-m;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

// Screen reader only
.sr-only {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}


.bh_video-container{
  height:300px;
  width:100%;
  max-height:400px;
  max-width:650px;
  @include larger-than(smallTablet){
      height:100%;
  }
}

.bh_image-mobile-padding{
  padding: inherit;

  @include smaller-than(smallTablet) {
    padding: 0 20px 0 20px;
  }
  
}

.bh_image-mobile-padding-after{
  padding: inherit;

  @include smaller-than(smallTablet) {
    padding: 0 0 30px 0;
  }
  
}

.bh_image-mobile-max-height{
  max-height: inherit;
  @include smaller-than(smallTablet) {
    max-height: 200px;
  }
}

.bh_img-mobile-max-75{
  @include smaller-than(smallTablet) {
    width: 75%
  }
}

.bh_img-mobile-max-50{
  @include smaller-than(smallTablet) {
    width: 50%
  }
}

.bh_break-word {
  word-break: break-all;
}