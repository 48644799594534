.bh_title-subtitle {
  img {
    vertical-align: bottom;
    max-width: 829px;
    width: 100%;
    margin-top: 10px;

    @include larger-than(smallTablet) {
      margin-top: 40px;
    }
  }
}