.bh_marketing-seo {

    &-second-title {
        section {
            padding-bottom:0px !important;
        }

        .bh_type-title-3 {
            font-size:$text-body;
            line-height: 28px;
        }
    }
    &-copy-with-checkboxes {
        section {
            padding-top:0px !important;
        }
    }

    .image-wide-column-title-image {
        height:42px;
        width:140px;
    }
}