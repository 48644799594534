.bh_copy-with-icon-list {
  h2 {
    max-width: 522px;
  }
}

.bh_copy-with-icon-list-grid {
  div:last-of-type {
    img {
      max-width: 329px;
      width: 100%;
    }
  }
}

.bh_copy-with-icon-list-list {
  &.--wide {
    max-width: 700px;
  }

  li {
    display: flex;

    & + li {
      @include larger-than(smallTablet) {
        margin-top: 30px;
      }
    }

    > div:first-of-type {
      width: 48px;
      margin-right: 24px;
      flex-shrink: 0;

      img {
        width: 100%;
      }
    }
  }
}