.bh_text-image-text {
  img {
    max-width: 343px;
  }

  > div:last-of-type {
    border-top: 1px solid $rocketGray;
    padding-top: 30px;
    margin-top: 30px;

    @include larger-than(smallTablet) {
      margin-top: 50px;
    }
  }
}