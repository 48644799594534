.bh_review-carousel {
  @include larger-than(smallMobile) {
    margin-top: 50px;
  }
}

.bh_review-carousel__copy {
  margin-bottom: 30px;

  @include larger-than(smallMobile) {
    font-size: $text-title-2;
    margin-bottom: 16px;
  }
}

.bh_review-carousel_reviewer {
  & > * {
    display: inline-block;
    vertical-align: middle;
  }

  .bh_review-carousel_reviewer-info {
    margin-left: 16px;

    .bh_review-carousel_reviewer-name {
      line-height: 1;
    }

    p {
      margin: 0;
    }
  }
}

.bh_review-carousel_reviewer-img {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}