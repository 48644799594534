.bh_pricing-hero {
  > div {
    > h1 {
      @extend %bh_type-feature;
      font-size: $text-large-title;
      line-height: 44px;
      margin-bottom: 12px;

      @include larger-than(tablet) {
        font-size: $text-feature;
        line-height: 60px;
      }
    }
    > p {
      @extend %bh_type-highlight;
    }
  }
}

.bh_pricing-plans-grid {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 22px;
  flex-wrap: wrap;

  @include larger-than(tablet) {
    margin-top: 50px;
  }
}

.bh_pricing-plans-grid__item {
  width: calc(50% - 8px);
  margin-bottom: 16px;
  background-color: $white;
  color: $black;
  border: 1px solid $white;
  display: flex;
  flex-direction: column;

  &:nth-child(n+3) {
    margin-top: 20px;

    @include larger-than(tablet) {
      margin-top: 0;
    }
  }

  @include larger-than(tablet) {
    width: calc(25% - 8px);
    margin-bottom: 0;
  }

  button {
    &:hover {
      color: $black;
    }
  }
}

.bh_pricing-plans-grid__item-header {
  background-color: $inspirationBlue;
  position: relative;
  color: $white;
  padding: 6px 0;

  @include larger-than(tablet) {
    padding: 16px 18px 12px;
  }

  h2 {
    @extend %bh_type-subhead;
  }

  p {
    @extend %bh_type-callout;
    margin-top: 0px;
    margin-bottom: 0px;
    @include larger-than(tablet) {
      margin: 0 14px;
      font-size: $text-body;
    }
  }
}

.bh_pricing-plans-grid__item-price {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 10px;

  @include larger-than(tablet) {
    margin-top: 6px;
  }

  > span:nth-child(1) {
    @extend %bh_type-footnote;
    margin-right: 4px;
  }
  > span:nth-child(2) {
    @extend %bh_type-feature;
    line-height: 24px;
    font-size: $text-large-title;
    @include larger-than(tablet) {
      font-size: $text-feature;
    }
  }
  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 6px;

    > span:nth-child(1) {
      @extend %bh_type-headline;
      line-height: 16px;
    }
    > span:nth-child(2) {
      @extend %bh_type-footnote;
      margin-top: 4px;
    }
  }
}

.bh_pricing-plans-grid__item-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  position: relative;

  padding: 12px 7px 10px;

  @include larger-than(tablet) {
    padding: 24px 56px 29px;
    min-height: 227px;
    &-special {
      padding-right: 30px;
      padding-left: 30px;
      align-items: center;
      button {
        width:160px;
      }
    }
  }

  a {
    text-decoration: none;
  }

  ul, li {
    margin: 0;
    padding: 0;
    text-align: left;
  }

  ul {
    margin-bottom: 16px;
    margin-left: 18px;
    @include larger-than(tablet) {
      margin-bottom: 24px;
    }
  }

  li {
    @extend %bh_type-callout;
    @include larger-than(tablet) {
      font-size: $text-body;
    }
  }

  span {
    @extend %bh_type-footnote;
    font-style: normal;
    position: absolute;
    bottom: 6px;
    left: 50%;
    transform: translate(-50%, 0);
    display: none;

    @include larger-than(tablet) {
      display: block;
    }
  }
}

.bh_pricing-plans-grid__item-callout {
  position: absolute;
  color: $black;
  background-color: $white;
  top: 0;
  left: -1px;
  right: -1px;
  transform: translate(0, -100%);
  @extend %bh_type-callout;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  @include larger-than(tablet) {
    padding: 2px 0;
  }
}
