.bh_faqs {
  .bh_faq {
    border-top: 1px solid $black;
    cursor: pointer;
    position: relative;
    &:last-child {
      border-bottom: 1px solid $black;
    }
  }
}

.bh_faq-question {
  @extend .bh_type-body;
  line-height: 20px;
  padding: 5px 0;
  width: calc(100% - 34px);
  font-weight: bold;
  position: relative;
  height: 70px;
  display: flex;
  align-items: center;
  color: $black;
  &:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '+';
    border-radius: 100%;
    width: 20px;
    height: 20px;
    color: #fff;
    background-color: $black;
    font-size: 18px;
    line-height: 16px;
    display: block;
    text-align: center;
    font-weight: 700;
    position: absolute;
    margin: auto;
    padding-left: 1px;
    top: 50%;
    right: -24px;
    transform: translateY(-50%);
  }
}

.bh_faq-answer {
  display: none;
  padding: 5px 0 15px;
  width: calc(100% - 24px);
  @extend .bh_type-body;
  color: $black;
}

.active {
  .bh_faq-question:after {
    content: '-';
  }
}
