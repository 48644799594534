.bh_pricing-hero {
  .bh_type-feature {
    @include smaller-than(smallTablet) {
      font-size: $text-title-1;
      line-height: 40px;
      margin-bottom: 14px;
    }
  }
  .bh_type-headline {
    @include smaller-than(smallTablet) {
      font-size: $text-body;
      font-weight: $font-weight-medium;
      line-height: 28px;
      br {
        display: none;
      }
    }
  }
}

.bh_pf-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @include smaller-than(smallTablet) {
    flex-direction: column;
    h4,
    p {
      text-align: center;
    }
  }

  .bh_pf-left {
    width: 60%;
    border-bottom: 1px solid $white;
    &.bh_line-dark {
      border-bottom: 1px solid $spaceBlack;
    }
    @include smaller-than(smallTablet) {
      width: 100%;
      border-bottom: none !important;
    }
  }
  .bh_pf-right {
    width: 33.33%;
    img {
      max-width: 100%;
    }
    @include smaller-than(smallTablet) {
      width: 100%;
      img {
        max-width: 75%;
        display: block;
        margin: auto;
      }
    }
  }
}

.bh-checklist {
  padding: 30px 0 0;
  display: flex;
  flex-wrap: wrap;

  @include smaller-than(smallTablet) {
    display: block;
    columns: 2;
    column-gap: 16px;
  }

  li {
    list-style-type: none;
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
    width: 33%;
    position: relative;
    height: 42px;
    &.bh-inv {
      opacity: 0;
    }
    @include smaller-than(smallTablet) {
      align-items: flex-start;
      width: inherit;
      height: auto;
      margin-bottom: 22px;
      -webkit-column-break-inside: avoid;
          page-break-inside: avoid;
               break-inside: avoid;
      br {
        display: none;
      }
    }

    span {
      width: calc(100% - 36px);
      @include smaller-than(smallTablet) {
        width: calc(100% - 29px);
      }
    }

    &:before {
      width: 36px;
      height: 26px;
      content: ' ';
      background-image: url('../images/pricing/check.svg');
      background-repeat: no-repeat;
      background-size: 26px 26px;
      @include smaller-than(smallTablet) {
        background-size: 19px 19px;
        width: 29px;
        height: 19px;
      }
    }
  }
}

.bh_pricing-main {
  h4 {
    @include smaller-than(smallTablet) {
      font-size: $text-title-1;
      line-height: 40px;
      br {
        display: none;
      }
    }
  }

  & > div p {
    margin-bottom:22px;
  }
  
  .bh_btn-arrow {
    text-decoration: underline;
    font-weight: bold;
    margin-left: 20px;
    &:after {
      width: 20px;
      height: 15.5px;
      content: ' ';
      background-image: url('../images/pricing/arrow.svg');
      background-repeat: no-repeat;
      background-size: 20px 15.5px;
      display: inline-block;
      margin: 0 0 -2px 10px;
    }
  }
}

.bh_unlimited {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .bh_unlim1 {
    width: 41%;
    img {
      max-width: 100%;
    }
    @include smaller-than(smallTablet) {
      display: none;
    }
  }

  .bh_unlim2 {
    width: 55%;
    padding-left: 5%;
    @include smaller-than(smallTablet) {
      width: 100%;
      padding-left: 0;
      text-align: center;
    }
    ul {
      text-align: left;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding-left: 16px;
      @include smaller-than(smallTablet) {
        columns: 2;
        column-gap: 32px;
        display: block;
        max-width: 600px;
        margin: 10px auto;
      }
      li {
        flex-grow: 1;
        width: 48%;
        margin-bottom: 7.5px;
        @include smaller-than(smallTablet) {
          width: inherit;
        }
      }
    }
  }
}

.bh_pricing-slider {
  margin-bottom: 40px;
  overflow-x: scroll;
  margin-right: calc(50% - 50vw);

  @include larger-than(mobile) {
    overflow: inherit;
    margin-right: inherit;
    margin-top: 40px;
  }
}

.bh_pricing-slider__table {
  table-layout: auto;
  width: 100%;
  text-align: left;
  border-spacing: 0;

  th, td {
    padding: 16px 0;
    border-bottom: 1px solid $black;
  }

  th {
    span {
      display: block;
    }

    &:first-of-type {
      position: sticky;
      left: 0;
      background-color: $offWhite;
      z-index: 1;

      @include larger-than(mobile) {
        position: static;
      }
    }
  }

  thead th, tbody td {
    &:nth-child(2) {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        width: 72%;
        min-width: 122px;
        height: 100%;
        background: $blueOpacity;
        top: 0;
        left: 0;

      }
    }
  }

  thead th {
    padding: 16px 20px;
    &:nth-child(2) {
      &::after {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
      }
    }
  }

  tbody {
    th {
      min-width: 180px;
    }

    td {
      min-width: 132px;
      padding: 16px 20px;
      @include larger-than(mobile) {
        min-width: inherit;
      }
    }

    tr {
      &:last-of-type {
        td:nth-child(2) {
          &::after {
            @include larger-than(mobile) {
              height: 125%;
              border-bottom-left-radius: 20px;
              border-bottom-right-radius: 20px;
            }
          }
        }
      }

      th {
        border-right: 1px solid $black;
        border-left: 1px solid $black;
        padding-left: 11px;

        @include larger-than(mobile) {
          border-right: none;
          border-left: none;
          padding-left: 0;
        }
      }
    }
  }
}