.bh_left-text-wide-bullets {
  li {
    display: flex;
    align-items: center;

    img {
      vertical-align: bottom;
    }

    & + li {
      margin-top: 28px;
    }

    p {
      margin: 0 0 0 23px;
    }
  }

  ul {
    margin-top: 30px;

    @include larger-than(smallTablet) {
      margin-top: 0;
    }
  }
}