.bh_signup-container{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 14px 0;
    h1 {
        text-align: center;
    }
}

.bh_signup-email{
    width: 100%;
    display: flex;
    flex-direction: column;
    input {
        background-color: $inspirationBlue;
        color: $cloudWhite;
        height: 50px;
        border: 1px solid $spaceBlack;
        border-bottom:none;
        padding: 0 16px;
    }

    input::placeholder{
        color: $cloudWhite;
    }

    a {
        height: 50px;
    }

    @include larger-than(mobile){
        width:650px;
        flex-direction: row;
        input {
            border: 1px solid $spaceBlack;
            border-right:none;
            width: 60%;
        }
        a {
            flex:1;
        }
    }
}
