.bh_pricing-cta__inner {
  align-items: center !important;
  flex-direction: column;
  display: flex;

  h1 {
    @extend %bh_type-title-2;
    @include larger-than(tablet) {
      font-size: $text-title-1;
    }
  }
}

.bh_pricing-cta__content {
  max-width: 550px;

  a {
    font-weight: $font-weight-bold;
    text-decoration: underline;
    color: $white;
  }

  p {
     @extend %bh_type-callout;
     line-height: 20px;

     @include larger-than(tablet) {
      line-height: 28px;
      font-size: $text-body;
     }
  }
}

.bh_pricing-cta__learn-more {
  margin-top: 32px;
}

.bh_pricing-cta__inspiration-logo {
  display: block;
  width: 100px;
  height: 100px;
  background: url(../images/homepage/inspiration.png);
  background-size: contain;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -20%);

  @include larger-than(tablet) {
    width: 160px;
    height: 160px;
  }
}