.image-column {
  h3 {
    margin-bottom: 10px;
  }

  @include larger-than(smallTablet) {
    display: flex;
    align-items: center;
    margin-right: -#{$gutter};
  }

  & > * {
    @include larger-than(smallTablet) {
      width: calc(50% - #{$gutter});
      margin-right: $gutter;
    }
  }

  & > div img {
    max-width: 247px;
    width: 100%;
    margin-bottom: 10px;

    @include larger-than(smallTablet) {
      margin-bottom: 0;
      max-width: 100%;
      max-height: 300px;
      height: auto;
      width: auto;
    }
  }

  & + & {
    margin-top: 40px;
  }
}

.image-column--right {
  @include larger-than(smallTablet) {
    flex-direction: row-reverse;
  }

  & > div:first-of-type {
    @include larger-than(smallTablet) {
      text-align: right;
    }
  }
}

.image-column-multiple {
  h2 {
    @include larger-than(smallTablet) {
      margin-bottom: 30px;
    }
  }
}