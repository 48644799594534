.bh_landing-features {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;

  h1 {
    @extend %bh_type-title-1;
    margin-bottom: 4px;
    margin-top: 48px;

    @include larger-than(tablet) {
      margin-top: 0;
    }
  }
  > p {
    @extend %bh_type-body;
    margin-top: 16px;

    @include larger-than(tablet) {
      margin-top: 0;
    }
  }

  ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 886px;

    @include larger-than(tablet) {
      flex-direction: row;
    }
  }

  li {
    width: 100%;
    margin-bottom: 24px;

    @include larger-than(tablet) {
      width: calc(33% - 40px);
      margin-bottom: 50px;
    }

    img {
      height: 42px;
      max-width: 46px;
    }

    h2 {
      margin-top: 8px;
      @extend %bh_type-headline;
    }

    p {
      @extend %bh_type-callout;
    }
  }
}