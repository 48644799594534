.bh_plan-slider {
  margin-bottom: 40px;
  overflow-x: scroll;
  margin-right: calc(50% - 50vw);

  @include larger-than(mobile) {
    overflow: inherit;
    margin-right: inherit;
    margin-top: 40px;
  }
}

.bh_plan-slider__table {
  table-layout: auto;
  width: 100%;
  text-align: left;
  border-spacing: 0;

  [data-tooltip] {
    position: relative;
    display: inline-flex;
    align-items: center;

    svg {
      display: none;

      @include larger-than(mobile) {
        display: block;
        margin-left: 6px;
        width: 16px;
      }
    }

    &::before {
      display: none;
      @extend %bh_type-callout;
      content: attr(data-tooltip);
      position: absolute;
      background-color: $lightGray;
      border-radius: 10px;
      padding: 10px;
      bottom: 126%;
      left: 0;
      width: 300px;
      box-shadow: 0px 2px 0px $box-shadow;
    }

    &:hover {
      @include larger-than(mobile) {
        &::before {
          display: block;
        }
      }
    }
  }

  svg {
    display: flex;
  }

  th, td {
    padding: 16px 0;
    border-bottom: 1px solid $black;
  }

  th {
    span {
      display: block;
    }

    &:first-of-type {
      position: sticky;
      left: 0;
      background-color: $white;
      z-index: 1;

      @include larger-than(mobile) {
        position: static;
        border-right: 1px solid black;
      }
    }
  }

  thead th, tbody td {
    &:nth-child(5) {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        width: 72%;
        min-width: 122px;
        height: 100%;
        background: $blueOpacity;
        top: 0;
        left: 0;

      }
    }
  }

  thead th {
    &:first-of-type() {
      padding-left: 11px;

      @include larger-than(mobile) {
        padding-left: 0;
      }
    }

    &:not(:first-of-type) {
      @include larger-than(mobile) {
        position: sticky;
        top: 60px;
        left: inherit;
        background: white;
        z-index: 1;
      }

      @include larger-than(tablet) {
        top: 92px;
      }
    }

    &:nth-child(n+2) {
      padding: 16px 20px;
    }

    &:nth-child(5) {
      &::after {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
      }
    }
  }

  tbody {
    tr:nth-last-child(2) {
      td:nth-child(5) {
        &::after {
          height: 125%;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
        }
      }
    }

    tr.bh_plan-header {
      > * {
      background-color: $offWhite;
      }

      th {
        font-size: $text-title-2;
      }
    }

    tr:not(:last-child) {
      th {
        border-right: 1px solid $black;
        border-left: 1px solid $black;
        padding-left: 11px;

        @include larger-than(mobile) {
          border-left: none;
          padding-left: 0;
        }
      }
    }

    tr:last-of-type() {
      td, th {
        border-bottom: 0;
        padding-top: 24px;
      }

      th {
        @include larger-than(mobile) {
          border-right: 0;
        }
      }

      td {
        padding-left: 0;
      }

      td:last-child {
        &::after {
          display: none;
        }
      }
    }

    td {
      a {
        max-width: 132px;
        min-width: auto;
        width: 100%;
      }
    }
  }

  tbody {
    th {
      min-width: 165px;
    }
    td {
      min-width: 132px;
      padding: 16px 20px;
      @include larger-than(mobile) {
        min-width: inherit;
      }
    }
  }
}

.bh_plan-hidden {
  display: none;
}

.bh_plan-see-all {
  &.bh_plan-visible {
    &::after {
      transform: translateY(-50%) rotate(270deg);
      top: 60%;
    }
  }
}