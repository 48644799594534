.bh_title-two-columns {
  display:flex;
  flex-direction: column;
  margin-bottom:-40px;

  &-body {
    display:flex;
    flex-direction: column;
    padding-top:20px;

    &-left {
      display:flex;
      flex-direction: column-reverse;
      img {
        max-width:200px;
        align-self: center;
      }
    }

    p {
      margin-bottom: 0;
    }
  }
  @include larger-than(smallTablet){
    &-body {
      flex-direction: row;
      &-left {
        flex-direction: column;
        img {
          max-width:280px;
          align-self: auto;
          width: 100%;
        }
      }
    }
  }
}


.bh_title-two-columns-list {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  list-style: none;
  margin-top:0;

  li {
    display:flex;
    p {
      margin-left:15px;
      color:$white;
      line-height:26px;
      margin-bottom:20px;
    }
  }

  &-icon{
    height: 26px;
    width: 26px;
  }
}

.bh_title-two-columns-headline {
  text-align: center;

  p {
    @include larger-than(smallTablet){
      max-width: $column-lg;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
