.bh_footer {
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    background-color:$spaceBlack;
    padding-top:0px;
    transition: all .5 ease;
		overflow:hidden;
		position: relative;
    h2 {
        display:flex;
        justify-content: center;
        color:$cloudWhite;
        margin-bottom:0px;
        padding-top:25px;
        font-size: $text-headline;
    }
    &::before {
        display:none;
    }

    @include larger-than(smallestMobile){
        h2 {
            font-size: $text-title-2;
        }
    }

    @include larger-than(mobile){
        h2 {
            font-size: $text-large-title;
            line-height: 44px;
            padding:50px 0;
        }

      &::after {
        content: '';
        position: absolute;
        width: 494px;
        height: 494px;
        border: 16px solid $inspirationBlue;
        border-radius: 50%;
        bottom: -50%;
        right: -100px;
        z-index:0;
      }
    }
}


  
.bh_footer-container {
    display:flex;
    flex-direction: column;
    margin-top: 20px;
    .bh_type-body {
        color:$cloudWhite;
    }

    .bh_footer-link{
      @extend %bh_type-body;
        color:$white;
        margin-bottom:20px;
        
        &:hover{
            color:$inspirationBlue;
            cursor: pointer;
            text-decoration: none;
        }
    }

    @include larger-than(mobile){
        flex-direction: row;
        padding-bottom:50px;
        margin:0px;
        .bh_footer-link{
            margin: 3px 0;
            font-size: $text-footnote;
        }
    }
}

.bh_footer-item{
    border-bottom: 1px solid $rocketGray;
    &-container {
        display:flex;
        flex-direction: column;
        max-height: 0;
        overflow: hidden;
        transition: max-height 400ms, visibility 400ms;
        visibility: hidden;

        &.--mobile-open{
          max-height: 900px;
          visibility: visible;
        }
    }

    &:last-child {
        border-bottom: none;
    }

    .bh_type-body {
        font-weight:$font-weight-bold;
    }

    .bh_footer-social-icons {
        display:none;
    }

    &-title {
        display:flex;
        justify-content: space-between;
        align-items: center;
        height:65px;
    }

    .bh_footer-mobile-dropdown {
        transition: .5s all;
        &.--rotate-image {
            transform: rotate(180deg);
        }
    }

    @include larger-than(mobile){
        border:none;
        margin-right:auto;
        z-index:1;
        &-container {
          max-height: inherit;
          overflow: hidden;
          transition: none;
          visibility: visible;
        }

        &-title {
            height: initial;
        }

        .bh_type-body {
            font-weight:$font-weight-medium;
        }

        .bh_footer-mobile-dropdown {
            display:none;
        }
    }
}

.bh_footer-details {
    display:none;
}

.bh_footer-seals{
    filter: grayscale(1);
    margin-right:25px;
}

.bh_footer-social-icons {
    a {
        color:$inspirationBlue;
    }

    svg {
        margin: 0 8px;
    }
}

@include larger-than(mobile) {
    .bh_footer-details-mobile {
        display:none;
    }

    .bh_footer-details {
        display:initial;
        padding: 40px 0;
    }

    .bh_footer-social-icons {
        display: flex;
        z-index:1 !important;
        
        svg {
            margin: 0 14px 0 0;
            height:16px;
            width:16px;
        }
        a {
            color:$inspirationBlue;
        }
        a:hover {
            color:white;
        }
    }
}

.bh_footer-details-group {
    display:flex;
    position:relative;
    justify-content: space-between;
}


.bh_footer-details-left {
    display:flex;
    z-index:1;
    .bh_type-body {
        color:$rocketGray;
        margin-right:10px;
        margin-bottom:5px;
    }

    .bh_type-link {
        display:initial;
        color:$cloudWhite;
        text-decoration: underline;
        
        &:hover{
            color:$inspirationBlue;
            cursor: pointer;
        }
    }
}

.bh_footer-details-right {
    display:flex;
    align-items: center;
    
    img {
        margin: 0 0 10px 10px;
    }

    .bh_type-body{
        color:$cloudWhite;
        margin-left:10px;
    }
    
}

.bh_footer-details-mobile {
    &-top {
        display:flex;
        align-items: center;
        justify-content: space-between;
        border-top:1px solid $rocketGray;
        height:75px;
        p {
            color:$cloudWhite;
            margin:0 0 0 10px;
        }
    }

    &-bottom {
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height:150px;
        border-top:1px solid $rocketGray;
        p {
            margin-top:10px;
            color:$rocketGray
        }
    }

}

.bh_footer-location-dropdown-container {
    position:relative;
    margin-left:10px;
    margin-bottom:30px;

    @include larger-than(mobile){
        margin:0;
        width:130px;
    }
}

.bh_footer-location-dropdown {
    overflow-y: hidden;
    width:120px;
    height:30px;
    position:absolute;
    padding-top:5px;
    transition: height .25s ease-in, width .1s ease-in .5s, background-color .35s ease-in;
    span {
        display:flex;
        align-content: center;
        margin-left:10px;
    }

    span .bh_type-body {
        margin-bottom:0px;
        margin-right:20px;
        &:hover{
            color:$inspirationBlue;
            cursor:pointer;
            text-decoration: none;
        }
    }

    .bh_footer-location-dropdown-selection ~ span{
        margin-left:40px;
    }

    .bh_footer-dropdown-globe {
        margin-right:10px;
    }

    &.--dropdown-open {
        background-color:$cloudWhite;
        height: 130px;
        width:170px;
        overflow-y: auto;
        transition: height .25s ease-out;
        .bh_type-body {
            color: $spaceBlack;
        }
    
        .bh_footer-dropdown-icon {
            display:none;
        }
    
        .bh_footer-dropdown-globe {
            content: url('../../images/icons/globe.svg');
        }
    }

    @include larger-than(mobile){
        bottom:1px;
        padding:0px;
        &::-webkit-scrollbar{
            width: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $lightGray;
            border-radius: 10px;  
            border: 3px solid $lightGray; 
          }
        &:hover{
            color:$inspirationBlue;
            cursor:pointer;
        } 
          
    }
}


.bh_footer-header {
  @extend %bh_type-headline;

  @include larger-than(mobile){
    font-size: $text-body;
  }
}

