.bh_feature-nav {
  height: 70px;
  overflow: hidden;
  border-bottom: 1px solid $lightGray;
  max-width: 1029px;
  margin: 0 auto;

  @include larger-than(smallTablet) {
    margin-top: 35px;
  }
}

.bh_feature-nav__inner {
  position: relative;
  width: 100%;
  overflow-x: auto;
}

.bh_feature-nav__scroll {
  display: flex;
  justify-content: space-between;
  width: 1029px;
}

.bh_feature-nav__link {
  width: 78px;
  height: 70px;
  align-items: center;
  display: flex;
  line-height: 20px;
  &:last-child {
    width:88px;
  }

  &:hover {
    text-decoration: none;
    color: $inspirationBlue;
    font-weight: $font-weight-bold;
  }

  &--active {
    font-weight: $font-weight-bold;
    color: $inspirationBlue;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 4px;
      width: 100%;
      background-color: $inspirationBlue;
    }
  }
}