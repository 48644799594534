.bh_right-align-copy-image {
  padding-top:10px;
  margin-top:200px;
  img {
    max-width: 280px;
    width: 100%;
  }

  &-content {
    display:flex;
    flex-direction: column;
    justify-content: flex-end;

    h2 {
      margin-bottom:20px;
    }
  }

  .bh_image-left {
    margin-top:-230px;
  }

  @include larger-than(smallTablet){
    margin-top:0px;
    .bh_image-left {
      margin-top:-60px;
    }

    img {
      padding-top:20px;
      max-width: 475px;
      width: initial;
      margin: 0 40px;
    }

    &-content {
      > *:first-child {
        font-size:$text-title-2;
        line-height: 36px;
        margin: 50px 0 20px;
      }
      .bh_type-body{
        padding-bottom:20px;
      }
    }
  }
}