.bh_type-feature, .bh-home-wrap h1,
.bh_pricing-hero > div > h1,
.bh_pricing-plans-grid__item-price > span:nth-child(2),
.bh_landing-hero-top h1 {
  font-size: 56px;
  line-height: 60px;
  font-family: freight-sans-pro, sans-serif;
  font-weight: 700;
  color: inherit; }

.bh_type-highlight,
.bh_pricing-hero > div > p,
.bh_landing-hero-top p {
  line-height: 36px;
  font-family: freight-sans-pro, sans-serif;
  font-size: 22px;
  font-weight: 500;
  color: inherit; }

.bh_type-large-title,
.bh_hp-intro__inner h1,
.bh_landing-signup-form h1 {
  line-height: 44px;
  font-family: freight-sans-pro, sans-serif;
  font-size: 36px;
  font-weight: 700;
  color: inherit;
  margin-bottom: 22px; }
  @media (max-width: 53.125rem) {
    .bh_type-large-title,
    .bh_hp-intro__inner h1,
    .bh_landing-signup-form h1 {
      font-size: 22px;
      line-height: 28px; }
      .bh_type-large-title br,
      .bh_hp-intro__inner h1 br,
      .bh_landing-signup-form h1 br {
        display: none; } }

.bh_type-title-1,
.bh_hp-features__inner h1,
.bh_hp-support__inner h1,
.bh_hp-websites__inner h1,
.bh_plan-features h1,
.bh_landing-enter-form h1,
.bh_landing-features h1,
.bh_image-text-slider__inner h1 {
  line-height: 40px;
  font-family: freight-sans-pro, sans-serif;
  font-size: 30px;
  font-weight: 700;
  color: inherit;
  margin-bottom: 22px; }

.bh_type-title-2,
.bh_hp-cta__inner h1,
.bh_hp-intro__column h2,
.bh_hp-features__column h2,
.bh_hp-features__column--right h3,
.bh_hp-integrations__inner h1,
.bh_hp-support__column h2,
.bh_pricing-cta__inner h1,
.bh_landing-enter-form ul li h2 {
  line-height: 28px;
  font-family: freight-sans-pro, sans-serif;
  font-size: 22px;
  font-weight: 700;
  color: inherit;
  margin-bottom: 22px; }

.bh_type-title-3 {
  line-height: 28px;
  font-family: freight-sans-pro, sans-serif;
  font-size: 22px;
  font-weight: 500;
  color: inherit;
  margin-bottom: 22px; }

.bh_type-headline,
.bh_footer-header,
.bh_hp-websites__website h2,
.bh_pricing-plans-grid__item-price > div > span:nth-child(1),
.bh_landing-features li h2 {
  line-height: 28px;
  font-family: freight-sans-pro, sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: inherit; }

.bh_type-body, .bh_faq-question, .bh_faq-answer,
.bh_footer-container .bh_footer-link,
.bh_hp-intro__inner p,
.bh_hp-features__inner p,
.bh_hp-features__column--right p,
.bh_hp-integrations__inner p,
.bh_hp-support__inner p,
.bh_landing-enter-form ul li p,
.bh_landing-features > p {
  line-height: 28px;
  font-family: freight-sans-pro, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: inherit; }

.bh_type-callout, .bh-home-wrap p,
.bh_hp-intro__column ul li,
.bh_hp-support__column ul li,
.bh_hp-websites__website p,
.bh_pricing-plans-grid__item-header p,
.bh_pricing-plans-grid__item-body li,
.bh_pricing-plans-grid__item-callout,
.bh_plan-features li,
.bh_pricing-cta__content p,
.bh_landing-features li p,
.bh_plan-slider__table [data-tooltip]::before {
  line-height: 26px;
  font-family: freight-sans-pro, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: inherit; }

.bh_type-subhead,
.bh_hp-features__grid li > div,
.bh_pricing-plans-grid__item-header h2,
.bh_landing-enter-form__step span {
  line-height: 28px;
  font-family: freight-sans-pro, sans-serif;
  font-size: 15px;
  font-weight: 700;
  color: inherit; }

.bh_type-footnote,
.bh_pricing-plans-grid__item-price > span:nth-child(1),
.bh_pricing-plans-grid__item-price > div > span:nth-child(2),
.bh_pricing-plans-grid__item-body span {
  line-height: 18px;
  font-family: freight-sans-pro, sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: inherit; }

.bh_type-caption {
  line-height: 15px;
  font-family: freight-sans-pro, sans-serif;
  font-size: 11px;
  font-weight: 500;
  color: #000000; }

.bh_strong {
  font-weight: 700;
  color: #000000; }

.bh_a {
  color: inherit;
  text-decoration: underline;
  font-family: freight-sans-pro, sans-serif; }
  .bh_a:hover {
    color: #136ef6; }

.bh_a-blue {
  color: inherit;
  text-decoration: underline;
  font-family: freight-sans-pro, sans-serif; }
  .bh_a-blue:hover {
    color: #000000; }

.bh_margin {
  margin-bottom: 22px; }

.bh_margin-double {
  margin-bottom: 44px; }

.bh_center {
  text-align: center; }

.bh_left {
  text-align: left; }

.bh_right {
  text-align: right; }

.bh_mobile-text-center {
  text-align: center; }
  @media (min-width: 47.9375rem) {
    .bh_mobile-text-center {
      text-align: inherit; } }

.bh_type-subhead-mobile-body {
  line-height: 28px;
  font-family: freight-sans-pro, sans-serif;
  font-size: 15px;
  font-weight: 700;
  color: inherit; }
  @media (max-width: 53.125rem) {
    .bh_type-subhead-mobile-body {
      line-height: 28px;
      font-family: freight-sans-pro, sans-serif;
      font-size: 16px;
      font-weight: 500;
      color: inherit; } }

.bh_mobile-text-left {
  text-align: inherit; }
  @media (max-width: 53.125rem) {
    .bh_mobile-text-left {
      text-align: left; } }

html.bh_html {
  box-sizing: border-box; }
  @media (min-width: 47.9375rem) {
    html.bh_html {
      overflow: inherit; } }
  html.bh_html *, html.bh_html *:before, html.bh_html *:after {
    box-sizing: inherit; }
  html.bh_html * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
    html.bh_html *:focus-visible {
      outline: 1px solid #136ef6; }
    html.bh_html *:focus:not(:focus-visible) {
      outline: none; }
  html.bh_html main {
    padding-top: 60px; }
    @media (min-width: 64rem) {
      html.bh_html main {
        padding-top: 92px; } }
  html.bh_html #s4s-home main {
    padding-top: 0; }

.bh-text-center {
  text-align: center; }

.bh-m-0 {
  margin-bottom: 0px; }

.bh-m-1 {
  margin-bottom: 10px; }

.bh-m-2 {
  margin-bottom: 20px; }

.bh-m-3 {
  margin-bottom: 30px; }

.bh-m-45 {
  margin-bottom: 45px; }

.bh_grid {
  display: flex;
  margin-right: -16px; }
  .bh_grid > * {
    width: calc((100% / 8) - 16px);
    margin-right: 16px; }

.bh_spacing-large.--top {
  padding-top: 40px; }
  @media (min-width: 47.9375rem) {
    .bh_spacing-large.--top {
      padding-top: 80px; } }

.bh_spacing-large.--bottom {
  padding-bottom: 40px; }
  @media (min-width: 47.9375rem) {
    .bh_spacing-large.--bottom {
      padding-bottom: 80px; } }

.bh_spacing-standard.--top {
  padding-top: 30px; }
  @media (min-width: 47.9375rem) {
    .bh_spacing-standard.--top {
      padding-top: 50px; } }

.bh_spacing-standard.--bottom {
  padding-bottom: 30px; }
  @media (min-width: 47.9375rem) {
    .bh_spacing-standard.--bottom {
      padding-bottom: 50px; } }

.bh_spacing-primary.--top {
  padding-top: 22px; }
  @media (min-width: 47.9375rem) {
    .bh_spacing-primary.--top {
      padding-top: 30px; } }

.bh_spacing-primary.--bottom {
  padding-bottom: 22px; }
  @media (min-width: 47.9375rem) {
    .bh_spacing-primary.--bottom {
      padding-bottom: 30px; } }

.bh_spacing-secondary.--top {
  padding-top: 16px; }
  @media (min-width: 47.9375rem) {
    .bh_spacing-secondary.--top {
      padding-top: 22px; } }

.bh_spacing-secondary.--bottom {
  padding-bottom: 16px; }
  @media (min-width: 47.9375rem) {
    .bh_spacing-secondary.--bottom {
      padding-bottom: 22px; } }

.bh_spacing-small.--top {
  padding-top: 10px; }
  @media (min-width: 47.9375rem) {
    .bh_spacing-small.--top {
      padding-top: 16px; } }

.bh_spacing-small.--bottom {
  padding-bottom: 10px; }
  @media (min-width: 47.9375rem) {
    .bh_spacing-small.--bottom {
      padding-bottom: 16px; } }

.bh_input-white, .bh_input-black {
  width: 197px;
  height: 50px;
  padding-left: 20px;
  border-radius: 2px;
  outline: none;
  background: transparent; }
  .bh_input--error .bh_input-white, .bh_input--error .bh_input-black {
    border: 1px solid #b41b1b; }

.bh_input--success .bh_input-message {
  color: #357e29; }

.bh_input--error .bh_input-message {
  color: #b41b1b; }

.bh_input-white {
  color: #090304;
  border: 1px solid #c1c1c1; }
  .bh_input-white::-webkit-input-placeholder {
    color: #c1c1c1; }
  .bh_input-white::-moz-placeholder {
    color: #c1c1c1; }
  .bh_input-white:-ms-input-placeholder {
    color: #c1c1c1; }
  .bh_input-white::-ms-input-placeholder {
    color: #c1c1c1; }
  .bh_input-white::placeholder {
    color: #c1c1c1; }

.bh_input-black {
  color: #ffffff;
  border: 1px solid #000000; }
  .bh_input-black::-webkit-input-placeholder {
    color: #ffffff; }
  .bh_input-black::-moz-placeholder {
    color: #ffffff; }
  .bh_input-black:-ms-input-placeholder {
    color: #ffffff; }
  .bh_input-black::-ms-input-placeholder {
    color: #ffffff; }
  .bh_input-black::placeholder {
    color: #ffffff; }

.bh_ul {
  margin: 0;
  padding: 0;
  list-style: none; }

.bh_color-blue {
  color: #136ef6; }

.bh_color-black {
  color: #000000; }

.bh_color-white {
  color: #ffffff; }

.bh_color-black {
  color: #000000; }

.bh_c-space-black {
  color: #090304; }

.bh_color-rocketGray {
  color: #88969e; }

.bh_background-off-white,
.bh_bg-off-white {
  background: #f9f9f9; }

.bh_background-black {
  background: #000000; }

.bh_background-blue {
  background: #136ef6; }

.bh_container1120 {
  max-width: calc(1120px + 16px * 2);
  width: 100%;
  margin: 0 auto;
  padding: 0 16px; }

@media (min-width: 64rem) {
  .bh_shift-left {
    padding-left: 142px; } }

@media (min-width: 64rem) {
  .bh_shift-right {
    padding-right: 142px; } }

@media (min-width: 64rem) {
  .bh_shift-left-small {
    padding-left: 72px; } }

@media (min-width: 64rem) {
  .bh_shift-right-small {
    padding-right: 72px; } }

@media (min-width: 53.125rem) {
  .bh_grid-wide-left {
    display: flex; } }

.bh_grid-wide-left > div:first-of-type {
  margin-bottom: 22px; }
  @media (min-width: 53.125rem) {
    .bh_grid-wide-left > div:first-of-type {
      flex-grow: 1;
      max-width: 552px;
      margin-bottom: 0; } }

@media (min-width: 53.125rem) {
  .bh_grid-wide-left > div:last-of-type {
    padding-left: 16px;
    flex-grow: 1; } }

@media (min-width: 53.125rem) {
  .bh_grid-narrow-left {
    display: flex; } }

.bh_grid-narrow-left > div:first-of-type {
  margin-bottom: 22px; }
  @media (min-width: 53.125rem) {
    .bh_grid-narrow-left > div:first-of-type {
      flex-grow: 1;
      max-width: 410px;
      margin-bottom: 0; } }

@media (min-width: 53.125rem) {
  .bh_grid-narrow-left > div:last-of-type {
    padding-left: 16px;
    flex-grow: 1; } }

@media (min-width: 53.125rem) {
  .bh_columns {
    display: flex;
    flex-wrap: wrap;
    margin-right: -16px; } }

@media (min-width: 53.125rem) {
  .bh_columns--2 > * {
    width: calc(50% - 16px);
    margin-right: 16px; } }

@media (min-width: 53.125rem) {
  .bh_columns--2-3 {
    flex-wrap: nowrap;
    margin-right: -16px * 2; } }

@media (min-width: 53.125rem) {
  .bh_columns--2-3 > *:first-of-type {
    width: 30%;
    flex-shrink: 0;
    margin-right: 32px; } }

@media (min-width: 53.125rem) {
  .bh_columns--3 > * {
    width: calc(33.33% - 16px);
    margin-right: 16px; } }

@media (min-width: 53.125rem) {
  .bh_grid-two-column-split {
    display: flex;
    flex-wrap: wrap;
    margin-right: -16px; }
    .bh_grid-two-column-split > * {
      width: calc(50% - 16px);
      margin-right: 16px; } }

.bh_image-left {
  text-align: center; }
  @media (min-width: 53.125rem) {
    .bh_image-left {
      text-align: left; } }

.bh_image-right {
  text-align: center; }
  @media (min-width: 53.125rem) {
    .bh_image-right {
      text-align: right; } }

.bh_desktop-only {
  display: none; }
  @media (min-width: 53.125rem) {
    .bh_desktop-only {
      display: block; } }

.bh__max-width-large {
  max-width: 552px;
  width: 100%; }

.bh__max-width-large-center {
  max-width: 552px;
  width: 100%;
  margin-left: auto;
  margin-right: auto; }

.bh__max-width-medium-center {
  max-width: 836px;
  width: 100%;
  margin-left: auto;
  margin-right: auto; }

.sr-only {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap; }

.bh_video-container {
  height: 300px;
  width: 100%;
  max-height: 400px;
  max-width: 650px; }
  @media (min-width: 53.125rem) {
    .bh_video-container {
      height: 100%; } }

.bh_image-mobile-padding {
  padding: inherit; }
  @media (max-width: 53.125rem) {
    .bh_image-mobile-padding {
      padding: 0 20px 0 20px; } }

.bh_image-mobile-padding-after {
  padding: inherit; }
  @media (max-width: 53.125rem) {
    .bh_image-mobile-padding-after {
      padding: 0 0 30px 0; } }

.bh_image-mobile-max-height {
  max-height: inherit; }
  @media (max-width: 53.125rem) {
    .bh_image-mobile-max-height {
      max-height: 200px; } }

@media (max-width: 53.125rem) {
  .bh_img-mobile-max-75 {
    width: 75%; } }

@media (max-width: 53.125rem) {
  .bh_img-mobile-max-50 {
    width: 50%; } }

.bh_break-word {
  word-break: break-all; }

.bh_button-primary-white, .bh_button-primary-blue-white, .bh_button-primary-black, .bh_button-primary-black-black, .bh_button-primary-blue,
.bh_button-primary-blue2, .bh_button-secondary-blue, .bh_button-secondary-white, .bh_button-secondary-blue-blue, .bh_button-secondary-black {
  display: inline-flex;
  justify-content: center;
  align-items: center; }
  .bh_button-primary-white:hover, .bh_button-primary-blue-white:hover, .bh_button-primary-black:hover, .bh_button-primary-black-black:hover, .bh_button-primary-blue:hover,
  .bh_button-primary-blue2:hover, .bh_button-secondary-blue:hover, .bh_button-secondary-white:hover, .bh_button-secondary-blue-blue:hover, .bh_button-secondary-black:hover {
    text-decoration: none; }

.bh_button-primary-white {
  margin: 0;
  padding: 0;
  border: 0;
  appearance: none;
  background: transparent;
  height: 45px;
  min-width: 145px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  font-weight: 500;
  color: #136ef6;
  padding: 0 16px; }
  .bh_button-primary-white:hover {
    background-color: transparent;
    color: #ffffff;
    border: 1px solid #ffffff; }

.bh_button-primary-blue-white {
  margin: 0;
  padding: 0;
  border: 0;
  appearance: none;
  background: transparent;
  height: 45px;
  min-width: 145px;
  background-color: #136ef6;
  border: 1px solid #136ef6;
  font-weight: 500;
  color: #ffffff;
  padding: 0 16px; }
  .bh_button-primary-blue-white:hover {
    background-color: #ffffff;
    color: #000000;
    border: 1px solid #136ef6; }

.bh_button-primary-black {
  margin: 0;
  padding: 0;
  border: 0;
  appearance: none;
  background: transparent;
  height: 45px;
  min-width: 145px;
  background-color: #000000;
  border: 1px solid #000000;
  font-weight: 500;
  color: #ffffff;
  padding: 0 16px; }
  .bh_button-primary-black:hover {
    background-color: transparent;
    color: #136ef6;
    border: 1px solid #136ef6; }

.bh_button-primary-black-black {
  margin: 0;
  padding: 0;
  border: 0;
  appearance: none;
  background: transparent;
  height: 45px;
  min-width: 145px;
  background-color: #000000;
  border: 1px solid #000000;
  font-weight: 500;
  color: #ffffff;
  padding: 0 16px; }
  .bh_button-primary-black-black:hover {
    background-color: transparent;
    color: #ffffff;
    border: 1px solid #000000; }

.bh_button-primary-blue,
.bh_button-primary-blue2 {
  margin: 0;
  padding: 0;
  border: 0;
  appearance: none;
  background: transparent;
  height: 45px;
  min-width: 145px;
  background-color: #136ef6;
  border: 1px solid #136ef6;
  font-weight: 500;
  color: #ffffff;
  padding: 0 16px; }
  .bh_button-primary-blue:hover,
  .bh_button-primary-blue2:hover {
    background-color: transparent;
    color: #ffffff;
    border: 1px solid #136ef6; }

.bh_button-primary-blue2:hover {
  color: #136ef6; }

.bh_button-secondary-blue {
  margin: 0;
  padding: 0;
  border: 0;
  appearance: none;
  background: transparent;
  height: 45px;
  min-width: 145px;
  background-color: transparent;
  border: 1px solid #136ef6;
  font-weight: 500;
  color: #136ef6;
  padding: 0 16px; }
  .bh_button-secondary-blue:hover {
    background-color: #136ef6;
    color: #ffffff;
    border: 1px solid #136ef6; }

.bh_button-secondary-white {
  margin: 0;
  padding: 0;
  border: 0;
  appearance: none;
  background: transparent;
  height: 45px;
  min-width: 145px;
  background-color: transparent;
  border: 1px solid #ffffff;
  font-weight: 500;
  color: #ffffff;
  padding: 0 16px; }
  .bh_button-secondary-white:hover {
    background-color: #ffffff;
    color: #136ef6;
    border: 1px solid #136ef6; }

.bh_button-secondary-blue-blue {
  margin: 0;
  padding: 0;
  border: 0;
  appearance: none;
  background: transparent;
  height: 45px;
  min-width: 145px;
  background-color: transparent;
  border: 1px solid #136ef6;
  font-weight: 500;
  color: #ffffff;
  padding: 0 16px; }
  .bh_button-secondary-blue-blue:hover {
    background-color: #136ef6;
    color: #ffffff;
    border: 1px solid #136ef6; }

.bh_button-secondary-black {
  margin: 0;
  padding: 0;
  border: 0;
  appearance: none;
  background: transparent;
  height: 45px;
  min-width: 145px;
  background-color: transparent;
  border: 1px solid #000000;
  font-weight: 500;
  color: #ffffff;
  padding: 0 16px; }
  .bh_button-secondary-black:hover {
    background-color: #000000;
    color: #ffffff;
    border: 1px solid #000000; }

.bh_button-circle {
  margin: 0;
  padding: 0;
  border: 0;
  appearance: none;
  background: transparent;
  height: 72px;
  min-width: 72px;
  background-color: #136ef6;
  border: 1px solid #136ef6;
  font-weight: 500;
  color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center; }
  .bh_button-circle:hover {
    background-color: transparent;
    color: #136ef6;
    border: 1px solid #136ef6; }
  .bh_button-circle:hover {
    text-decoration: none; }

.bh_button-arrow-black {
  margin: 0;
  padding: 0;
  border: 0;
  appearance: none;
  background: transparent;
  font-weight: 700;
  text-decoration: underline;
  color: #000000;
  position: relative;
  padding-right: 32px; }
  .bh_button-arrow-black::after {
    content: '';
    position: absolute;
    height: 17px;
    width: 21px;
    background-image: url("../../images/icons/arrow-sm.svg");
    right: 0;
    top: 50%;
    transform: translateY(-50%); }
  .bh_button-arrow-black:hover {
    color: #136ef6; }
    .bh_button-arrow-black:hover::after {
      background-image: url("../../images/icons/arrow-sm-blue.svg"); }

.bh_button-arrow-white-blue {
  margin: 0;
  padding: 0;
  border: 0;
  appearance: none;
  background: transparent;
  font-weight: 700;
  text-decoration: underline;
  color: #ffffff;
  position: relative;
  padding-right: 32px; }
  .bh_button-arrow-white-blue::after {
    content: '';
    position: absolute;
    height: 17px;
    width: 21px;
    background-image: url("../../images/icons/arrow-sm-white.svg");
    right: 0;
    top: 50%;
    transform: translateY(-50%); }
  .bh_button-arrow-white-blue:hover {
    color: #136ef6; }
    .bh_button-arrow-white-blue:hover::after {
      background-image: url("../../images/icons/arrow-sm-blue.svg"); }

.bh_button-arrow-white-black {
  margin: 0;
  padding: 0;
  border: 0;
  appearance: none;
  background: transparent;
  font-weight: 700;
  text-decoration: underline;
  color: #ffffff;
  position: relative;
  padding-right: 32px; }
  .bh_button-arrow-white-black::after {
    content: '';
    position: absolute;
    height: 17px;
    width: 21px;
    background-image: url("../../images/icons/arrow-sm-white.svg");
    right: 0;
    top: 50%;
    transform: translateY(-50%); }
  .bh_button-arrow-white-black:hover {
    color: #000000; }
    .bh_button-arrow-white-black:hover::after {
      background-image: url("../../images/icons/arrow-sm-black.svg"); }

.bh_button-chevron-black {
  margin: 0;
  padding: 0;
  border: 0;
  appearance: none;
  background: transparent;
  font-weight: 700;
  text-decoration: underline;
  color: #000000;
  position: relative;
  padding-right: 32px; }
  .bh_button-chevron-black::after {
    content: '';
    position: absolute;
    height: 17px;
    width: 21px;
    background-image: url("../../images/icons/chevron.svg");
    right: 0;
    top: 50%;
    transform: translateY(-50%); }
  .bh_button-chevron-black:hover {
    color: #136ef6; }
    .bh_button-chevron-black:hover::after {
      background-image: url("../../images/icons/chevron-blue.svg"); }
  .bh_button-chevron-black:after {
    height: 20px;
    width: 16px;
    transform: translateY(-50%) rotate(90deg);
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: center;
    top: 37%; }

.bh_index {
  display: flex;
  flex-direction: column;
  margin: 50px 0 100px; }

.bh_styleguide-logo--normal {
  max-width: 387px; }

.bh_styleguide-logo--small {
  max-width: 50px; }

.bh_styleguide-headline {
  margin-bottom: 43px; }

.container1170--margin {
  margin-bottom: 100px; }

.container1170--header {
  margin-bottom: 100px;
  margin-top: 100px; }

.bh_styleguide-grid {
  display: grid;
  grid-gap: 30px; }

.bh_styleguide-grid--typography {
  grid-gap: 20px; }

.bh_styleguide__buttons > div + div {
  margin-top: 30px; }

.bh_styleguide__buttons-wrap {
  display: flex;
  flex-wrap: wrap; }
  .bh_styleguide__buttons-wrap > * {
    padding: 40px; }

.bh_styleguide-grid--input {
  --gg: 10px; }

.bh_styleguide--icons {
  display: flex;
  align-items: center; }
  .bh_styleguide--icons img {
    margin-right: 20px; }

.bh_styleguide-colors {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  .bh_styleguide-colors-container {
    flex-direction: column;
    margin-right: 20px;
    margin-bottom: 30px; }

.bh_styleguide-colors-circle {
  height: 170px;
  width: 170px;
  border-radius: 50%;
  margin-bottom: 10px; }

.bh_styleguide-desktop-grid {
  height: 50px;
  position: relative; }
  .bh_styleguide-desktop-grid::after {
    content: '';
    position: absolute;
    background: #BDBDBD;
    width: 100vw;
    height: 100%;
    z-index: -2;
    margin: 0 calc(50% - 50vw);
    left: -8px; }
  .bh_styleguide-desktop-grid::before {
    content: '';
    background: #ffffff;
    width: calc(100% - 16px);
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1; }
  .bh_styleguide-desktop-grid > * {
    background: #EAEAEA; }

.bh_styleguide-desktop-grid-img {
  max-width: 245px;
  margin-top: 50px; }

.bh_spacing-grid {
  display: grid;
  grid-gap: 20px; }
  @media (min-width: 47.9375rem) {
    .bh_spacing-grid {
      grid-gap: 100px;
      grid-template-columns: 1fr 1fr; } }

.bh_spacing-column {
  display: grid;
  grid-gap: 20px; }

.bh_spacing-grid-inner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px; }

.bh_spacing-grid-padding {
  background: #E2E2E2;
  padding-left: 10px; }
  .bh_spacing-grid-padding.--large {
    height: 80px; }
  .bh_spacing-grid-padding.--standard {
    height: 50px; }
  .bh_spacing-grid-padding.--primary {
    height: 30px; }
  .bh_spacing-grid-padding.--secondary {
    height: 22px; }
  .bh_spacing-grid-padding.--small {
    height: 16px; }
  .bh_spacing-column:nth-child(2) .bh_spacing-grid-padding.--large {
    height: 40px; }
  .bh_spacing-column:nth-child(2) .bh_spacing-grid-padding.--standard {
    height: 30px; }
  .bh_spacing-column:nth-child(2) .bh_spacing-grid-padding.--primary {
    height: 22px; }
  .bh_spacing-column:nth-child(2) .bh_spacing-grid-padding.--secondary {
    height: 16px; }
  .bh_spacing-column:nth-child(2) .bh_spacing-grid-padding.--small {
    height: 10px; }

.bh_styleguide-color-spaceBlack {
  background-color: #090304; }

.bh_styleguide-color-inspirationBlue {
  background-color: #136ef6; }

.bh_styleguide-color-cloudWhite {
  background-color: #ffffff;
  border: 1px solid #c1c1c1; }

.bh_styleguide-color-lightGray {
  background-color: #c1c1c1; }

.bh_styleguide-color-rocketGray {
  background-color: #88969e; }

.bh_styleguide-color-offWhite {
  background-color: #f9f9f9;
  border: 1px solid #c1c1c1; }

.bh_styleguide-color-errorRed {
  background-color: #b41b1b;
  height: 24px;
  width: 139px;
  border-radius: 12px; }

.bh_styleguide-color-goGreen {
  background-color: #357e29;
  height: 24px;
  width: 139px;
  border-radius: 12px; }

.bh_styleguide-illustration-icon-grid {
  display: flex;
  flex-wrap: wrap; }
  .bh_styleguide-illustration-icon-grid > * {
    width: 120px;
    padding: 0 20px 20px 0; }

.bh_styleguide-no-line {
  display: inline;
  line-height: 28px; }

.bh_styleguide-email {
  display: flex;
  flex-wrap: wrap; }
  .bh_styleguide-email-container {
    display: flex;
    flex-direction: column;
    margin-right: 50px; }

.bh_nav {
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 2;
  font-family: freight-sans-pro, sans-serif;
  box-shadow: 0px 2px 0px #eaeaea;
  background: #ffffff; }
  .bh_nav.bh-nav--scrolled {
    position: fixed; }
  .bh_nav.--mobile-open::after {
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    top: 0;
    left: 0; }
    @media (min-width: 64rem) {
      .bh_nav.--mobile-open::after {
        display: none; } }

.bh_nav-inner {
  max-width: 1280px;
  margin: 0 auto; }
  @media (min-width: 64rem) {
    .bh_nav-inner {
      display: flex; } }

.bh_nav__logo {
  max-width: 182px; }

.bh_nav__logo--invert {
  display: none; }

.bh_nav__left {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 18px; }
  @media (min-width: 64rem) {
    .bh_nav__left {
      height: 92px;
      padding: 0 0 0 30px; } }

.bh_nav__toggle {
  margin: 0;
  padding: 0;
  border: 0;
  appearance: none;
  background: transparent;
  display: flex; }
  @media (min-width: 64rem) {
    .bh_nav__toggle {
      display: none; } }

.bh_nav__right {
  position: fixed;
  z-index: 2;
  background: #ffffff;
  width: 100%;
  height: 0%;
  top: 0;
  padding: 0 18px;
  transform: translateX(100%);
  opacity: 0;
  visibility: hidden;
  transition: transform 300ms, opacity 100ms 1s, visibility 100ms 1s, height 100ms 1s; }
  .--mobile-open .bh_nav__right {
    height: 100%;
    transform: translateX(0%);
    opacity: 1;
    visibility: visible;
    transition: transform 300ms, opacity 100ms, visibility 100ms; }
    @media (min-width: 64rem) {
      .--mobile-open .bh_nav__right {
        transform: none;
        opacity: 1;
        visibility: visible; } }
  @media (min-width: 64rem) {
    .bh_nav__right {
      position: static;
      height: auto;
      padding: 0 50px 0 18px;
      transform: none;
      opacity: 1;
      visibility: visible;
      transition: none; } }

.bh_nav__right-header {
  display: flex;
  justify-content: space-between;
  height: 60px;
  align-items: center; }
  @media (min-width: 64rem) {
    .bh_nav__right-header {
      display: none; } }

.bh_nav__scroll {
  overflow: auto;
  height: calc(100% - 60px);
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 0 20px; }
  @media (min-width: 64rem) {
    .bh_nav__scroll {
      overflow: visible;
      height: auto;
      position: static;
      padding: 0;
      display: flex;
      justify-content: space-between; } }

.bh_nav__links[role='list'] {
  margin: 0;
  padding: 0;
  list-style: none; }
  .bh_nav__links[role='list'] a {
    color: #000000; }
    .bh_nav__links[role='list'] a:hover {
      text-decoration: none; }
      @media (min-width: 64rem) {
        .bh_nav__links[role='list'] a:hover {
          color: #136ef6; } }
  @media (min-width: 64rem) {
    .bh_nav__links[role='list'] {
      display: flex; } }

.bh_nav__links-item {
  position: relative;
  border-bottom: 1px solid #eaeaea; }
  @media (min-width: 64rem) {
    .bh_nav__links-item {
      border-bottom: none;
      position: relative; } }

.bh_nav__link {
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  padding: 22px 0;
  display: block;
  position: relative; }
  .bh_nav-links-expandable .bh_nav__link::after {
    content: '';
    position: absolute;
    background-image: url("../images/icons/chevron-blue.svg");
    background-size: 14px;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    top: 50%;
    transform: translateY(-50%) rotate(90deg) scaleX(1);
    transition: transform 400ms;
    right: 0; }
    @media (min-width: 64rem) {
      .bh_nav-links-expandable .bh_nav__link::after {
        display: none; } }
  .bh_nav-links-expandable.bh-nav--dropdown-open .bh_nav__link::after {
    transform: translateY(-50%) rotate(90deg) scaleX(-1); }
    @media (min-width: 64rem) {
      .bh_nav-links-expandable.bh-nav--dropdown-open .bh_nav__link::after {
        transform: none; } }
  @media (min-width: 64rem) {
    .bh_nav__link {
      font-size: 16px;
      font-weight: 500;
      padding: 0 20px;
      display: flex;
      height: 92px;
      align-items: center; }
      .bh_nav__link.--selected {
        font-weight: 700; }
        .bh_nav__link.--selected::before {
          content: '';
          position: absolute;
          bottom: -2px;
          left: 20px;
          height: 2px;
          width: calc(100% - 40px);
          background: #136ef6; }
      .bh-nav--dropdown-open .bh_nav__link::before {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 20px;
        height: 2px;
        width: calc(100% - 40px);
        background: #136ef6; }
      .bh-nav--dropdown-open .bh_nav__link::after {
        display: block;
        content: '';
        width: 100%;
        height: 4px;
        bottom: -2px;
        left: 0;
        background: transparent;
        top: inherit;
        transform: none;
        right: inherit; } }

.bh_nav__logins {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px; }
  @media (min-width: 64rem) {
    .bh_nav__logins {
      margin-top: 0; } }

.bh_nav__login-login {
  margin-right: 30px; }
  .bh_nav__login-login:hover {
    text-decoration: none;
    color: #136ef6; }

.bh_nav__links-inner {
  margin: 0;
  padding: 0;
  list-style: none;
  max-height: 0;
  overflow: hidden;
  transition: max-height 400ms;
  visibility: hidden; }
  .bh-nav--dropdown-open .bh_nav__links-inner {
    max-height: 900px;
    visibility: visible; }
  @media (min-width: 64rem) {
    .bh_nav__links-inner {
      position: absolute;
      background: #ffffff;
      width: 200px;
      top: 102%;
      left: 0;
      padding: 0 0 20px;
      display: none;
      max-height: inherit;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2); }
      .bh-nav--dropdown-open .bh_nav__links-inner {
        display: block;
        max-height: inherit; } }

.bh_nav__link-inner {
  padding: 15px 0;
  font-weight: 500;
  display: block; }
  @media (min-width: 64rem) {
    .bh_nav__link-inner {
      padding: 8px 20px; } }

.bh_nav__links-inner-item:nth-child(1) .bh_nav__link-inner {
  padding: 0 0 15px; }
  @media (min-width: 64rem) {
    .bh_nav__links-inner-item:nth-child(1) .bh_nav__link-inner {
      padding: 10px 20px 8px; } }

.bh_nav__links-inner-item:last-of-type .bh_nav__link-inner {
  padding: 15px 0 30px; }
  @media (min-width: 64rem) {
    .bh_nav__links-inner-item:last-of-type .bh_nav__link-inner {
      padding: 10px 20px 8px; } }

@media (min-width: 64rem) {
  .bh_nav__links-inner-item.--desktop-hidden {
    display: none; }
    .bh_nav__links-inner-item.--desktop-hidden + li .bh_nav__link-inner {
      padding: 10px 20px 8px; } }

.bh_nav__logo-link {
  display: flex;
  align-items: center; }
  .bh_nav__logo-link img {
    vertical-align: bottom; }

#s4s-home .bh_nav {
  box-shadow: none; }
  #s4s-home .bh_nav.bh-nav--scrolled {
    background: #ffffff;
    box-shadow: 0px 2px 0px #eaeaea; }

#s4s-home .bh_nav:not(.bh-nav--scrolled) {
  background-color: transparent; }
  #s4s-home .bh_nav:not(.bh-nav--scrolled) .bh_nav__logo--invert {
    display: block; }
  #s4s-home .bh_nav:not(.bh-nav--scrolled) .bh_nav__logo--normal {
    display: none; }
  @media (min-width: 64rem) {
    #s4s-home .bh_nav:not(.bh-nav--scrolled) .bh_nav__right {
      background-color: transparent; } }
  @media (min-width: 64rem) {
    #s4s-home .bh_nav:not(.bh-nav--scrolled) .bh_nav__links-item > a,
    #s4s-home .bh_nav:not(.bh-nav--scrolled) .bh_nav__logins a {
      color: #ffffff; } }
  @media (min-width: 64rem) {
    #s4s-home .bh_nav:not(.bh-nav--scrolled) .bh_nav__links-item > a:hover,
    #s4s-home .bh_nav:not(.bh-nav--scrolled) .bh_nav__logins a:hover {
      font-weight: 700; } }
  #s4s-home .bh_nav:not(.bh-nav--scrolled) .bh_nav__left .bh_nav__toggle svg > * {
    stroke: #ffffff; }
  #s4s-home .bh_nav:not(.bh-nav--scrolled) .--mobile-open .bh_nav__left .bh_nav__toggle svg > * {
    stroke: #000000; }

.bh_copy-with-cb h2 {
  max-width: 522px; }

.bh_copy-with-cb-grid div:last-of-type img {
  max-width: 329px;
  width: 100%; }

.bh_copy-with-cb-columns {
  columns: 2; }
  .bh_copy-with-cb-columns li {
    margin-bottom: 17px;
    position: relative;
    padding-left: 42px;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid; }
    .bh_copy-with-cb-columns li::before {
      content: '';
      background-image: url("../images/icons/checkmark-circle.svg");
      width: 26px;
      height: 26px;
      position: absolute;
      background-size: 26px;
      left: 0; }

.bh_copy-with-icon-list h2 {
  max-width: 522px; }

.bh_copy-with-icon-list-grid div:last-of-type img {
  max-width: 329px;
  width: 100%; }

.bh_copy-with-icon-list-list.--wide {
  max-width: 700px; }

.bh_copy-with-icon-list-list li {
  display: flex; }
  @media (min-width: 53.125rem) {
    .bh_copy-with-icon-list-list li + li {
      margin-top: 30px; } }
  .bh_copy-with-icon-list-list li > div:first-of-type {
    width: 48px;
    margin-right: 24px;
    flex-shrink: 0; }
    .bh_copy-with-icon-list-list li > div:first-of-type img {
      width: 100%; }

.bh_left-text-wide-image img {
  max-width: 329px;
  width: 100%; }

.bh_image-mobile-overflow .bh_left-text-wide-image {
  margin-bottom: -40px; }
  @media (min-width: 47.9375rem) {
    .bh_image-mobile-overflow .bh_left-text-wide-image {
      margin-bottom: -80px; } }
  @media (min-width: 53.125rem) {
    .bh_image-mobile-overflow .bh_left-text-wide-image {
      margin-bottom: 0; } }
  .bh_image-mobile-overflow .bh_left-text-wide-image .bh_image-right {
    position: relative; }
    .bh_image-mobile-overflow .bh_left-text-wide-image .bh_image-right img {
      position: relative;
      z-index: 1; }
    .bh_image-mobile-overflow .bh_left-text-wide-image .bh_image-right::after {
      content: '';
      background: #f9f9f9;
      position: absolute;
      width: 100vw;
      height: 80px;
      bottom: 0;
      left: -16px; }
      @media (min-width: 53.125rem) {
        .bh_image-mobile-overflow .bh_left-text-wide-image .bh_image-right::after {
          display: none; } }

.bh_right-align-copy-image {
  padding-top: 10px;
  margin-top: 200px; }
  .bh_right-align-copy-image img {
    max-width: 280px;
    width: 100%; }
  .bh_right-align-copy-image-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end; }
    .bh_right-align-copy-image-content h2 {
      margin-bottom: 20px; }
  .bh_right-align-copy-image .bh_image-left {
    margin-top: -230px; }
  @media (min-width: 53.125rem) {
    .bh_right-align-copy-image {
      margin-top: 0px; }
      .bh_right-align-copy-image .bh_image-left {
        margin-top: -60px; }
      .bh_right-align-copy-image img {
        padding-top: 20px;
        max-width: 475px;
        width: initial;
        margin: 0 40px; }
      .bh_right-align-copy-image-content > *:first-child {
        font-size: 22px;
        line-height: 36px;
        margin: 50px 0 20px; }
      .bh_right-align-copy-image-content .bh_type-body, .bh_right-align-copy-image-content .bh_faq-question, .bh_right-align-copy-image-content .bh_faq-answer {
        padding-bottom: 20px; } }

.bh_title-two-columns {
  display: flex;
  flex-direction: column;
  margin-bottom: -40px; }
  .bh_title-two-columns-body {
    display: flex;
    flex-direction: column;
    padding-top: 20px; }
    .bh_title-two-columns-body-left {
      display: flex;
      flex-direction: column-reverse; }
      .bh_title-two-columns-body-left img {
        max-width: 200px;
        align-self: center; }
    .bh_title-two-columns-body p {
      margin-bottom: 0; }
  @media (min-width: 53.125rem) {
    .bh_title-two-columns-body {
      flex-direction: row; }
      .bh_title-two-columns-body-left {
        flex-direction: column; }
        .bh_title-two-columns-body-left img {
          max-width: 280px;
          align-self: auto;
          width: 100%; } }

.bh_title-two-columns-list {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  list-style: none;
  margin-top: 0; }
  .bh_title-two-columns-list li {
    display: flex; }
    .bh_title-two-columns-list li p {
      margin-left: 15px;
      color: #ffffff;
      line-height: 26px;
      margin-bottom: 20px; }
  .bh_title-two-columns-list-icon {
    height: 26px;
    width: 26px; }

.bh_title-two-columns-headline {
  text-align: center; }
  @media (min-width: 53.125rem) {
    .bh_title-two-columns-headline p {
      max-width: 552px;
      margin-left: auto;
      margin-right: auto; } }

.bh_image-copy-tiles .bh_type-title-1 {
  color: #136ef6; }

.bh_image-copy-tiles .bh_type-headline {
  color: #136ef6;
  line-height: 40px;
  font-size: 30px; }

.bh_image-copy-tiles img {
  max-width: 280px; }

.bh_image-copy-tiles .bh_right-align-copy-image {
  margin-top: 0;
  padding-top: 40px; }
  .bh_image-copy-tiles .bh_right-align-copy-image .bh_image-left {
    margin-top: 0; }

.bh_image-copy-tiles .bh_right-align-copy-image > :first-child {
  display: flex;
  flex-direction: column-reverse; }
  .bh_image-copy-tiles .bh_right-align-copy-image > :first-child img {
    padding-top: 20px; }

@media (min-width: 53.125rem) {
  .bh_image-copy-tiles .bh_left-text-wide-image-content,
  .bh_image-copy-tiles .bh_right-align-copy-image-content {
    max-width: 405px !important; }
  .bh_image-copy-tiles .bh_right-align-copy-image > :first-child {
    flex-direction: row; }
  .bh_image-copy-tiles section .bh_right-align-copy-image .bh_image-left {
    margin-top: 0;
    margin-right: 30px; }
  .bh_image-copy-tiles section.--bottom {
    padding-bottom: 40px; }
  .bh_image-copy-tiles section.--top {
    padding-top: 60px; }
  .bh_image-copy-tiles img {
    max-width: 480px;
    margin-top: 20px;
    margin-left: 0; } }

.bh_footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #090304;
  padding-top: 0px;
  transition: all .5 ease;
  overflow: hidden;
  position: relative; }
  .bh_footer h2 {
    display: flex;
    justify-content: center;
    color: #ffffff;
    margin-bottom: 0px;
    padding-top: 25px;
    font-size: 18px; }
  .bh_footer::before {
    display: none; }
  @media (min-width: 20.0625rem) {
    .bh_footer h2 {
      font-size: 22px; } }
  @media (min-width: 47.9375rem) {
    .bh_footer h2 {
      font-size: 36px;
      line-height: 44px;
      padding: 50px 0; }
    .bh_footer::after {
      content: '';
      position: absolute;
      width: 494px;
      height: 494px;
      border: 16px solid #136ef6;
      border-radius: 50%;
      bottom: -50%;
      right: -100px;
      z-index: 0; } }

.bh_footer-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px; }
  .bh_footer-container .bh_type-body, .bh_footer-container .bh_faq-question, .bh_footer-container .bh_faq-answer {
    color: #ffffff; }
  .bh_footer-container .bh_footer-link {
    color: #ffffff;
    margin-bottom: 20px; }
    .bh_footer-container .bh_footer-link:hover {
      color: #136ef6;
      cursor: pointer;
      text-decoration: none; }
  @media (min-width: 47.9375rem) {
    .bh_footer-container {
      flex-direction: row;
      padding-bottom: 50px;
      margin: 0px; }
      .bh_footer-container .bh_footer-link {
        margin: 3px 0;
        font-size: 13px; } }

.bh_footer-item {
  border-bottom: 1px solid #88969e; }
  .bh_footer-item-container {
    display: flex;
    flex-direction: column;
    max-height: 0;
    overflow: hidden;
    transition: max-height 400ms, visibility 400ms;
    visibility: hidden; }
    .bh_footer-item-container.--mobile-open {
      max-height: 900px;
      visibility: visible; }
  .bh_footer-item:last-child {
    border-bottom: none; }
  .bh_footer-item .bh_type-body, .bh_footer-item .bh_faq-question, .bh_footer-item .bh_faq-answer {
    font-weight: 700; }
  .bh_footer-item .bh_footer-social-icons {
    display: none; }
  .bh_footer-item-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 65px; }
  .bh_footer-item .bh_footer-mobile-dropdown {
    transition: .5s all; }
    .bh_footer-item .bh_footer-mobile-dropdown.--rotate-image {
      transform: rotate(180deg); }
  @media (min-width: 47.9375rem) {
    .bh_footer-item {
      border: none;
      margin-right: auto;
      z-index: 1; }
      .bh_footer-item-container {
        max-height: inherit;
        overflow: hidden;
        transition: none;
        visibility: visible; }
      .bh_footer-item-title {
        height: initial; }
      .bh_footer-item .bh_type-body, .bh_footer-item .bh_faq-question, .bh_footer-item .bh_faq-answer {
        font-weight: 500; }
      .bh_footer-item .bh_footer-mobile-dropdown {
        display: none; } }

.bh_footer-details {
  display: none; }

.bh_footer-seals {
  filter: grayscale(1);
  margin-right: 25px; }

.bh_footer-social-icons a {
  color: #136ef6; }

.bh_footer-social-icons svg {
  margin: 0 8px; }

@media (min-width: 47.9375rem) {
  .bh_footer-details-mobile {
    display: none; }
  .bh_footer-details {
    display: initial;
    padding: 40px 0; }
  .bh_footer-social-icons {
    display: flex;
    z-index: 1 !important; }
    .bh_footer-social-icons svg {
      margin: 0 14px 0 0;
      height: 16px;
      width: 16px; }
    .bh_footer-social-icons a {
      color: #136ef6; }
    .bh_footer-social-icons a:hover {
      color: white; } }

.bh_footer-details-group {
  display: flex;
  position: relative;
  justify-content: space-between; }

.bh_footer-details-left {
  display: flex;
  z-index: 1; }
  .bh_footer-details-left .bh_type-body, .bh_footer-details-left .bh_faq-question, .bh_footer-details-left .bh_faq-answer {
    color: #88969e;
    margin-right: 10px;
    margin-bottom: 5px; }
  .bh_footer-details-left .bh_type-link {
    display: initial;
    color: #ffffff;
    text-decoration: underline; }
    .bh_footer-details-left .bh_type-link:hover {
      color: #136ef6;
      cursor: pointer; }

.bh_footer-details-right {
  display: flex;
  align-items: center; }
  .bh_footer-details-right img {
    margin: 0 0 10px 10px; }
  .bh_footer-details-right .bh_type-body, .bh_footer-details-right .bh_faq-question, .bh_footer-details-right .bh_faq-answer {
    color: #ffffff;
    margin-left: 10px; }

.bh_footer-details-mobile-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #88969e;
  height: 75px; }
  .bh_footer-details-mobile-top p {
    color: #ffffff;
    margin: 0 0 0 10px; }

.bh_footer-details-mobile-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 150px;
  border-top: 1px solid #88969e; }
  .bh_footer-details-mobile-bottom p {
    margin-top: 10px;
    color: #88969e; }

.bh_footer-location-dropdown-container {
  position: relative;
  margin-left: 10px;
  margin-bottom: 30px; }
  @media (min-width: 47.9375rem) {
    .bh_footer-location-dropdown-container {
      margin: 0;
      width: 130px; } }

.bh_footer-location-dropdown {
  overflow-y: hidden;
  width: 120px;
  height: 30px;
  position: absolute;
  padding-top: 5px;
  transition: height .25s ease-in, width .1s ease-in .5s, background-color .35s ease-in; }
  .bh_footer-location-dropdown span {
    display: flex;
    align-content: center;
    margin-left: 10px; }
  .bh_footer-location-dropdown span .bh_type-body, .bh_footer-location-dropdown span .bh_faq-question, .bh_footer-location-dropdown span .bh_faq-answer {
    margin-bottom: 0px;
    margin-right: 20px; }
    .bh_footer-location-dropdown span .bh_type-body:hover, .bh_footer-location-dropdown span .bh_faq-question:hover, .bh_footer-location-dropdown span .bh_faq-answer:hover {
      color: #136ef6;
      cursor: pointer;
      text-decoration: none; }
  .bh_footer-location-dropdown .bh_footer-location-dropdown-selection ~ span {
    margin-left: 40px; }
  .bh_footer-location-dropdown .bh_footer-dropdown-globe {
    margin-right: 10px; }
  .bh_footer-location-dropdown.--dropdown-open {
    background-color: #ffffff;
    height: 130px;
    width: 170px;
    overflow-y: auto;
    transition: height .25s ease-out; }
    .bh_footer-location-dropdown.--dropdown-open .bh_type-body, .bh_footer-location-dropdown.--dropdown-open .bh_faq-question, .bh_footer-location-dropdown.--dropdown-open .bh_faq-answer {
      color: #090304; }
    .bh_footer-location-dropdown.--dropdown-open .bh_footer-dropdown-icon {
      display: none; }
    .bh_footer-location-dropdown.--dropdown-open .bh_footer-dropdown-globe {
      content: url("../../images/icons/globe.svg"); }
  @media (min-width: 47.9375rem) {
    .bh_footer-location-dropdown {
      bottom: 1px;
      padding: 0px; }
      .bh_footer-location-dropdown::-webkit-scrollbar {
        width: 5px; }
      .bh_footer-location-dropdown::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 10px;
        border: 3px solid #c1c1c1; }
      .bh_footer-location-dropdown:hover {
        color: #136ef6;
        cursor: pointer; } }

@media (min-width: 47.9375rem) {
  .bh_footer-header {
    font-size: 16px; } }

.bh_text-image-text img {
  max-width: 343px; }

.bh_text-image-text > div:last-of-type {
  border-top: 1px solid #88969e;
  padding-top: 30px;
  margin-top: 30px; }
  @media (min-width: 53.125rem) {
    .bh_text-image-text > div:last-of-type {
      margin-top: 50px; } }

.bh_feature-nav {
  height: 70px;
  overflow: hidden;
  border-bottom: 1px solid #c1c1c1;
  max-width: 1029px;
  margin: 0 auto; }
  @media (min-width: 53.125rem) {
    .bh_feature-nav {
      margin-top: 35px; } }

.bh_feature-nav__inner {
  position: relative;
  width: 100%;
  overflow-x: auto; }

.bh_feature-nav__scroll {
  display: flex;
  justify-content: space-between;
  width: 1029px; }

.bh_feature-nav__link {
  width: 78px;
  height: 70px;
  align-items: center;
  display: flex;
  line-height: 20px; }
  .bh_feature-nav__link:last-child {
    width: 88px; }
  .bh_feature-nav__link:hover {
    text-decoration: none;
    color: #136ef6;
    font-weight: 700; }
  .bh_feature-nav__link--active {
    font-weight: 700;
    color: #136ef6;
    position: relative; }
    .bh_feature-nav__link--active::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 4px;
      width: 100%;
      background-color: #136ef6; }

.bh_hp-hero {
  position: relative; }
  @media (min-width: 64rem) {
    .bh_hp-hero::before, .bh_hp-hero::after {
      content: '';
      position: absolute;
      background-repeat: no-repeat;
      background-size: contain; } }
  @media (min-width: 64rem) {
    .bh_hp-hero::before {
      background-image: url("../images/hero/hero-image.png");
      width: 232px;
      height: 330px;
      bottom: 50px;
      left: -100px; } }
  @media (min-width: 74.9375rem) {
    .bh_hp-hero::before {
      left: 0; } }
  @media (min-width: 64rem) {
    .bh_hp-hero::after {
      background-image: url("../images/hero/hero-image-2.png");
      width: 174px;
      height: 297px;
      right: -100px;
      top: 0; } }
  @media (min-width: 74.9375rem) {
    .bh_hp-hero::after {
      right: 0; } }

.bh_hp-hero__inner {
  max-width: 836px; }
  .bh_hp-hero__inner h1 {
    font-size: 30px;
    line-height: 40px;
    font-family: freight-sans-pro, sans-serif;
    font-weight: 700;
    color: inherit;
    margin-bottom: 22px; }
    @media (min-width: 47.9375rem) {
      .bh_hp-hero__inner h1 {
        font-size: 56px;
        line-height: 60px; } }
  .bh_hp-hero__inner p {
    line-height: 28px;
    font-family: freight-sans-pro, sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: inherit;
    margin-bottom: 36px; }
    @media (min-width: 47.9375rem) {
      .bh_hp-hero__inner p {
        font-size: 22px;
        line-height: 36px;
        margin-bottom: 50px; } }

.bh_hp-hero__content {
  max-width: 550px;
  margin: 0 auto; }

.bh_hp-hero__signup {
  margin-bottom: 30px; }
  @media (min-width: 47.9375rem) {
    .bh_hp-hero__signup {
      margin-bottom: 20px;
      display: flex; }
      .bh_hp-hero__signup input {
        flex-grow: 1; } }

.bh_hp-hero__input {
  width: 100%; }
  @media (min-width: 47.9375rem) {
    .bh_hp-hero__input {
      flex-grow: 1;
      width: auto; } }

.bh_hp-hero__submit {
  width: 100%;
  height: 50px; }
  @media (min-width: 47.9375rem) {
    .bh_hp-hero__submit {
      width: auto; } }

.bh_hp-cta__inner {
  align-items: center !important;
  flex-direction: column;
  display: flex;
  padding-top: 100px; }
  @media (min-width: 64rem) {
    .bh_hp-cta__inner {
      padding-top: 138px; } }
  @media (min-width: 64rem) {
    .bh_hp-cta__inner h1 {
      font-size: 30px; } }

.bh_hp-cta__content {
  max-width: 550px; }
  .bh_hp-cta__content a {
    font-weight: 700;
    text-decoration: underline;
    color: #ffffff; }
    .bh_hp-cta__content a:hover {
      color: #136ef6; }

.bh_hp-cta__learn-more {
  margin-top: 32px; }

.bh_hp-cta__inspiration-logo {
  display: block;
  width: 100px;
  height: 100px;
  background: url(../images/homepage/inspiration.png);
  background-size: contain;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -20%); }
  @media (min-width: 64rem) {
    .bh_hp-cta__inspiration-logo {
      width: 160px;
      height: 160px; } }

.bh_hp-intro__inner {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .bh_hp-intro__inner p {
    max-width: 620px; }

.bh_hp-intro__columns {
  display: flex;
  flex-direction: column; }
  @media (min-width: 53.125rem) {
    .bh_hp-intro__columns {
      margin-top: 50px;
      flex-direction: row; } }

.bh_hp-intro__column {
  flex-grow: 0;
  flex-shrink: 1; }
  @media (min-width: 53.125rem) {
    .bh_hp-intro__column:last-child {
      margin-left: 50px; } }
  @media (min-width: 53.125rem) {
    .bh_hp-intro__column {
      width: 50%;
      max-width: 410px; } }
  .bh_hp-intro__column img {
    width: 100%;
    max-width: 410px; }
  .bh_hp-intro__column h2 {
    color: #136ef6; }
    @media (min-width: 53.125rem) {
      .bh_hp-intro__column h2 {
        font-size: 22px;
        line-height: 28px; } }
  .bh_hp-intro__column ul,
  .bh_hp-intro__column li {
    list-style: none;
    margin: 0;
    padding: 0; }
  .bh_hp-intro__column ul li {
    margin-bottom: 20px;
    position: relative;
    padding-left: 45px; }
    .bh_hp-intro__column ul li::before {
      content: '';
      background-image: url("../images/icons/checkmark-circle.svg");
      width: 27px;
      height: 27px;
      position: absolute;
      background-size: 27px;
      left: 0; }

.bh_hp-cta-button:hover {
  color: #000000; }

.bh_hp-features__inner {
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media (min-width: 64rem) {
    .bh_hp-features__inner h1 {
      font-size: 36px; } }
  .bh_hp-features__inner p {
    max-width: 600px; }

.bh_hp-features__columns {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 30px; }
  @media (min-width: 74.9375rem) {
    .bh_hp-features__columns {
      margin-top: 50px;
      flex-direction: row;
      margin-bottom: 50px; } }

.bh_hp-features__column {
  flex-grow: 0;
  flex-shrink: 1; }
  .bh_hp-features__column:last-child {
    margin-left: 20px;
    margin-right: 20px; }
    @media (min-width: 74.9375rem) {
      .bh_hp-features__column:last-child {
        margin-left: 50px;
        margin-right: 0px; } }
  @media (min-width: 74.9375rem) {
    .bh_hp-features__column {
      width: 50%;
      min-width: 550px;
      max-width: 550px; } }
  .bh_hp-features__column img {
    width: 100%;
    max-width: 550px; }
  .bh_hp-features__column h2 {
    color: #136ef6; }
    @media (min-width: 74.9375rem) {
      .bh_hp-features__column h2 {
        font-size: 30px; } }

.bh_hp-features__grid {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .bh_hp-features__grid, .bh_hp-features__grid li {
    list-style: none;
    margin: 0;
    padding: 0; }
  .bh_hp-features__grid li {
    width: 182px;
    height: 182px;
    border-left: 1px solid #136ef6;
    border-top: 1px solid #136ef6;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    .bh_hp-features__grid li:nth-child(3n) {
      border-right: 1px solid #136ef6; }
    .bh_hp-features__grid li:nth-child(n+7) {
      border-bottom: 1px solid #136ef6; }
    .bh_hp-features__grid li:hover svg path {
      stroke: #000000; }
    .bh_hp-features__grid li:hover > div {
      color: #000000; }
    .bh_hp-features__grid li.bh_hp-features__grid-item--active {
      background: #136ef6; }
      .bh_hp-features__grid li.bh_hp-features__grid-item--active svg path {
        stroke: #ffffff; }
      .bh_hp-features__grid li.bh_hp-features__grid-item--active svg path {
        stroke: #ffffff; }
      .bh_hp-features__grid li.bh_hp-features__grid-item--active > div {
        color: #ffffff; }
    .bh_hp-features__grid li img, .bh_hp-features__grid li svg {
      height: 48px;
      width: 100%;
      margin-top: 20px; }
      .bh_hp-features__grid li img path, .bh_hp-features__grid li svg path {
        stroke: #136ef6; }
    .bh_hp-features__grid li > div {
      height: 62px;
      max-width: 70%;
      color: #136ef6;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 22px; }
    .bh_hp-features__grid li:hover {
      cursor: pointer; }

.bh_hp-features__column--left {
  display: none;
  margin-right: 16px; }
  @media (min-width: 74.9375rem) {
    .bh_hp-features__column--left {
      display: block; } }

.bh_hp-features__column--right {
  width: 100%;
  padding-bottom: 20px;
  background-color: #ffffff;
  position: relative; }
  @media (min-width: 74.9375rem) {
    .bh_hp-features__column--right {
      height: 546px;
      max-width: 550px; } }

.bh_hp-features__slideshow-slide {
  padding: 30px 20px 0; }
  .bh_hp-features__slideshow-slide img {
    max-height: 132px;
    width: auto;
    margin-bottom: 30px; }
    @media (min-width: 64rem) {
      .bh_hp-features__slideshow-slide img {
        margin: 0 auto 24px;
        max-height: 200px; } }
  @media (min-width: 64rem) {
    .bh_hp-features__slideshow-slide {
      padding: 30px 80px 0; } }

.bh_hp-features__slideshow-slide-controls {
  display: none;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0; }
  @media (min-width: 74.9375rem) {
    .bh_hp-features__slideshow-slide-controls {
      display: flex; } }
  .bh_hp-features__slideshow-slide-controls button {
    width: 40px;
    height: 40px;
    display: block;
    -webkit-appearance: none;
    border: none;
    position: relative;
    background-color: transparent; }
    .bh_hp-features__slideshow-slide-controls button svg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
      .bh_hp-features__slideshow-slide-controls button svg * {
        stroke: #000000; }
    .bh_hp-features__slideshow-slide-controls button:hover svg * {
      stroke: #136ef6; }
  .bh_hp-features__slideshow-slide-controls .bh_hp-features__slideshow-slide-controls--left {
    transform: rotate(180deg); }

.bh_hp-features__slideshow-slide-bottom-controls {
  display: flex;
  justify-content: center;
  margin-top: 30px; }
  @media (min-width: 74.9375rem) {
    .bh_hp-features__slideshow-slide-bottom-controls {
      display: none; } }
  .bh_hp-features__slideshow-slide-bottom-controls button {
    width: 40px;
    height: 40px;
    display: block;
    background: url("../images/icons/carousel-arrow.svg");
    background-size: contain;
    -webkit-appearance: none;
    border: none; }
  .bh_hp-features__slideshow-slide-bottom-controls .bh_hp-features__slideshow-slide-bottom-controls--left {
    transform: rotate(180deg);
    margin-right: 30px; }

.bh_hp-themes {
  overflow-x: hidden; }
  #s4s-landing .bh_hp-themes {
    background-color: #f9f9f9; }
  .bh_hp-themes h1 {
    line-height: 44px;
    font-size: 36px; }
  .bh_hp-themes p {
    max-width: 705px;
    margin: 0 auto 20px; }
    @media (min-width: 64rem) {
      .bh_hp-themes p {
        margin: 0 auto 50px; } }

.bh_hp-themes__image {
  width: 100%; }
  .bh_hp-themes__image img {
    max-width: 836px; }

.bh-home-themes {
  width: 100%; }
  @media (min-width: 53.9375rem) {
    .bh-home-themes .slick-track {
      left: calc(50% - 805px/2); } }

@media (min-width: 53.9375rem) {
  .bh-home-theme:not(.slick-active) img {
    opacity: 0.1; } }

.bh-home-theme img {
  width: 100%;
  max-width: 268px; }

.bh_hp-themes__control {
  margin: 0;
  padding: 0;
  border: 0;
  appearance: none;
  background: transparent;
  display: inline-flex !important;
  position: absolute;
  top: 40%; }
  .bh_hp-themes__control:hover svg > * {
    stroke: #136ef6; }
  .bh_hp-themes__control.slick-disabled {
    pointer-events: none;
    opacity: 0.2; }
  .bh_hp-themes__control.--left {
    transform: rotate(180deg) translateY(-50%);
    left: calc(50% - 1120px/2);
    transform-origin: top; }
  .bh_hp-themes__control.--right {
    transform: translateY(-50%);
    right: calc(50% - 1120px/2); }

.bh_hp-themes__slider {
  position: relative; }

.bh_hp-integrations {
  background-color: #f9f9f9; }
  .bh_pricing_pages .bh_hp-integrations {
    background-color: #ffffff; }

.bh_hp-integrations__inner {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .bh_hp-integrations__inner h1 {
    max-width: 550px;
    margin-bottom: 32px; }
    @media (min-width: 64rem) {
      .bh_hp-integrations__inner h1 {
        font-size: 30px; } }
  .bh_hp-integrations__inner p {
    max-width: 550px; }
  .bh_hp-integrations__inner button {
    width: 40px;
    height: 40px;
    background: url("../images/icons/carousel-arrow.svg");
    background-size: contain;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    border: none; }
  .bh_hp-integrations__inner .bh_hp-integrations__slideshow-button--previous {
    transform: rotate(180deg);
    margin-right: 30px; }

.bh_hp-integrations__slideshow {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: flex-start; }
  @media (min-width: 47.9375rem) {
    .bh_hp-integrations__slideshow {
      justify-content: center; } }
  .bh_hp-integrations__slideshow button {
    display: none; }
    @media (min-width: 64rem) {
      .bh_hp-integrations__slideshow button {
        display: block; } }
  .bh_hp-integrations__slideshow > div {
    width: 100%; }
    @media (min-width: 47.9375rem) {
      .bh_hp-integrations__slideshow > div {
        width: 90%; } }

.bh_hp-integrations__slides {
  width: 100%; }
  .bh_hp-integrations__slides.slick-initialized .slick-slide {
    display: flex;
    height: 70px;
    align-items: center;
    justify-content: center; }

.bh_hp-integrations__slide {
  width: 175px; }
  .bh_hp-integrations__slide img {
    margin: 12px;
    max-width: 112px; }
    @media (min-width: 64rem) {
      .bh_hp-integrations__slide img {
        margin: 0 12px;
        max-width: 145px; } }

.bh_hp-integrations__slideshow-buttons {
  display: flex;
  margin-top: 16px;
  flex-direction: row;
  align-self: center; }
  @media (min-width: 64rem) {
    .bh_hp-integrations__slideshow-buttons {
      display: none; } }

.bh_hp-integrations__slideshow button {
  visibility: hidden;
  position: absolute; }
  @media (min-width: 64rem) {
    .bh_hp-integrations__slideshow button {
      visibility: visible;
      position: static; } }

.bh_hp-integrations__image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;
  max-width: 100%;
  flex-wrap: wrap; }
  @media (min-width: 64rem) {
    .bh_hp-integrations__image-container {
      width: 1120px;
      flex-wrap: nowrap; } }

.bh_hp-support__inner {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .bh_hp-support__inner h1 {
    width: 100%;
    text-align: left; }
  .bh_hp-support__inner p {
    max-width: 620px; }

.bh_hp-support__columns {
  display: flex;
  flex-direction: column; }
  @media (min-width: 53.125rem) {
    .bh_hp-support__columns {
      margin-top: 50px;
      flex-direction: row; } }

.bh_hp-support__column {
  flex-grow: 0;
  flex-shrink: 1; }
  .bh_hp-support__column:last-child {
    width: 40%; }
    @media (min-width: 53.125rem) {
      .bh_hp-support__column:last-child {
        margin-left: 50px; } }
  .bh_hp-support__column:first-child {
    margin-left: 0; }
    @media (min-width: 74.9375rem) {
      .bh_hp-support__column:first-child {
        margin-left: 200px; } }
  .bh_hp-support__column a {
    margin-left: 16px; }
  @media (min-width: 53.125rem) {
    .bh_hp-support__column {
      width: 60%;
      max-width: 510px; } }
  .bh_hp-support__column img {
    width: 100%;
    max-width: 410px; }
  .bh_hp-support__column h2 {
    color: #136ef6; }
    @media (min-width: 53.125rem) {
      .bh_hp-support__column h2 {
        font-size: 30px; } }
  .bh_hp-support__column ul,
  .bh_hp-support__column li {
    list-style: none;
    margin: 0;
    padding: 0; }
  .bh_hp-support__column ul li {
    margin-bottom: 20px;
    position: relative;
    padding-left: 45px; }
    .bh_hp-support__column ul li::before {
      content: '';
      background-image: url("../images/icons/checkmark-circle.svg");
      width: 27px;
      height: 27px;
      position: absolute;
      background-size: 27px;
      left: 0; }

.bh_hp-support__column-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  height: 86px;
  justify-content: space-between;
  width: 100%; }
  .bh_hp-support__column-buttons button:hover {
    color: #000000; }
  .bh_hp-support__column-buttons-link:hover {
    text-decoration: none; }
  @media (min-width: 53.125rem) {
    .bh_hp-support__column-buttons {
      display: block; } }

.bh_hp-support__column--first {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .bh_hp-support__column--first img {
    width: 100%;
    max-width: auto;
    padding: 30px 20px; }
  @media (min-width: 53.125rem) {
    .bh_hp-support__column--first img {
      display: none; } }

.bh_hp-support__column--last img {
  display: none; }

@media (min-width: 53.125rem) {
  .bh_hp-support__column--last img {
    display: block; } }

.bh_hp-websites__inner {
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media (min-width: 64rem) {
    .bh_hp-websites__inner h1 {
      font-size: 36px; } }

.bh_hp-websites__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 32px;
  max-width: 560px;
  width: 100%;
  flex-wrap: wrap; }
  .bh_hp-websites__container a {
    text-decoration: none;
    color: black;
    border: 3px solid transparent;
    margin-bottom: 22px;
    width: calc(50% - 8px);
    cursor: pointer; }
    @media (min-width: 47.9375rem) {
      .bh_hp-websites__container a {
        width: 268px; } }
    .bh_hp-websites__container a:hover {
      border: 3px solid #136ef6;
      border-radius: 4px; }
  @media (min-width: 74.9375rem) {
    .bh_hp-websites__container {
      max-width: 1120px;
      width: 1120px;
      flex-wrap: nowrap; } }

.bh_hp-websites__website-content {
  padding: 22px; }

.bh_hp-websites__website {
  height: 100%;
  text-align: left;
  border: 1px solid #c1c1c1; }
  .bh_hp-websites__website img {
    width: 100%;
    height: 161px;
    object-fit: cover; }
  .bh_hp-websites__website p {
    margin-top: 8px; }

.bh_left-text-wide-bullets li {
  display: flex;
  align-items: center; }
  .bh_left-text-wide-bullets li img {
    vertical-align: bottom; }
  .bh_left-text-wide-bullets li + li {
    margin-top: 28px; }
  .bh_left-text-wide-bullets li p {
    margin: 0 0 0 23px; }

.bh_left-text-wide-bullets ul {
  margin-top: 30px; }
  @media (min-width: 53.125rem) {
    .bh_left-text-wide-bullets ul {
      margin-top: 0; } }

.bh_title-subtitle img {
  vertical-align: bottom;
  max-width: 829px;
  width: 100%;
  margin-top: 10px; }
  @media (min-width: 53.125rem) {
    .bh_title-subtitle img {
      margin-top: 40px; } }

.bh_text-video_inner {
  max-width: 637px;
  width: 100%;
  margin: 0 auto; }
  .bh_text-video_inner + * {
    margin-top: 22px; }

.bh_text-video__iframe {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
  margin-top: 30px; }
  .bh_text-video__iframe iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }

@media (min-width: 53.125rem) {
  .image-wide-column {
    display: flex; } }

.image-wide-column > div img {
  max-width: 284px;
  width: 100%; }
  @media (min-width: 53.125rem) {
    .image-wide-column > div img {
      max-width: 360px; } }

.image-wide-column > div.image-wide-column_img-short img {
  max-height: 360px;
  width: auto; }

@media (min-width: 53.125rem) {
  .image-wide-column > div:first-of-type {
    width: 410px;
    flex-shrink: 0; } }

.image-wide-column > div:last-of-type {
  text-align: center; }
  @media (min-width: 53.125rem) {
    .image-wide-column > div:last-of-type {
      flex-grow: 1; } }

.image-wide-column-title {
  display: flex;
  flex-direction: column; }
  .image-wide-column-title-image {
    max-width: 170px !important;
    max-height: 56px; }
  @media (min-width: 53.125rem) {
    .image-wide-column-title {
      flex-direction: row;
      margin-bottom: 0; }
      .image-wide-column-title-image {
        margin-bottom: 0;
        margin-left: 12px; } }

@media (min-width: 53.125rem) {
  .image-wide-column--left {
    flex-direction: row-reverse; }
    .image-wide-column--left > div:last-of-type {
      padding-right: 32px; } }

@media (min-width: 53.125rem) {
  .image-wide-column--right > div:last-of-type {
    padding-left: 32px; } }

.image-wide-column__iframe {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
  margin-top: 30px; }
  .image-wide-column__iframe iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }
    @media (min-width: 53.125rem) {
      .image-wide-column__iframe iframe {
        width: 95%;
        height: 95%; } }

.bh_title-icon-list__content {
  margin-bottom: 35px; }
  @media (min-width: 53.125rem) {
    .bh_title-icon-list__content {
      margin-bottom: 40px; } }

.bh_title-icon-list__list li {
  display: flex; }
  .bh_title-icon-list__list li > div:last-of-type {
    margin-top: 10px; }
  .bh_title-icon-list__list li > div:first-of-type {
    width: 48px;
    margin-right: 24px;
    flex-shrink: 0; }
    .bh_title-icon-list__list li > div:first-of-type img {
      width: 100%; }

.text-three-columns__columns {
  margin-top: 20px; }
  .text-three-columns__columns img {
    max-width: 100%;
    vertical-align: bottom;
    margin-bottom: 16px; }
  @media (min-width: 47.9375rem) {
    .text-three-columns__columns {
      display: flex;
      flex-wrap: wrap;
      margin-right: -16px;
      margin-top: 40px; } }
  .text-three-columns__columns > * {
    margin-bottom: 30px; }
    @media (min-width: 47.9375rem) {
      .text-three-columns__columns > * {
        width: calc(33.33% - 16px);
        margin-right: 16px;
        margin-bottom: 0; } }

.text-two-columns__columns {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  margin-right: -16px; }
  .text-two-columns__columns img {
    max-width: 100%;
    vertical-align: bottom;
    margin-bottom: 16px; }
  @media (min-width: 47.9375rem) {
    .text-two-columns__columns {
      margin-top: 40px; } }
  .text-two-columns__columns > * {
    margin-bottom: 30px;
    width: calc(50% - 16px);
    margin-right: 16px; }
    @media (min-width: 47.9375rem) {
      .text-two-columns__columns > * {
        margin-bottom: 0; } }

.image-column h3 {
  margin-bottom: 10px; }

@media (min-width: 53.125rem) {
  .image-column {
    display: flex;
    align-items: center;
    margin-right: -16px; } }

@media (min-width: 53.125rem) {
  .image-column > * {
    width: calc(50% - 16px);
    margin-right: 16px; } }

.image-column > div img {
  max-width: 247px;
  width: 100%;
  margin-bottom: 10px; }
  @media (min-width: 53.125rem) {
    .image-column > div img {
      margin-bottom: 0;
      max-width: 100%;
      max-height: 300px;
      height: auto;
      width: auto; } }

.image-column + .image-column {
  margin-top: 40px; }

@media (min-width: 53.125rem) {
  .image-column--right {
    flex-direction: row-reverse; } }

@media (min-width: 53.125rem) {
  .image-column--right > div:first-of-type {
    text-align: right; } }

@media (min-width: 53.125rem) {
  .image-column-multiple h2 {
    margin-bottom: 30px; } }

.bh_third-party img {
  max-width: 338px; }

.bh_third-party p:last-of-type {
  margin-bottom: 30px; }

.bh_third-party ul {
  display: flex;
  flex-wrap: wrap;
  margin-right: -16px;
  margin-top: 50px; }
  .bh_third-party ul > * {
    width: calc(50% - 16px);
    margin-right: 16px;
    margin-bottom: 16px; }
    @media (min-width: 25rem) {
      .bh_third-party ul > * {
        width: auto; } }

.bh_pricing-hero > div > h1 {
  font-size: 36px;
  line-height: 44px;
  margin-bottom: 12px; }
  @media (min-width: 64rem) {
    .bh_pricing-hero > div > h1 {
      font-size: 56px;
      line-height: 60px; } }

.bh_pricing-plans-grid {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 22px;
  flex-wrap: wrap; }
  @media (min-width: 64rem) {
    .bh_pricing-plans-grid {
      margin-top: 50px; } }

.bh_pricing-plans-grid__item {
  width: calc(50% - 8px);
  margin-bottom: 16px;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #ffffff;
  display: flex;
  flex-direction: column; }
  .bh_pricing-plans-grid__item:nth-child(n+3) {
    margin-top: 20px; }
    @media (min-width: 64rem) {
      .bh_pricing-plans-grid__item:nth-child(n+3) {
        margin-top: 0; } }
  @media (min-width: 64rem) {
    .bh_pricing-plans-grid__item {
      width: calc(25% - 8px);
      margin-bottom: 0; } }
  .bh_pricing-plans-grid__item button:hover {
    color: #000000; }

.bh_pricing-plans-grid__item-header {
  background-color: #136ef6;
  position: relative;
  color: #ffffff;
  padding: 6px 0; }
  @media (min-width: 64rem) {
    .bh_pricing-plans-grid__item-header {
      padding: 16px 18px 12px; } }
  .bh_pricing-plans-grid__item-header p {
    margin-top: 0px;
    margin-bottom: 0px; }
    @media (min-width: 64rem) {
      .bh_pricing-plans-grid__item-header p {
        margin: 0 14px;
        font-size: 16px; } }

.bh_pricing-plans-grid__item-price {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 10px; }
  @media (min-width: 64rem) {
    .bh_pricing-plans-grid__item-price {
      margin-top: 6px; } }
  .bh_pricing-plans-grid__item-price > span:nth-child(1) {
    margin-right: 4px; }
  .bh_pricing-plans-grid__item-price > span:nth-child(2) {
    line-height: 24px;
    font-size: 36px; }
    @media (min-width: 64rem) {
      .bh_pricing-plans-grid__item-price > span:nth-child(2) {
        font-size: 56px; } }
  .bh_pricing-plans-grid__item-price > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 6px; }
    .bh_pricing-plans-grid__item-price > div > span:nth-child(1) {
      line-height: 16px; }
    .bh_pricing-plans-grid__item-price > div > span:nth-child(2) {
      margin-top: 4px; }

.bh_pricing-plans-grid__item-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  position: relative;
  padding: 12px 7px 10px; }
  @media (min-width: 64rem) {
    .bh_pricing-plans-grid__item-body {
      padding: 24px 56px 29px;
      min-height: 227px; }
      .bh_pricing-plans-grid__item-body-special {
        padding-right: 30px;
        padding-left: 30px;
        align-items: center; }
        .bh_pricing-plans-grid__item-body-special button {
          width: 160px; } }
  .bh_pricing-plans-grid__item-body a {
    text-decoration: none; }
  .bh_pricing-plans-grid__item-body ul, .bh_pricing-plans-grid__item-body li {
    margin: 0;
    padding: 0;
    text-align: left; }
  .bh_pricing-plans-grid__item-body ul {
    margin-bottom: 16px;
    margin-left: 18px; }
    @media (min-width: 64rem) {
      .bh_pricing-plans-grid__item-body ul {
        margin-bottom: 24px; } }
  @media (min-width: 64rem) {
    .bh_pricing-plans-grid__item-body li {
      font-size: 16px; } }
  .bh_pricing-plans-grid__item-body span {
    font-style: normal;
    position: absolute;
    bottom: 6px;
    left: 50%;
    transform: translate(-50%, 0);
    display: none; }
    @media (min-width: 64rem) {
      .bh_pricing-plans-grid__item-body span {
        display: block; } }

.bh_pricing-plans-grid__item-callout {
  position: absolute;
  color: #000000;
  background-color: #ffffff;
  top: 0;
  left: -1px;
  right: -1px;
  transform: translate(0, -100%);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px; }
  @media (min-width: 64rem) {
    .bh_pricing-plans-grid__item-callout {
      padding: 2px 0; } }

.bh_plan-features {
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media (min-width: 64rem) {
    .bh_plan-features h1 {
      line-height: 44px;
      font-size: 36px; } }
  .bh_plan-features ul, .bh_plan-features li {
    list-style: none;
    margin: 0;
    padding: 0; }
  .bh_plan-features ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 16px; }
    @media (min-width: 64rem) {
      .bh_plan-features ul {
        justify-content: flex-start;
        max-width: 847px;
        transform: translate(5%, 0);
        margin-top: 40px; } }
  .bh_plan-features li {
    width: 100%;
    text-align: left;
    width: calc(50% - 8px);
    margin-bottom: 20px;
    position: relative;
    padding-left: 36px; }
    @media (min-width: 64rem) {
      .bh_plan-features li {
        width: 33%; } }
    .bh_plan-features li a {
      color: #136ef6; }
    .bh_plan-features li::before {
      content: '';
      background-image: url("../images/icons/checkmark-circle.svg");
      width: 27px;
      height: 27px;
      position: absolute;
      background-size: 27px;
      left: 0; }

.bh_pricing-cta__inner {
  align-items: center !important;
  flex-direction: column;
  display: flex; }
  @media (min-width: 64rem) {
    .bh_pricing-cta__inner h1 {
      font-size: 30px; } }

.bh_pricing-cta__content {
  max-width: 550px; }
  .bh_pricing-cta__content a {
    font-weight: 700;
    text-decoration: underline;
    color: #ffffff; }
  .bh_pricing-cta__content p {
    line-height: 20px; }
    @media (min-width: 64rem) {
      .bh_pricing-cta__content p {
        line-height: 28px;
        font-size: 16px; } }

.bh_pricing-cta__learn-more {
  margin-top: 32px; }

.bh_pricing-cta__inspiration-logo {
  display: block;
  width: 100px;
  height: 100px;
  background: url(../images/homepage/inspiration.png);
  background-size: contain;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -20%); }
  @media (min-width: 64rem) {
    .bh_pricing-cta__inspiration-logo {
      width: 160px;
      height: 160px; } }

.bh-home-hero {
  width: 100vw;
  position: relative;
  background-size: cover;
  background-position: top center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("/images/homepage/hero-desktop.jpg"); }
  @media (max-width: 64rem) {
    .bh-home-hero {
      background-image: url("/images/homepage/hero-mobile.png"); } }

.bh-home-wrap {
  padding: 154px 0;
  color: #ffffff;
  max-width: 670px; }
  @media (max-width: 64rem) {
    .bh-home-wrap {
      margin-left: 0;
      width: 100%;
      padding: 154px 2.5%; } }
  .bh-home-wrap h1 {
    font-size: 56px;
    line-height: 60px; }
    @media (max-width: 64rem) {
      .bh-home-wrap h1 {
        font-size: 36px;
        line-height: 40px; } }
  .bh-home-wrap p {
    font-size: 22px;
    line-height: 36px;
    margin: 20px 0 60px; }
    @media (max-width: 64rem) {
      .bh-home-wrap p {
        font-size: 16px;
        line-height: 28px;
        margin: 20px 0 100px; } }
  .bh-home-wrap .hero-inp {
    background-color: orange;
    color: #000000; }

.hero-email {
  width: 460px;
  height: 50px;
  display: flex;
  justify-content: flex-start; }
  @media (max-width: 64rem) {
    .hero-email {
      width: 100%;
      flex-direction: column;
      height: 100px; } }
  .hero-email input {
    background-color: #ffffff;
    color: #000000;
    height: 50px;
    border: none;
    padding: 0 16px;
    width: 60%; }
    @media (max-width: 64rem) {
      .hero-email input {
        width: 100%; } }
    .hero-email input:focus {
      outline: none; }

.hero-link {
  font-size: 16px;
  margin-top: 20px; }
  .hero-link br {
    display: none; }
  @media (max-width: 64rem) {
    .hero-link br {
      display: block; } }

.bh_bottom-feature-nav-container {
  display: flex;
  justify-content: center; }
  .bh_bottom-feature-nav-container h4 {
    margin-bottom: 0px;
    font-size: 18px; }
  .bh_bottom-feature-nav-container .--hide {
    display: none; }
  .bh_bottom-feature-nav-container > div {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 250px; }
    @media (min-width: 47.9375rem) {
      .bh_bottom-feature-nav-container > div {
        width: auto;
        max-width: unset; }
        .bh_bottom-feature-nav-container > div > div {
          max-width: unset;
          padding: 0 80px;
          margin: unset; } }

.bh_bottom-feature-nav-button {
  color: #090304;
  height: 45px;
  width: 45px; }
  .bh_bottom-feature-nav-button svg {
    height: 100%;
    width: 100%; }
  @media (min-width: 47.9375rem) {
    .bh_bottom-feature-nav-button {
      height: 60px;
      width: 60px; }
      .bh_bottom-feature-nav-button:hover {
        color: #136ef6; } }

.bh_bottom-feature-nav-left {
  padding-right: 12px;
  justify-content: flex-end;
  border-right: 1px solid black; }
  .bh_bottom-feature-nav-left > div {
    text-align: right;
    margin-left: auto;
    max-width: 86px; }
  .bh_bottom-feature-nav-left a {
    transform: rotate(180deg); }

.bh_bottom-feature-nav-right {
  padding-left: 12px; }
  .bh_bottom-feature-nav-right > div {
    margin-right: auto;
    max-width: 86px; }

.bh_signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 14px 0; }
  .bh_signup-container h1 {
    text-align: center; }

.bh_signup-email {
  width: 100%;
  display: flex;
  flex-direction: column; }
  .bh_signup-email input {
    background-color: #136ef6;
    color: #ffffff;
    height: 50px;
    border: 1px solid #090304;
    border-bottom: none;
    padding: 0 16px; }
  .bh_signup-email input::placeholder {
    color: #ffffff; }
  .bh_signup-email a {
    height: 50px; }
  @media (min-width: 47.9375rem) {
    .bh_signup-email {
      width: 650px;
      flex-direction: row; }
      .bh_signup-email input {
        border: 1px solid #090304;
        border-right: none;
        width: 60%; }
      .bh_signup-email a {
        flex: 1; } }

#s4s-landing nav.bh_nav {
  display: none; }

#s4s-landing main {
  padding-top: 0; }

.bh_landing__inspiration-logo {
  display: block;
  width: 150px;
  height: 150px;
  background: url(../images/homepage/inspiration.png);
  background-size: contain;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50px); }
  @media (min-width: 64rem) {
    .bh_landing__inspiration-logo {
      transform: translate(-50%, -20%);
      width: 160px;
      height: 160px; } }

.bh_landing-hero-top {
  height: 648px;
  background: url(../images/landing-page/bg-mobile.jpg) top center/cover no-repeat; }
  @media (min-width: 47.9375rem) {
    .bh_landing-hero-top {
      background: url(../images/landing-page/bg.jpg) top center/cover no-repeat; } }
  @media (min-width: 64rem) {
    .bh_landing-hero-top {
      height: 530px; } }
  .bh_landing-hero-top > div {
    position: relative;
    padding-top: 140px;
    display: flex;
    flex-direction: column;
    align-items: center; }
  .bh_landing-hero-top img {
    width: 182px;
    position: absolute;
    top: 35px;
    left: 40px;
    display: none; }
    @media (min-width: 64rem) {
      .bh_landing-hero-top img {
        display: block; } }
  .bh_landing-hero-top h1 {
    line-height: 40px;
    font-size: 30px;
    font-weight: 700;
    max-width: 860px;
    margin-bottom: 22px; }
    @media (min-width: 64rem) {
      .bh_landing-hero-top h1 {
        font-size: 56px;
        line-height: 60px;
        font-weight: 700; } }
  .bh_landing-hero-top p {
    margin-bottom: 40px; }

.bh_landing-enter-form {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .bh_landing-enter-form h1 {
    padding-top: 220px; }
    @media (min-width: 64rem) {
      .bh_landing-enter-form h1 {
        padding-top: 148px; } }
  .bh_landing-enter-form ul, .bh_landing-enter-form li {
    list-style: none;
    margin: 0;
    padding: 0; }
  .bh_landing-enter-form ul {
    max-width: 870px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 10px;
    padding: 0 16px; }
    @media (min-width: 64rem) {
      .bh_landing-enter-form ul {
        flex-direction: row; } }
    .bh_landing-enter-form ul li {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .bh_landing-enter-form ul li p {
        margin-bottom: 44px; }
      @media (min-width: 64rem) {
        .bh_landing-enter-form ul li {
          width: calc(33% - 20px);
          margin-bottom: 0px; } }

.bh_landing-enter-form__step {
  width: 26px;
  height: 26px;
  border-radius: 13px;
  color: #ffffff;
  border: 2px solid #136ef6;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px; }
  .bh_landing-enter-form__step span {
    margin-top: -4px; }

.bh_landing-hero__signup {
  margin-bottom: 30px;
  width: 100%;
  max-width: 870px;
  margin-top: 60px;
  padding: 0 16px; }
  @media (min-width: 47.9375rem) {
    .bh_landing-hero__signup {
      padding: 0 16px;
      margin-bottom: 20px;
      display: flex; }
      .bh_landing-hero__signup input {
        flex-grow: 1; } }
  @media (min-width: 64rem) {
    .bh_landing-hero__signup {
      padding: 0; } }

.bh_landing-hero__input {
  width: 100%;
  background-color: #000000;
  outline: none;
  border: 1px solid #ffffff;
  padding: 0 16px;
  height: 49px; }
  @media (min-width: 47.9375rem) {
    .bh_landing-hero__input {
      padding: 0 16px;
      border-right: none;
      flex-grow: 1;
      width: auto; } }

.bh_landing-hero__submit {
  width: 100%;
  height: 50px;
  padding: 0 32px;
  border: none; }
  @media (min-width: 47.9375rem) {
    .bh_landing-hero__submit {
      width: auto; } }

.bh_landing-features {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px; }
  .bh_landing-features h1 {
    margin-bottom: 4px;
    margin-top: 48px; }
    @media (min-width: 64rem) {
      .bh_landing-features h1 {
        margin-top: 0; } }
  .bh_landing-features > p {
    margin-top: 16px; }
    @media (min-width: 64rem) {
      .bh_landing-features > p {
        margin-top: 0; } }
  .bh_landing-features ul, .bh_landing-features li {
    list-style: none;
    margin: 0;
    padding: 0; }
  .bh_landing-features ul {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 886px; }
    @media (min-width: 64rem) {
      .bh_landing-features ul {
        flex-direction: row; } }
  .bh_landing-features li {
    width: 100%;
    margin-bottom: 24px; }
    @media (min-width: 64rem) {
      .bh_landing-features li {
        width: calc(33% - 40px);
        margin-bottom: 50px; } }
    .bh_landing-features li img {
      height: 42px;
      max-width: 46px; }
    .bh_landing-features li h2 {
      margin-top: 8px; }

.bh_landing-signup-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px; }
  @media (min-width: 64rem) {
    .bh_landing-signup-form h1 {
      line-height: 40px;
      font-size: 30px;
      font-weight: 700;
      color: inherit;
      margin-bottom: 22px; } }

.bh_landing-signup-form__form-body {
  display: flex;
  max-width: 650px;
  width: 100%;
  flex-direction: column; }
  @media (min-width: 47.9375rem) {
    .bh_landing-signup-form__form-body {
      flex-direction: row; } }
  .bh_landing-signup-form__form-body input {
    height: 45px;
    background-color: #ffffff;
    color: #000000;
    border: 1px solid #000000; }
    @media (min-width: 64rem) {
      .bh_landing-signup-form__form-body input {
        border-right: none; } }
  .bh_landing-signup-form__form-body button {
    height: 45px;
    border: 1px solid #000000; }
    .bh_landing-signup-form__form-body button:hover {
      color: #000000; }

.bh_marketing-seo-second-title section {
  padding-bottom: 0px !important; }

.bh_marketing-seo-second-title .bh_type-title-3 {
  font-size: 16px;
  line-height: 28px; }

.bh_marketing-seo-copy-with-checkboxes section {
  padding-top: 0px !important; }

.bh_marketing-seo .image-wide-column-title-image {
  height: 42px;
  width: 140px; }

.bh_carousel-card {
  max-width: 984px;
  margin: 0 auto;
  padding-bottom: 70px; }
  @media (min-width: 47.9375rem) {
    .bh_carousel-card {
      padding-bottom: 0; } }
  .bh_carousel-card .slick-slide {
    padding: 0 8px; }
  .bh_carousel-card .slick-track {
    display: flex; }
  .bh_carousel-card .slick-initialized .slick-slide {
    display: flex;
    float: none;
    height: auto; }
  .bh_carousel-card .slick-arrow {
    font-size: 0;
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    background: transparent;
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: -70px;
    transform: translateX(-50%); }
    @media (min-width: 64rem) {
      .bh_carousel-card .slick-arrow {
        transform: none;
        bottom: inherit;
        top: 50%;
        transform: translateY(-50%); } }
    .bh_carousel-card .slick-arrow::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-size: 40px;
      background-image: url("data:image/svg+xml,%3Csvg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.8135 11.8135L27.0059 21.0059L17.8135 30.1983' stroke='%23ffffff' stroke-miterlimit='10' stroke-linecap='square'/%3E%3Ccircle cx='21' cy='21' r='20' stroke='%23ffffff' stroke-miterlimit='10' stroke-linecap='square'/%3E%3C/svg%3E"); }
    @media (min-width: 64rem) {
      .bh_carousel-card .slick-arrow:hover::before {
        background-image: url("data:image/svg+xml,%3Csvg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.8135 11.8135L27.0059 21.0059L17.8135 30.1983' stroke='%23111111' stroke-miterlimit='10' stroke-linecap='square'/%3E%3Ccircle cx='21' cy='21' r='20' stroke='%23111111' stroke-miterlimit='10' stroke-linecap='square'/%3E%3C/svg%3E"); } }
  .bh_carousel-card .slick-prev {
    left: calc(50% - 35px); }
    @media (min-width: 64rem) {
      .bh_carousel-card .slick-prev {
        left: -70px; } }
    .bh_carousel-card .slick-prev::before {
      transform: rotate(180deg); }
  .bh_carousel-card .slick-next {
    left: calc(50% + 35px); }
    @media (min-width: 64rem) {
      .bh_carousel-card .slick-next {
        left: inherit;
        right: -70px; } }

.bh_carousel-card__card {
  background: #ffffff;
  border-radius: 30px;
  padding: 30px; }
  @media (min-width: 47.9375rem) {
    .bh_carousel-card__card {
      padding: 50px; } }
  .bh_carousel-card__card.bh_background-blue {
    background: #136ef6;
    color: #ffffff; }
    .bh_carousel-card__card.bh_background-blue .bh_review-carousel_reviewer-name .bh_strong,
    .bh_carousel-card__card.bh_background-blue .bh_type-caption {
      color: #ffffff !important; }

.bh_carousel-card.bh_light .slick-arrow::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: 40px;
  background-image: url("data:image/svg+xml,%3Csvg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.8135 11.8135L27.0059 21.0059L17.8135 30.1983' stroke='%23111111' stroke-miterlimit='10' stroke-linecap='square'/%3E%3Ccircle cx='21' cy='21' r='20' stroke='%23111111' stroke-miterlimit='10' stroke-linecap='square'/%3E%3C/svg%3E"); }

@media (min-width: 64rem) {
  .bh_carousel-card.bh_light .slick-arrow:hover::before {
    background-image: url("data:image/svg+xml,%3Csvg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.8135 11.8135L27.0059 21.0059L17.8135 30.1983' stroke='%23136EF6' stroke-miterlimit='10' stroke-linecap='square'/%3E%3Ccircle cx='21' cy='21' r='20' stroke='%23136EF6' stroke-miterlimit='10' stroke-linecap='square'/%3E%3C/svg%3E"); } }

@media (min-width: 25rem) {
  .bh_review-carousel {
    margin-top: 50px; } }

.bh_review-carousel__copy {
  margin-bottom: 30px; }
  @media (min-width: 25rem) {
    .bh_review-carousel__copy {
      font-size: 22px;
      margin-bottom: 16px; } }

.bh_review-carousel_reviewer > * {
  display: inline-block;
  vertical-align: middle; }

.bh_review-carousel_reviewer .bh_review-carousel_reviewer-info {
  margin-left: 16px; }
  .bh_review-carousel_reviewer .bh_review-carousel_reviewer-info .bh_review-carousel_reviewer-name {
    line-height: 1; }
  .bh_review-carousel_reviewer .bh_review-carousel_reviewer-info p {
    margin: 0; }

.bh_review-carousel_reviewer-img {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden; }
  .bh_review-carousel_reviewer-img img {
    width: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.bh_statistic-carousel.slick-initialized .slick-slide {
  display: block; }

@media (min-width: 25rem) {
  .bh_statistic-carousel {
    margin-top: 50px; } }

@media (min-width: 64rem) {
  .bh_carousel-card .bh_statistic-carousel .slick-arrow::before {
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='20' cy='20' r='20' fill='white' fill-opacity='0.8'/%3E%3Cpath d='M16.8135 10.8135L26.0059 20.0059L16.8135 29.1983' stroke='%23111111' stroke-miterlimit='10' stroke-linecap='square'/%3E%3C/svg%3E"); }
  .bh_carousel-card .bh_statistic-carousel .slick-arrow:hover::before {
    background-image: url("data:image/svg+xml,%3Csvg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.8135 11.8135L27.0059 21.0059L17.8135 30.1983' stroke='%23ffffff' stroke-miterlimit='10' stroke-linecap='square'/%3E%3Ccircle cx='21' cy='21' r='20' stroke='%23ffffff' stroke-miterlimit='10' stroke-linecap='square'/%3E%3C/svg%3E"); } }

.bh_statistic-carousel__stat img {
  max-height: 105px;
  width: auto; }

@media (min-width: 47.9375rem) {
  .bh_statistic-carousel__stat {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between; } }

@media (min-width: 47.9375rem) {
  .bh_statistic-carousel__card {
    display: flex;
    flex-direction: column;
    min-height: 340px; } }

@media (min-width: 47.9375rem) {
  .bh_statistic-carousel__card > p {
    margin-top: auto;
    margin-bottom: 0; } }

.bh_static-stats {
  margin-top: 30px; }
  @media (min-width: 47.9375rem) {
    .bh_static-stats {
      margin-top: 90px; } }
  @media (min-width: 64rem) {
    .bh_static-stats {
      margin-top: 30px; } }

.bh_static-stats__inner {
  margin-bottom: 20px;
  margin-top: 30px; }
  @media (min-width: 47.9375rem) {
    .bh_static-stats__inner {
      display: flex;
      flex-wrap: wrap;
      margin-right: -16px;
      margin-top: 40px; } }
  .bh_static-stats__inner > *:first-of-type {
    margin-bottom: 16px; }
    @media (min-width: 47.9375rem) {
      .bh_static-stats__inner > *:first-of-type {
        margin-bottom: 0; } }
  @media (min-width: 47.9375rem) {
    .bh_static-stats__inner > * {
      width: calc(50% - 16px);
      margin-right: 16px; } }

.bh_static-stats__card {
  background: #136ef6;
  border-radius: 30px;
  padding: 30px;
  display: flex;
  justify-content: space-between; }
  .bh_static-stats__card p:first-of-type {
    font-size: 36px; }
  @media (min-width: 47.9375rem) {
    .bh_static-stats__card {
      padding: 30px 40px; } }
  .bh_static-stats__card > div:first-of-type {
    max-width: 240px; }
  .bh_static-stats__card img {
    max-width: 62px; }

.bh_faqs .bh_faq {
  border-top: 1px solid #000000;
  cursor: pointer;
  position: relative; }
  .bh_faqs .bh_faq:last-child {
    border-bottom: 1px solid #000000; }

.bh_faq-question {
  line-height: 20px;
  padding: 5px 0;
  width: calc(100% - 34px);
  font-weight: bold;
  position: relative;
  height: 70px;
  display: flex;
  align-items: center;
  color: #000000; }
  .bh_faq-question:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '+';
    border-radius: 100%;
    width: 20px;
    height: 20px;
    color: #fff;
    background-color: #000000;
    font-size: 18px;
    line-height: 16px;
    display: block;
    text-align: center;
    font-weight: 700;
    position: absolute;
    margin: auto;
    padding-left: 1px;
    top: 50%;
    right: -24px;
    transform: translateY(-50%); }

.bh_faq-answer {
  display: none;
  padding: 5px 0 15px;
  width: calc(100% - 24px);
  color: #000000; }

.active .bh_faq-question:after {
  content: '-'; }

.bh_plan-slider {
  margin-bottom: 40px;
  overflow-x: scroll;
  margin-right: calc(50% - 50vw); }
  @media (min-width: 47.9375rem) {
    .bh_plan-slider {
      overflow: inherit;
      margin-right: inherit;
      margin-top: 40px; } }

.bh_plan-slider__table {
  table-layout: auto;
  width: 100%;
  text-align: left;
  border-spacing: 0; }
  .bh_plan-slider__table [data-tooltip] {
    position: relative;
    display: inline-flex;
    align-items: center; }
    .bh_plan-slider__table [data-tooltip] svg {
      display: none; }
      @media (min-width: 47.9375rem) {
        .bh_plan-slider__table [data-tooltip] svg {
          display: block;
          margin-left: 6px;
          width: 16px; } }
    .bh_plan-slider__table [data-tooltip]::before {
      display: none;
      content: attr(data-tooltip);
      position: absolute;
      background-color: #c1c1c1;
      border-radius: 10px;
      padding: 10px;
      bottom: 126%;
      left: 0;
      width: 300px;
      box-shadow: 0px 2px 0px #eaeaea; }
    @media (min-width: 47.9375rem) {
      .bh_plan-slider__table [data-tooltip]:hover::before {
        display: block; } }
  .bh_plan-slider__table svg {
    display: flex; }
  .bh_plan-slider__table th, .bh_plan-slider__table td {
    padding: 16px 0;
    border-bottom: 1px solid #000000; }
  .bh_plan-slider__table th span {
    display: block; }
  .bh_plan-slider__table th:first-of-type {
    position: sticky;
    left: 0;
    background-color: #ffffff;
    z-index: 1; }
    @media (min-width: 47.9375rem) {
      .bh_plan-slider__table th:first-of-type {
        position: static;
        border-right: 1px solid black; } }
  .bh_plan-slider__table thead th:nth-child(5), .bh_plan-slider__table tbody td:nth-child(5) {
    position: relative; }
    .bh_plan-slider__table thead th:nth-child(5)::after, .bh_plan-slider__table tbody td:nth-child(5)::after {
      content: '';
      position: absolute;
      width: 72%;
      min-width: 122px;
      height: 100%;
      background: rgba(0, 107, 237, 0.2);
      top: 0;
      left: 0; }
  .bh_plan-slider__table thead th:first-of-type {
    padding-left: 11px; }
    @media (min-width: 47.9375rem) {
      .bh_plan-slider__table thead th:first-of-type {
        padding-left: 0; } }
  @media (min-width: 47.9375rem) {
    .bh_plan-slider__table thead th:not(:first-of-type) {
      position: sticky;
      top: 60px;
      left: inherit;
      background: white;
      z-index: 1; } }
  @media (min-width: 64rem) {
    .bh_plan-slider__table thead th:not(:first-of-type) {
      top: 92px; } }
  .bh_plan-slider__table thead th:nth-child(n+2) {
    padding: 16px 20px; }
  .bh_plan-slider__table thead th:nth-child(5)::after {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px; }
  .bh_plan-slider__table tbody tr:nth-last-child(2) td:nth-child(5)::after {
    height: 125%;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px; }
  .bh_plan-slider__table tbody tr.bh_plan-header > * {
    background-color: #f9f9f9; }
  .bh_plan-slider__table tbody tr.bh_plan-header th {
    font-size: 22px; }
  .bh_plan-slider__table tbody tr:not(:last-child) th {
    border-right: 1px solid #000000;
    border-left: 1px solid #000000;
    padding-left: 11px; }
    @media (min-width: 47.9375rem) {
      .bh_plan-slider__table tbody tr:not(:last-child) th {
        border-left: none;
        padding-left: 0; } }
  .bh_plan-slider__table tbody tr:last-of-type td, .bh_plan-slider__table tbody tr:last-of-type th {
    border-bottom: 0;
    padding-top: 24px; }
  @media (min-width: 47.9375rem) {
    .bh_plan-slider__table tbody tr:last-of-type th {
      border-right: 0; } }
  .bh_plan-slider__table tbody tr:last-of-type td {
    padding-left: 0; }
  .bh_plan-slider__table tbody tr:last-of-type td:last-child::after {
    display: none; }
  .bh_plan-slider__table tbody td a {
    max-width: 132px;
    min-width: auto;
    width: 100%; }
  .bh_plan-slider__table tbody th {
    min-width: 165px; }
  .bh_plan-slider__table tbody td {
    min-width: 132px;
    padding: 16px 20px; }
    @media (min-width: 47.9375rem) {
      .bh_plan-slider__table tbody td {
        min-width: inherit; } }

.bh_plan-hidden {
  display: none; }

.bh_plan-see-all.bh_plan-visible::after {
  transform: translateY(-50%) rotate(270deg);
  top: 60%; }

.bh_image-text-slider__inner {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: center; }
  .bh_image-text-slider__inner h1 {
    max-width: 600px;
    margin-bottom: 0; }
    @media (min-width: 64rem) {
      .bh_image-text-slider__inner h1 {
        font-size: 36px;
        margin-bottom: 32px; } }
  .bh_image-text-slider__inner p {
    max-width: 200px;
    margin: 0 auto; }
  .bh_image-text-slider__inner button {
    width: 40px;
    height: 40px;
    background: url("../images/icons/carousel-arrow.svg");
    background-size: contain;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    border: none; }
  .bh_image-text-slider__inner .bh_image-text-slider__slideshow-button--previous {
    transform: rotate(180deg);
    margin-right: 30px; }

.bh_image-text-slider__slideshow {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  margin-top: 40px; }
  @media (min-width: 47.9375rem) {
    .bh_image-text-slider__slideshow {
      justify-content: center; } }
  .bh_image-text-slider__slideshow button {
    display: none; }
    @media (min-width: 64rem) {
      .bh_image-text-slider__slideshow button {
        display: block; } }

.bh_image-text-slider__slides {
  width: 100%; }

.bh_image-text-slider__slide {
  margin: 0 40px; }
  .bh_image-text-slider__slide img {
    margin: 0 auto;
    max-width: 100%; }

.bh_image-text-slider__slideshow-buttons {
  display: flex;
  margin-top: 16px;
  flex-direction: row;
  align-self: center;
  -webkit-tap-highlight-color: transparent; }
  @media (min-width: 64rem) {
    .bh_image-text-slider__slideshow-buttons {
      display: none; } }

.bh_image-text-slider__slideshow button {
  visibility: hidden;
  position: absolute; }
  @media (min-width: 64rem) {
    .bh_image-text-slider__slideshow button {
      visibility: visible;
      position: static; } }

.bh_image-text-slider__image {
  height: 84px;
  max-width: 200px;
  margin: 0 auto 30px;
  display: flex;
  align-items: center; }

@media (max-width: 53.125rem) {
  .bh_pricing-hero .bh_type-feature, .bh_pricing-hero .bh-home-wrap h1, .bh-home-wrap .bh_pricing-hero h1 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 14px; } }

@media (max-width: 53.125rem) {
  .bh_pricing-hero .bh_type-headline {
    font-size: 16px;
    font-weight: 500;
    line-height: 28px; }
    .bh_pricing-hero .bh_type-headline br {
      display: none; } }

.bh_pf-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  @media (max-width: 53.125rem) {
    .bh_pf-head {
      flex-direction: column; }
      .bh_pf-head h4,
      .bh_pf-head p {
        text-align: center; } }
  .bh_pf-head .bh_pf-left {
    width: 60%;
    border-bottom: 1px solid #ffffff; }
    .bh_pf-head .bh_pf-left.bh_line-dark {
      border-bottom: 1px solid #090304; }
    @media (max-width: 53.125rem) {
      .bh_pf-head .bh_pf-left {
        width: 100%;
        border-bottom: none !important; } }
  .bh_pf-head .bh_pf-right {
    width: 33.33%; }
    .bh_pf-head .bh_pf-right img {
      max-width: 100%; }
    @media (max-width: 53.125rem) {
      .bh_pf-head .bh_pf-right {
        width: 100%; }
        .bh_pf-head .bh_pf-right img {
          max-width: 75%;
          display: block;
          margin: auto; } }

.bh-checklist {
  padding: 30px 0 0;
  display: flex;
  flex-wrap: wrap; }
  @media (max-width: 53.125rem) {
    .bh-checklist {
      display: block;
      columns: 2;
      column-gap: 16px; } }
  .bh-checklist li {
    list-style-type: none;
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
    width: 33%;
    position: relative;
    height: 42px; }
    .bh-checklist li.bh-inv {
      opacity: 0; }
    @media (max-width: 53.125rem) {
      .bh-checklist li {
        align-items: flex-start;
        width: inherit;
        height: auto;
        margin-bottom: 22px;
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid; }
        .bh-checklist li br {
          display: none; } }
    .bh-checklist li span {
      width: calc(100% - 36px); }
      @media (max-width: 53.125rem) {
        .bh-checklist li span {
          width: calc(100% - 29px); } }
    .bh-checklist li:before {
      width: 36px;
      height: 26px;
      content: ' ';
      background-image: url("../images/pricing/check.svg");
      background-repeat: no-repeat;
      background-size: 26px 26px; }
      @media (max-width: 53.125rem) {
        .bh-checklist li:before {
          background-size: 19px 19px;
          width: 29px;
          height: 19px; } }

@media (max-width: 53.125rem) {
  .bh_pricing-main h4 {
    font-size: 30px;
    line-height: 40px; }
    .bh_pricing-main h4 br {
      display: none; } }

.bh_pricing-main > div p {
  margin-bottom: 22px; }

.bh_pricing-main .bh_btn-arrow {
  text-decoration: underline;
  font-weight: bold;
  margin-left: 20px; }
  .bh_pricing-main .bh_btn-arrow:after {
    width: 20px;
    height: 15.5px;
    content: ' ';
    background-image: url("../images/pricing/arrow.svg");
    background-repeat: no-repeat;
    background-size: 20px 15.5px;
    display: inline-block;
    margin: 0 0 -2px 10px; }

.bh_unlimited {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .bh_unlimited .bh_unlim1 {
    width: 41%; }
    .bh_unlimited .bh_unlim1 img {
      max-width: 100%; }
    @media (max-width: 53.125rem) {
      .bh_unlimited .bh_unlim1 {
        display: none; } }
  .bh_unlimited .bh_unlim2 {
    width: 55%;
    padding-left: 5%; }
    @media (max-width: 53.125rem) {
      .bh_unlimited .bh_unlim2 {
        width: 100%;
        padding-left: 0;
        text-align: center; } }
    .bh_unlimited .bh_unlim2 ul {
      text-align: left;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding-left: 16px; }
      @media (max-width: 53.125rem) {
        .bh_unlimited .bh_unlim2 ul {
          columns: 2;
          column-gap: 32px;
          display: block;
          max-width: 600px;
          margin: 10px auto; } }
      .bh_unlimited .bh_unlim2 ul li {
        flex-grow: 1;
        width: 48%;
        margin-bottom: 7.5px; }
        @media (max-width: 53.125rem) {
          .bh_unlimited .bh_unlim2 ul li {
            width: inherit; } }

.bh_pricing-slider {
  margin-bottom: 40px;
  overflow-x: scroll;
  margin-right: calc(50% - 50vw); }
  @media (min-width: 47.9375rem) {
    .bh_pricing-slider {
      overflow: inherit;
      margin-right: inherit;
      margin-top: 40px; } }

.bh_pricing-slider__table {
  table-layout: auto;
  width: 100%;
  text-align: left;
  border-spacing: 0; }
  .bh_pricing-slider__table th, .bh_pricing-slider__table td {
    padding: 16px 0;
    border-bottom: 1px solid #000000; }
  .bh_pricing-slider__table th span {
    display: block; }
  .bh_pricing-slider__table th:first-of-type {
    position: sticky;
    left: 0;
    background-color: #f9f9f9;
    z-index: 1; }
    @media (min-width: 47.9375rem) {
      .bh_pricing-slider__table th:first-of-type {
        position: static; } }
  .bh_pricing-slider__table thead th:nth-child(2), .bh_pricing-slider__table tbody td:nth-child(2) {
    position: relative; }
    .bh_pricing-slider__table thead th:nth-child(2)::after, .bh_pricing-slider__table tbody td:nth-child(2)::after {
      content: '';
      position: absolute;
      width: 72%;
      min-width: 122px;
      height: 100%;
      background: rgba(0, 107, 237, 0.2);
      top: 0;
      left: 0; }
  .bh_pricing-slider__table thead th {
    padding: 16px 20px; }
    .bh_pricing-slider__table thead th:nth-child(2)::after {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px; }
  .bh_pricing-slider__table tbody th {
    min-width: 180px; }
  .bh_pricing-slider__table tbody td {
    min-width: 132px;
    padding: 16px 20px; }
    @media (min-width: 47.9375rem) {
      .bh_pricing-slider__table tbody td {
        min-width: inherit; } }
  @media (min-width: 47.9375rem) {
    .bh_pricing-slider__table tbody tr:last-of-type td:nth-child(2)::after {
      height: 125%;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px; } }
  .bh_pricing-slider__table tbody tr th {
    border-right: 1px solid #000000;
    border-left: 1px solid #000000;
    padding-left: 11px; }
    @media (min-width: 47.9375rem) {
      .bh_pricing-slider__table tbody tr th {
        border-right: none;
        border-left: none;
        padding-left: 0; } }

.bh_mobile-ecommerce .bh_title-two-columns-headline .bh_type-title-3 {
  max-width: 650px; }

.bh_mobile-ecommerce .bh_title-two-columns-body p {
  margin-bottom: 12px; }

.bh_mobile-ecommerce .bh_title-two-columns-body-left {
  align-items: center; }

.bh_mobile-ecommerce .bh_title-subtitle .bh_type-title-3 {
  margin-bottom: 0; }

.bh_mobile-ecommerce .bh_manage-orders-image {
  flex-grow: unset !important; }
  .bh_mobile-ecommerce .bh_manage-orders-image img {
    padding: 0 8px; }

.bh_mobile-ecommerce .bh_manage-orders-align {
  padding-left: 0px !important; }

@media (min-width: 64rem) {
  .bh_mobile-ecommerce .bh_shift-right-manage-orders {
    padding: auto; } }

.bh_manage-orders-large-centered-image {
  flex-grow: unset !important; }
  .bh_manage-orders-large-centered-image img {
    max-width: 600px;
    padding: 0 8px; }

.bh_secure-hosting .bh_title-two-columns-body-left {
  display: flex;
  align-items: center; }

.bh_secure-hosting .bh_title-two-columns-list {
  margin-top: 40px; }
  .bh_secure-hosting .bh_title-two-columns-list li {
    display: flex;
    flex-direction: column; }
    .bh_secure-hosting .bh_title-two-columns-list li div {
      display: flex; }
    .bh_secure-hosting .bh_title-two-columns-list li .bh_type-headline {
      margin-bottom: 0; }
    .bh_secure-hosting .bh_title-two-columns-list li .bh_type-callout, .bh_secure-hosting .bh_title-two-columns-list li .bh-home-wrap p, .bh-home-wrap .bh_secure-hosting .bh_title-two-columns-list li p {
      margin-left: 41px; }
  @media (min-width: 53.125rem) {
    .bh_secure-hosting .bh_title-two-columns-list {
      margin-top: 0; } }

.bh_secure-hosting .bh_hide-item {
  display: none; }

.bh_mobile-ecommerce .bh_title-two-columns-headline .bh_type-title-3 {
  max-width: 650px; }

.bh_mobile-ecommerce .bh_title-two-columns-body p {
  margin-bottom: 12px; }

.bh_mobile-ecommerce .bh_title-two-columns-body-left {
  align-items: center; }

.bh_mobile-ecommerce .bh_title-subtitle .bh_type-title-3 {
  margin-bottom: 0; }

.bh_mobile-ecommerce .bh_mobile-ecommerce-image {
  flex-grow: unset !important; }
  .bh_mobile-ecommerce .bh_mobile-ecommerce-image img {
    padding: 0 8px; }

.bh_mobile-ecommerce .bh_mobile-ecommerce-align {
  padding-left: 0px !important; }

.bh_mobile-ecommerce .bh_statistic-carousel__card {
  min-height: 350px; }
  .bh_mobile-ecommerce .bh_statistic-carousel__card p {
    margin-top: 22px; }

@media (min-width: 64rem) {
  .bh_mobile-ecommerce .bh_carousel-card .slick-arrow {
    top: 45%; }
  .bh_mobile-ecommerce .bh_carousel-card .slick-initialized .slick-slide {
    height: fit-content; } }

.bh_manage-products-alignment .bh_type-subhead {
  margin-bottom: 12px; }

.bh_manage-products-alignment .bh_type-title-3 {
  max-width: 650px; }

.bh_manage-products-alignment .bh_type-body, .bh_manage-products-alignment .bh_faq-question, .bh_manage-products-alignment .bh_faq-answer {
  margin-bottom: 22px; }

.bh_manage-products-alignment img {
  align-self: center; }

.bh_manage-products .bh_remove-bottom-margin {
  margin-bottom: 0px; }

@media (min-width: 64rem) {
  .bh_manage-products .bh_manage-product-alignment {
    padding-top: 0px; } }

.bh_manage-products .bh_adjust-image {
  max-width: 100%; }
  @media (min-width: 64rem) {
    .bh_manage-products .bh_adjust-image {
      max-width: 90%; } }

.bh_manage-products .bh_title-two-columns-body-left {
  flex-direction: column; }

.bh_manage-products-margin {
  margin-top: 0px; }
  .bh_manage-products-margin .bh_image-left {
    margin-top: 0px; }
  @media (min-width: 64rem) {
    .bh_manage-products-margin .bh_image-left {
      margin-top: -60px; } }

.bh_shopping-cart .bh_adjust-top-padding {
  padding-top: 10px; }

.bh_shopping-cart-card-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
  margin: auto; }
  .bh_shopping-cart-card-container-image {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 50%;
    width: 100%;
    margin: 22px auto; }
    .bh_shopping-cart-card-container-image img {
      width: 125px;
      margin: 0px; }
  @media (min-width: 47.9375rem) {
    .bh_shopping-cart-card-container {
      flex-wrap: unset;
      margin: 28px 0 40px 0;
      flex-direction: row; }
      .bh_shopping-cart-card-container-image {
        margin: 0;
        height: 130px;
        max-width: 150px; } }
  @media (min-width: 53.125rem) {
    .bh_shopping-cart-card-container-image img {
      width: auto; } }

.bh_shopping-cart-reverse {
  display: flex;
  flex-direction: column-reverse; }
  @media (min-width: 53.125rem) {
    .bh_shopping-cart-reverse {
      flex-direction: row; } }

.bh_shopping-cart-adjust-margin {
  margin-top: 0px;
  padding-top: 40px; }
  .bh_shopping-cart-adjust-margin .bh_image-left {
    margin-top: 0px; }
  @media (min-width: 64rem) {
    .bh_shopping-cart-adjust-margin .bh_image-left {
      margin-top: -80px; } }

.bh_shopping-cart-video-container {
  height: 300px;
  width: 100%;
  max-height: 400px;
  max-width: 500px; }
  @media (min-width: 53.125rem) {
    .bh_shopping-cart-video-container {
      height: 100%; } }

@media (max-width: 53.125rem) {
  .bh_shopping-cart-tall-image {
    width: 210px !important;
    height: 200px; } }

.bh_title-inline-images {
  display: flex;
  justify-content: space-between;
  flex-direction: column; }
  .bh_title-inline-images img {
    margin-top: 0px;
    margin-bottom: 12px;
    max-width: 227px; }
  .bh_title-inline-images a img {
    max-height: 104px; }
  @media (min-width: 64rem) {
    .bh_title-inline-images {
      flex-direction: row; } }

.bh_customer-suuport-links p {
  margin-bottom: 0; }

.bh_customer-suuport-links li {
  margin-bottom: 40px; }

.bh_title-customer-support {
  margin-top: 300px;
  text-align: start; }
  .bh_title-customer-support .bh_title-subtitle {
    margin-top: -300px; }
  @media (min-width: 53.125rem) {
    .bh_title-customer-support {
      text-align: center; } }

.bh_store-design-adjust-margin {
  margin-top: 0px;
  padding-top: 40px; }
  .bh_store-design-adjust-margin .bh_image-left {
    margin-top: 0px; }
  @media (min-width: 53.125rem) {
    .bh_store-design-adjust-margin .bh_image-left {
      margin-top: -80px; } }

.bh_store-design-multiple-columns .image-column {
  align-items: flex-start;
  margin-top: 12px; }

.bh_store-design-desktop-only-image {
  display: none; }
  @media (min-width: 53.125rem) {
    .bh_store-design-desktop-only-image {
      display: flex; } }

.bh_store-design-title-two-columns img {
  max-width: 400px; }

.bh_store-design-title-two-columns p {
  margin-bottom: 22px; }

.bh_store-design-title-two-columns .bh_columns {
  flex-direction: column-reverse;
  padding-bottom: 40px; }
  @media (min-width: 53.125rem) {
    .bh_store-design-title-two-columns .bh_columns {
      flex-direction: row; } }

@media (min-width: 53.125rem) {
  .bh_store-design .bh_alignment-margin {
    padding-left: 16px; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }
