.bh_title-inline-images {
    display:flex;
    justify-content: space-between;
    flex-direction: column;
    img {
        margin-top:0px;
        margin-bottom:12px;
        max-width:227px;
    }

    a img {
        max-height:104px;
    }
    
    @include larger-than(tablet){
        flex-direction: row;
    }

}

.bh_customer-suuport-links {
    p {
        margin-bottom:0;
    }
    li {
        margin-bottom:40px;
    }
}

.bh_title-customer-support{
    margin-top:300px;
    .bh_title-subtitle {
        margin-top:-300px;
    }

    text-align: start;

    @include larger-than (smallTablet){
        text-align: center;
    }
}