.bh_styleguide-logo--normal {
  max-width: 387px;
}

.bh_styleguide-logo--small {
  max-width: 50px;
}

.bh_styleguide-headline {
  margin-bottom: 43px;
}

.container1170--margin {
  margin-bottom: 100px;
}

.container1170--header {
  margin-bottom: 100px;
  margin-top: 100px;
}

.bh_styleguide-grid {
  display: grid;
  grid-gap: 30px;
}

.bh_styleguide-grid--typography {
  grid-gap: 20px;
}

.bh_styleguide__buttons {
  & > div {
    & + div {
      margin-top: 30px;
    }
  }
}

.bh_styleguide__buttons-wrap {
  display: flex;
  flex-wrap: wrap;

  & > * {
    padding: 40px;
  }
}

.bh_styleguide-grid--input {
  --gg: 10px;
}

.bh_styleguide--icons {
  display:flex;
  align-items: center;
  img {
    margin-right:20px;
  }
}

.bh_styleguide-colors {
  display:flex;
  align-items: center;
  flex-wrap:wrap;

  &-container {
    flex-direction: column;
    margin-right:20px;
    margin-bottom:30px;
  }
}

.bh_styleguide-colors-circle{
  height:170px;
  width:170px;
  border-radius:50%;
  margin-bottom:10px;
}


.bh_styleguide-desktop-grid {
  height: 50px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    background: #BDBDBD;
    width: 100vw;
    height: 100%;
    z-index: -2;
    margin: 0 calc(50% - 50vw);
    left: -8px;
  }

  &::before {
    content: '';
    background: $white;
    width: calc(100% - 16px);
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }

  & > * {
    background: #EAEAEA;
  }
}

.bh_styleguide-desktop-grid-img {
  max-width: 245px;
  margin-top: 50px;
}

.bh_spacing-grid {
  display: grid;
  grid-gap: 20px;

  @include larger-than(mobile) {
    grid-gap: 100px;
    grid-template-columns: 1fr 1fr;
  }
}

.bh_spacing-column {
  display: grid;
  grid-gap: 20px;
}

.bh_spacing-grid-inner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
}

.bh_spacing-grid-padding {
  background: #E2E2E2;
  padding-left: 10px;

  &.--large {
    height: 80px;
  }

  &.--standard {
    height: 50px;
  }

  &.--primary {
    height: 30px;
  }

  &.--secondary {
    height: 22px;
  }

  &.--small {
    height: 16px;
  }

  .bh_spacing-column:nth-child(2) & {
    &.--large {
      height: 40px;
    }

    &.--standard {
      height: 30px;
    }

    &.--primary {
      height: 22px;
    }

    &.--secondary {
      height: 16px;
    }

    &.--small {
      height: 10px;
    }
  }
}

.bh_styleguide-color {
  &-spaceBlack {
    background-color:$spaceBlack;
  }

  &-inspirationBlue {
    background-color: $inspirationBlue;
  }

  &-cloudWhite {
    background-color: $cloudWhite;
    border: 1px solid $lightGray;
  }

  &-lightGray {
    background-color: $lightGray;
  }

  &-rocketGray {
    background-color: $rocketGray;
  }

  &-offWhite {
    background-color: $offWhite;
    border: 1px solid $lightGray;
  }

  &-errorRed {
    background-color: $errorRed;
    height:24px;
    width:139px;
    border-radius: 12px;
  }

  &-goGreen {
    background-color: $goGreen;
    height:24px;
    width:139px;
    border-radius: 12px;
  }
}

.bh_styleguide-illustration-icon-grid {
  display: flex;
  flex-wrap: wrap;

  & > * {
    width: 120px;
    padding: 0 20px 20px 0;
  }
}

.bh_styleguide-no-line {
  display:inline;
  line-height:28px;
}

.bh_styleguide-email {
  display:flex;
  flex-wrap: wrap;
  
  &-container {
    display:flex;
    flex-direction: column;
    margin-right:50px;
  }
}