.bh-text-center {
  text-align: center;
}

.bh-m-0 {
  margin-bottom: 0px;
}

.bh-m-1 {
  margin-bottom: 10px;
}

.bh-m-2 {
  margin-bottom: 20px;
}

.bh-m-3 {
  margin-bottom: 30px;
}

.bh-m-45 {
  margin-bottom: 45px;
}
