.bh_hp-support__inner {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    @extend %bh_type-title-1;
    width: 100%;
    text-align: left;
  }

  p {
    @extend %bh_type-body;
    max-width: 620px;
  }
}

.bh_hp-support__columns {
  display: flex;
  flex-direction: column;

  @include larger-than(smallTablet) {
    margin-top: 50px;
    flex-direction: row;
  }
}

.bh_hp-support__column {
  &:last-child {
    width: 40%;
    @include larger-than(smallTablet) {
      margin-left: 50px;
    }
  }
  &:first-child {
    margin-left: 0;
    @include larger-than(smallDesktop) {
      margin-left: 200px;
    }
  }
  flex-grow: 0;
  flex-shrink: 1;

  a {
    margin-left: 16px;
  }

  @include larger-than(smallTablet) {
    width: 60%;
    max-width: 510px;
  }

  img {
    width: 100%;
    max-width: 410px;
    @include larger-than(smallTablet) {
    }
  }
  h2 {
    color: $inspirationBlue;
    @extend %bh_type-title-2;
    @include larger-than(smallTablet) {
      font-size: $text-title-1;
    }
  }

  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul li {
    @extend %bh_type-callout;
    margin-bottom: 20px;

    position: relative;
    padding-left: 45px;

    &::before {
      content: '';
      background-image: url('../images/icons/checkmark-circle.svg');
      width: 27px;
      height: 27px;
      position: absolute;
      background-size: 27px;
      left: 0;
    }
  }
}

.bh_hp-support__column-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  height: 86px;
  justify-content: space-between;
  width: 100%;

  button:hover {
    color: $black;
  }

  &-link:hover {
    text-decoration: none;
  }

  @include larger-than(smallTablet) {
    display: block;
  }
}

.bh_hp-support__column--first {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 100%;
    max-width: auto;
    padding: 30px 20px;
  }
  @include larger-than(smallTablet) {
    img {
      display: none;
    }
  }
}

.bh_hp-support__column--last {
  img {
    display: none;
  }
  @include larger-than(smallTablet) {
    img {
      display: block;
    }
  }
}
