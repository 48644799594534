.bh_hp-themes {
  overflow-x: hidden;
  #s4s-landing & {
    background-color: $offWhite;
  }

  h1 {
    line-height: 44px;
    font-size: $text-large-title;
  }

  p {
    max-width: 705px;
    margin: 0 auto 20px;

    @include larger-than(tablet) {
      margin: 0 auto 50px;
    }
  }
}

.bh_hp-themes__image {
  width: 100%;
  img {
    max-width: 836px;
  }
}

.bh-home-themes {
  width: 100%;

  .slick-track {
    @include larger-than(carousel) {
      left: calc(50% - 805px/2);
    }
  }
}

.bh-home-theme {

  &:not(.slick-active) {
    img {
      @include larger-than(carousel) {
        opacity: 0.1;
      }
    }
  }

  img {
    width: 100%;
    max-width: 268px;
  }
}

.bh_hp-themes__control {
  @include reset-button;
  display: inline-flex !important;
  position: absolute;
  top: 40%;

  &:hover {
    svg > * {
      stroke: $inspirationBlue;
    }
  }

  &.slick-disabled {
    pointer-events: none;
    opacity: 0.2;
  }

  &.--left {
    transform: rotate(180deg) translateY(-50%);
    left: calc(50% - #{$container-width}/2);
    transform-origin: top;
  }

  &.--right {
    transform: translateY(-50%);
    right: calc(50% - #{$container-width}/2);
  }
}

.bh_hp-themes__slider {
  position: relative;
}