html.bh_html {
  box-sizing: border-box;

  @include larger-than(mobile) {
    overflow: inherit;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &:focus-visible {
      outline: 1px solid $inspirationBlue;
    }

    &:focus:not(:focus-visible) {
      outline: none;
    }
  }


  main {
    padding-top: 60px;

    @include larger-than(tablet) {
      padding-top: 92px;
    }
  }

  #s4s-home main {
    padding-top: 0;
  }
}

