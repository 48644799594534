%bh_button-square {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &:hover {
    text-decoration: none;
  }
}

.bh_button-primary-white {
  @include bh_button(
    45px,
    145px,
    $white,
    transparent,
    $inspirationBlue,
    $white,
    $font-weight-medium,
    1px solid $white,
    1px solid $white
  );
  @extend %bh_button-square;
  padding: 0 16px;
}

.bh_button-primary-blue-white {
  @include bh_button(
    45px,
    145px,
    $inspirationBlue,
    $white,
    $white,
    $black,
    $font-weight-medium,
    1px solid $inspirationBlue,
    1px solid $inspirationBlue
  );
  @extend %bh_button-square;
  padding: 0 16px;
}

.bh_button-primary-black {
  @include bh_button(
    45px,
    145px,
    $black,
    transparent,
    $white,
    $inspirationBlue,
    $font-weight-medium,
    1px solid $black,
    1px solid $inspirationBlue
  );
  @extend %bh_button-square;
  padding: 0 16px;
}

.bh_button-primary-black-black {
  @include bh_button(
    45px,
    145px,
    $black,
    transparent,
    $white,
    $white,
    $font-weight-medium,
    1px solid $black,
    1px solid $black
  );
  @extend %bh_button-square;
  padding: 0 16px;
}

.bh_button-primary-blue,
.bh_button-primary-blue2 {
  @include bh_button(
    45px,
    145px,
    $inspirationBlue,
    transparent,
    $white,
    $white,
    $font-weight-medium,
    1px solid $inspirationBlue,
    1px solid $inspirationBlue
  );
  @extend %bh_button-square;
  padding: 0 16px;
}

.bh_button-primary-blue2:hover {
  color: $inspirationBlue;
}

.bh_button-secondary-blue {
  @include bh_button(
    45px,
    145px,
    transparent,
    $inspirationBlue,
    $inspirationBlue,
    $white,
    $font-weight-medium,
    1px solid $inspirationBlue,
    1px solid $inspirationBlue
  );
  @extend %bh_button-square;
  padding: 0 16px;
}

.bh_button-secondary-white {
  @include bh_button(
    45px,
    145px,
    transparent,
    $white,
    $white,
    $inspirationBlue,
    $font-weight-medium,
    1px solid $white,
    1px solid $inspirationBlue
  );
  @extend %bh_button-square;
  padding: 0 16px;
}

.bh_button-secondary-blue-blue {
  @include bh_button(
    45px,
    145px,
    transparent,
    $inspirationBlue,
    $white,
    $white,
    $font-weight-medium,
    1px solid $inspirationBlue,
    1px solid $inspirationBlue
  );
  @extend %bh_button-square;
  padding: 0 16px;
}

.bh_button-secondary-black {
  @include bh_button(
    45px,
    145px,
    transparent,
    $black,
    $white,
    $white,
    $font-weight-medium,
    1px solid $black,
    1px solid $black
  );
  @extend %bh_button-square;
  padding: 0 16px;
}

.bh_button-circle {
  @include bh_button(
    72px,
    72px,
    $inspirationBlue,
    transparent,
    $white,
    $inspirationBlue,
    $font-weight-medium,
    1px solid $inspirationBlue,
    1px solid $inspirationBlue
  );
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    text-decoration: none;
  }
}

.bh_button-arrow-black {
  @include bh_button-arrow(
    $black,
    $inspirationBlue,
    '../../images/icons/arrow-sm.svg',
    '../../images/icons/arrow-sm-blue.svg'
  );
}

.bh_button-arrow-white-blue {
  @include bh_button-arrow(
    $white,
    $inspirationBlue,
    '../../images/icons/arrow-sm-white.svg',
    '../../images/icons/arrow-sm-blue.svg'
  );
}

.bh_button-arrow-white-black {
  @include bh_button-arrow(
    $white,
    $black,
    '../../images/icons/arrow-sm-white.svg',
    '../../images/icons/arrow-sm-black.svg'
  );
}

.bh_button-chevron-black {
  @include bh_button-arrow(
    $black,
    $inspirationBlue,
    '../../images/icons/chevron.svg',
    '../../images/icons/chevron-blue.svg'
  );

  &:after {
    height: 20px;
    width: 16px;
    transform: translateY(-50%) rotate(90deg);
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: center;
    top: 37%;
  }
}
