.bh_mobile-ecommerce{

    .bh_title-two-columns-headline .bh_type-title-3{
        max-width:650px;
    }

    .bh_title-two-columns-body {
        p {
            margin-bottom:12px;
        }

        &-left {
           align-items: center; 
        }
        
    }

    .bh_title-subtitle .bh_type-title-3 {
        margin-bottom:0;
    }

    .bh_mobile-ecommerce-image {
        flex-grow:unset !important;
        img {
            padding:0 8px;
        }
    }

    .bh_mobile-ecommerce-align{
        padding-left:0px !important;
    }


    .bh_statistic-carousel__card {
        p {
            margin-top:22px;
        }
        min-height:350px;
    }
    
    @include larger-than(tablet){

    
        .bh_carousel-card {
            & .slick-arrow {
                top:45%;
            }
    
            & .slick-initialized .slick-slide {
                height:fit-content;
            }
        }
    }


}